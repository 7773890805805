import React, { useEffect } from "react";
// Apollo
import { useLazyQuery } from "@apollo/client";
import WEATHER_FORECAST from "queries/widgets/weather";
import { Skeleton } from "@material-ui/lab";
// Components
import Accordion from "components/Accordion/Accordion";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import WeatherPreview from 'components/WeatherPreview';
import Switch from "@material-ui/core/Switch";
// Styles
import { FormControlLabel, makeStyles } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import { useTranslation } from "react-i18next";
import { getLSValue } from "utils/localStorage";
import { inject, observer } from "mobx-react";

const useStyles = makeStyles(theme => ({
  ...styles,
  text: {
    color: 'black'
  },
  wrapper: {
    width: '100%',
    margin: theme.spacing(6, 0, 2)
  },
  city: {
    color: 'black',
    paddingRight: '15px'
  },
  heading: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    transform: 'translateY(-25px)'
  },
  custom: {
    marginLeft: '5px'
  },
  padding: {
    paddingBottom: '15px'
  }
}));

const WeatherForecast = ({ session }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [fetchWeather, { data, error, loading }] = useLazyQuery(WEATHER_FORECAST, { fetchPolicy: 'no-cache' });
  const [isCollapsed, setCollapsed] = React.useState(false);

  useEffect(() => {
    fetchWeather({
      variables: {
        ZipCode: isNaN(parseInt(session.activeAccount.ZIP)) ? 3013 : parseInt(session.activeAccount.ZIP),
        CountryCode: 'ch',
        Language: session.user.language,
        Units: 'metric'
      },
    });
  }, []);

  useEffect(() => {
    fetchWeather({
      variables: {
        ZipCode: isNaN(parseInt(session.activeAccount.ZIP)) ? 3013 : parseInt(session.activeAccount.ZIP),
        CountryCode: 'ch',
        Language: session.user.language,
        Units: 'metric'
      },
    });
  }, [session.user.language]);

  const renderForecastPreview = () => {
    if (!data) return;

    const forecastList = data.weatherForecast.list;

    return Object.keys(forecastList).map((objectKey, i) => {
      if (objectKey === '__typename') return;

      return <GridItem xs={12} sm={4} md={4} lg={4} key={i}><WeatherPreview data={forecastList[objectKey]} isCollapsed={isCollapsed} /></GridItem>
    });
  }

  return (
    <>
      {
        loading
          ? (
            <GridContainer className={classes.padding}>
              <GridItem xs={12} style={{ marginRight: '15px' }}>
                <GridContainer className={classes.heading} direction="column">
                  <Skeleton width={115} height={15} />
                  <Skeleton width={90} height={15} />
                </GridContainer>
              </GridItem>
              <GridItem xs={12}>
                <GridContainer justify="center" align="center">
                  {Array.from(Array(3).keys()).map((_, i) => <GridItem xs key={i}><Skeleton width={135} height={125} /></GridItem>)}
                </GridContainer>
              </GridItem>
            </GridContainer>
          )
          : (
            <GridContainer direction="column" className={classes.padding}>
              <GridItem xs={12}>
                <GridContainer className={classes.heading} direction="column">
                  <h5 className={classes.city} style={{ paddingTop: '10px' }}><b>{t('Location')}:</b> {data?.weatherForecast?.city?.name}</h5>

                  <FormControlLabel
                    control={
                      <Switch
                        checked={isCollapsed}
                        onChange={event => setCollapsed(event.target.checked)}
                        value="isCollapsed"
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          thumb: classes.switchIcon,
                          track: classes.switchBar,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    style={{ marginLeft: 0 }}
                    label={t('See detailed')}
                  />
                </GridContainer>
              </GridItem>
              <GridItem xs={12}>
                <GridContainer>
                  {renderForecastPreview()}
                </GridContainer>
              </GridItem>
            </GridContainer>
          )
      }
    </>
  );
}

export default inject('session')(observer(WeatherForecast));
