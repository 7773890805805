import React, { useState } from 'react';

// Styles
import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import SuperAdmin from 'components/SuperAdmin/SuperAdmin';

const useStyles = makeStyles(theme => ({
  ...styles,
  ...sweetAlertStyles,
  ...buttonsStyle
}));

const SuperAdminPage = props => {
  const classes = useStyles();
  const history = useHistory();
  const [alert, setAlert] = useState(null);

  const hideAlert = () => {
    setAlert(null);
  }

  const showAlert = (title, message, redirectTo, type = 'success') => {
    setAlert(
      <SweetAlert
        success={type === 'success'}
        error={type === 'error'}
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => {
          hideAlert();

          if (redirectTo) {
            history.push(redirectTo)
          }
        }}
        onCancel={hideAlert}
        confirmBtnCssClass={
          classNames({
            [classes.button]: true,
            [classes.success]: type === 'success',
            [classes.danger]: type === 'error',
          })
        }>{message}</SweetAlert>
    );
  }

  return (
    <>
      {alert}
      <SuperAdmin showAlert={showAlert} />
    </>
  );
}

export default SuperAdminPage;
