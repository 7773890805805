import { gql } from '@apollo/client';

const RECENT_MONTHS_BILLING = gql`
  query monthlyBillingDetails {
    recentMonthsBilling {
      Electricity {
        month
        total
      }
      WaterWarm {
        month
        total
      }
    }
  }
`;

export default RECENT_MONTHS_BILLING;
