import { gql } from '@apollo/client'; 

const MEASURE_VALUES_TO_EXCEL = gql`
  query measureValuesToExcel($MeasurePointPos: Int!, $StartDate: String, $Period: MeasurePeriodEnum){
    measureValuesToExcel(MeasurePointPos: $MeasurePointPos, StartDate: $StartDate, Period: $Period) {
      data {
        Name
        used
        MeasureDateLocal
        sale
      }
    }
  }
`;

export default MEASURE_VALUES_TO_EXCEL;
