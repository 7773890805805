import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Wizard from "components/Wizard/Wizard.js";

import AccountStep from "views/Forms/WizardSteps/AccountStep";
import UserStep from "views/Forms/WizardSteps/UserStep";
// Apollo
import { GENERATE_ACTIVATION_TOKEN } from "mutations/account";
import { useLazyQuery } from "@apollo/client";

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";

import AuthGuard from 'components/AuthGuard/AuthGuard';
import { PERMISSIONS } from "utils/session";

const useStyles = makeStyles({ ...styles, ...buttonsStyle, ...sweetAlertStyles });

export default function AddAccount() {
  const classes = useStyles();
  const [alert, setAlert] = useState(null);
  const [error, setError] = useState('');
  const [generateActivationToken, { loading, data, error: generationError }] = useLazyQuery(GENERATE_ACTIVATION_TOKEN);

  useEffect(() => {
    if (generationError) {
      generationError.graphQLErrors.map(error => setError(error.message));
    }
  }, [generationError]);

  useEffect(() => {
    if (data) {
      if (data.generateActivationToken.response) {
        setError('');
        successAlert(data.generateActivationToken.message);
      }
    }
  }, [data]);

  const register = values => {
    if (values) {
      generateActivationToken({ variables: { input: values } });
    }
  }

  const hideAlert = () => {
    setAlert(null);
  }

  const successAlert = message => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={message}
        onConfirm={hideAlert}
        onCancel={hideAlert}
        confirmBtnCssClass={
          classes.button + " " + classes.success
        }>You can activate account now!</SweetAlert>
    );
  }

  return (
    <AuthGuard permission={PERMISSIONS.accounts}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            {alert}

            <Wizard
              validate
              error={error}
              steps={[
                { stepName: "About", stepComponent: AccountStep, stepId: "account" },
                { stepName: "User account", stepComponent: UserStep, stepId: "user" }
              ]}
              title="Let's create a new account"
              loading={loading}
              subtitle="This information will let us know more."
              finishButtonClick={values => register(values)}
            />
          </GridItem>
        </GridContainer>
      </div>
    </AuthGuard>
  );
}
