import { gql } from '@apollo/client';

const CHANGE_PASSWORD = gql`
  mutation changePassword($resetToken: String!, $Password: String!){
    changePassword(resetToken: $resetToken, password: $Password) {
      response
      message
    }
  }
`;

export default CHANGE_PASSWORD;