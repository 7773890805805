import { gql } from '@apollo/client';

const ENERGY_TYPES = gql`
  {
    getEnergyTypes {
        Id
        Name
        Status
    }    
  }
`;

export default ENERGY_TYPES;