import { gql } from '@apollo/client';

const ACCOUNTS = gql`
  query accounts($page: Int, $size: Int) {
    accounts(page: $page, size: $size) {
      data {
        Id
        Company
        FirstName
        LastName
        Street
        ZIP
        City
        Country
        PersonGroupPos
      }
      page
      totalCount
    }    
  }
`;

export default ACCOUNTS;