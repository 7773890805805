import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";

import { routes } from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

import register from "assets/img/register.jpeg";
import login from "assets/img/login-page-background.jpeg";
import lock from "assets/img/lock.jpeg";
import error from "assets/img/clint-mckoy.jpg";
import pricing from "assets/img/bg-pricing.jpeg";
import { inject, observer } from "mobx-react";
import { getLSValue } from "utils/localStorage";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  ...sweetAlertStyles,
  ...buttonsStyle,
}));

const Pages = ({ session, ...props }) => {
  const { ...rest } = props;
  const history = useHistory();
  const [alert, setAlert] = useState(null);
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  const { t } = useTranslation();

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            exact={prop.exact || true}
            component={(props) => (
              <prop.component
                {...props}
                openAlert={(title, message, redirectTo) =>
                  successAlert(title, message, redirectTo)
                }
              />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBgImage = () => {
    if (window.location.pathname.indexOf("/auth/register-page") !== -1) {
      return register;
    } else if (window.location.pathname.indexOf("/auth/login-page") !== -1) {
      return login;
    } else if (
      window.location.pathname.indexOf("/auth/forgot-password") !== -1
    ) {
      return login;
    } else if (
      window.location.pathname.indexOf("/auth/activate-account") !== -1
    ) {
      return login;
    } else if (window.location.pathname.indexOf("/auth/pricing-page") !== -1) {
      return pricing;
    } else if (
      window.location.pathname.indexOf("/auth/lock-screen-page") !== -1
    ) {
      return lock;
    } else if (window.location.pathname.indexOf("/auth/error-page") !== -1) {
      return error;
    }
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return t(routes[i].name);
        }
      }
    }
    return activeRoute;
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const successAlert = (title, message, redirectTo) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => {
          hideAlert();

          if (redirectTo) {
            history.push(redirectTo);
          }
        }}
        onCancel={hideAlert}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        {message}
      </SweetAlert>
    );
  };

  return (
    <>
      <AuthNavbar brandText={getActiveRoute(routes)} {...rest} />
      <div className={classes.wrapper} ref={wrapper}>
        {alert}
        <div
          className={classes.fullPage}
          style={{ backgroundImage: "url(" + getBgImage() + ")" }}
        >
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/auth" to="/auth/login-page" />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default Pages;
