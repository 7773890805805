import { gql } from '@apollo/client';

const SENSORS = gql`
  {
    sensors {
      Id
      ExtSensorName
      SensorNr
      SensorName
      SerialNr
      StatusPos
    }
  }
`;

export default SENSORS;