import React, { cloneElement, useEffect, useState } from "react";
import {
  useTable,
  usePagination,
  useFilters,
  useSortBy
} from "react-table";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import matchSorter from "match-sorter";
// @material-ui/core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { CheckOutlined, Close, Delete, Done, Edit, FilterList, Remove } from "@material-ui/icons";
import { Checkbox, CircularProgress, IconButton, InputAdornment, InputLabel, Tooltip, Typography } from "@material-ui/core";
// core components
import Modal from "components/Modal/Modal";
import GridItem from "components/Grid/GridItem.js";
import Button from 'components/CustomButtons/Button';
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// Styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
// Apollo
import { gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDebounce } from '@react-hook/debounce';
import PerfectScrollbar from "react-perfect-scrollbar";

const newStyles = {
  ...styles,
  formControlMargins: {
    margin: "3px 0 !important"
  },
  gridContainer: {
    justifyContent: "center"
  },
  centered: {
    display: 'flex',
    justifyContent: 'center'
  }
};

const useStyles = makeStyles(newStyles);
function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = val => !val;

function Table({
  columns,
  data: { data, totalCount },
  loading,
  fetchData,
  showSuccessAlert,
  showErrorAlert,
  onAddQuery,
  getQuery,
  onDeleteQuery,
  extraActionButtons = [],
  onUpdateQuery,
  addRowText,
  deleteRowText,
  addRowLink,
  onPagination,
  defaultRowsPerPage = 5,
  ...props
}) {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [filteredObject, setFilteredObject] = useDebounce({}, 200);
  const [mode, setMode] = useState('');
  const [pagination, setPagination] = useState({
    page: 0,
    size: defaultRowsPerPage
  });
  const emptyQuery = gql`
    mutation {
      sensors {
        Id
      }
    }
  `;
  const [editingObject, setEditingObject] = useState({});
  const [onAddData, { loading: addingLoader }] = useMutation(onAddQuery || emptyQuery, {
    onCompleted: data => showSuccessAlert(data),
    onError: (err) => {
      if (err.graphQLErrors) {
        showErrorAlert(err.graphQLErrors[0].message);
      }
    }
  });
  const [onDeleteData, { loading: deletingLoader }] = useMutation(onDeleteQuery || emptyQuery);
  const [onUpdateData, { loading: updatingLoader }] = useMutation(onUpdateQuery || emptyQuery);
  const pageCount = data ? Math.ceil(totalCount / pagination.size) : 0;
  const [pageOptionsArray, setPageOptionsArray] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  React.useEffect(() => {
    const transformedFilteredObject =
      Object
        .entries({ ...filteredObject, ...props.filteredObject })
        .reduce((acc, [key, value]) => {
          if (value) {
            acc[key] = value;
          }

          return acc;
        }, {});
    setFilteredObject(transformedFilteredObject);
  }, [props.filteredObject]);

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  function DefaultColumnFilter({ column: { filterValue, setFilter, ...rest } }) {
    return (
      <CustomInput
        formControlProps={{ fullWidth: true }}
        inputProps={{
          onChange: e => {
            const newFilterObject = e.target.value ? { [rest.id]: e.target.value } : {};
            setFilteredObject({ ...filteredObject, ...newFilterObject });
          },
          startAdornment: (
            <InputAdornment position="start">
              <FilterList />
            </InputAdornment>
          )
        }}
      />
    );
  }

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter
    }),
    []
  );
  const {
    getTableProps, getTableBodyProps, headerGroups, prepareRow,
    page, pageOptions, gotoPage, nextPage, previousPage
  } = useTable(
    {
      data,
      columns,
      pageCount,
      filterTypes,
      defaultColumn,
      manualPagination: true,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    const { page, size } = pagination;
    const variables = { page, size, searchCriteria: filteredObject };
    fetchData(variables);
  }, [fetchData, pagination, filteredObject]);

  useEffect(() => {
    if (!onPagination) return;
    onPagination(pagination)
  }, [pagination]);

  useEffect(() => {
    if (pageOptions.length) {
      setPageOptionsArray(pageOptions);
    }
  }, [pageOptions]);

  const onAddRow = () => {
    onAddData({
      variables: {
        input: editingObject,
        ...getQuery.variables
      },
      refetchQueries:
        [{
          query: getQuery.query,
          variables: { page: pagination.page, size: pagination.size, ...getQuery.variables }
        }]
    });
    setMode('');
  }

  const onDeleteRow = () => {
    onDeleteData({
      variables: {
        id: editingObject.Id,
        ...getQuery.variables
      }, refetchQueries: [{
        query: getQuery.query,
        variables: { page: pagination.page, size: pagination.size, ...getQuery.variables }
      }]
    });
    setModalVisible(false);
  }

  const onUpdate = (row, cell) => {
    const input = { ...editingObject };
    delete input['__typename'];
    onUpdateData({
      variables: {
        input,
        id: editingObject.Id
      },
      refetchQueries: [{
        query: getQuery.query,
        variables: { page: pagination.page, size: pagination.size, ...getQuery.variables }
      }]
    });
    setMode('');
  }

  let numberOfRowsData = [5, 10, 20];

  const addRowClick = () => {
    if (onAddQuery) {
      setMode('add');
    } else if (addRowLink) {
      history.push(addRowLink);
    }
  }

  const renderCell = cell => {
    if (cell.column.type === 'boolean') {
      return Boolean(cell.value) ? <CheckOutlined color="primary" /> : <Remove color="primary" />;
    } else if (cell.column.type === 'float') {
      return Number(cell.value).toFixed(2);
    } else if (cell.column.type === 'number') {
      return cell.column.lookUp(Number(cell.value));
    } else {
      return cell.render("Cell");
    }
  }

  const renderEditingComponent = (cell) => {
    if (cell.column.editing) {
      const { component, label } = cell.column.editing;
      return (
        <FormControl fullWidth>
          <InputLabel id={`demo-simple-select-label-${label}`}>{label}</InputLabel>
          {cloneElement(component, {
            value: editingObject[cell.column.id],
            onChange: event => setEditingObject({ ...editingObject, [cell.column.id]: event.target.value })
          })}
        </FormControl>
      );
    } else {
      return <CustomInput
        inputProps={{
          placeholder: cell.column.Header,
          defaultValue: cell.value || '',
          type: cell.column.type || 'text',
          onChange: ({ target: { value } }) => {
            setEditingObject({ ...editingObject, [cell.column.id]: cell.column.type === 'number' ? parseInt(value) : value })
          }
        }} />
    }
  }

  return (
    <>
      {
        (onAddQuery || addRowLink) && (
          <div className={classes.addRowButton}>
            <Button color="info" block onClick={addRowClick}>{addRowText}</Button>
          </div>
        )
      }

      {deleteRowText && (  
        <Modal isOpened={modalVisible} titleText={t("Confirmation")} bodyText={deleteRowText} onModalClose={() => setModalVisible(false)}>
          <Button color="info" onClick={onDeleteRow}>{t('Delete')}</Button>
          <Button onClick={() => setModalVisible(false)} color="danger" simple>
            {t('Close')}
          </Button>
        </Modal>
      )}

      <div className="ReactTable -striped -highlight">
        <div className="pagination-top"></div>
        {
          <PerfectScrollbar>
            <table {...getTableProps()} className="rt-table">
              <thead className="rt-thead -header">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                    {headerGroup.headers.map((column, key) => (
                      <th
                        // {...column.getHeaderProps(column.getSortByToggleProps())}
                        {...column.getHeaderProps()}
                        style={column.Header === t('Actions') ? { textAlign: 'center' } : {}}
                        className={classnames("rt-th rt-resizable-header", {
                          "-cursor-pointer": headerGroup.headers.length - 1 !== key,
                          "-sort-asc": column.isSorted && !column.isSortedDesc,
                          "-sort-desc": column.isSorted && column.isSortedDesc
                        })}
                      >
                        <div className="rt-resizable-header-content">
                          {column.render("Header")}
                        </div>
                        <div>
                          {headerGroup.headers.length - 1 === key
                            ? null
                            : column.canFilter
                              ? column.render("Filter")
                              : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="rt-tbody">
                <tr className="rt-tr">
                  {
                    mode === 'add' && columns.map((column, index) => {

                      if (column.editable === undefined) {
                       column.editable = true;
                      }  

                      if (column.editable) {
                        return (<td className="rt-td" key={index}>
                          <CustomInput
                            inputProps={{
                              placeholder: column.Header,
                              onChange: ({ target: { value } }) => {
                                setEditingObject({
                                  ...editingObject,
                                  [column.accessor]: column.type === 'numeric' ? parseInt(value) : value,
                                });
                              }
                            }}
                          />
                        </td>);
                      } else if(column.Header === t('Actions'))  {
                        return (<td className="rt-td" key={index}>
                          <div className={classes.centered}>
                            <Tooltip id="tooltip-top" title={t('Save')} placement="top" classes={{ tooltip: classes.tooltip }}>
                              <IconButton aria-label="Save" color="secondary" onClick={onAddRow}>
                                <Done />
                              </IconButton>
                            </Tooltip>

                            <Tooltip id="tooltip-top" title={t('Cancel')} placement="top" classes={{ tooltip: classes.tooltip }}>
                              <IconButton aria-label="Cancel" onClick={() => setMode('')}>
                                <Close />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </td>);
                      } else {
                        return <td className="rt-td" key={index}></td>
                      }
                    })
                  }
                </tr>
                {
                  loading || addingLoader || deletingLoader || updatingLoader
                    ? (
                      <tr className={classes.backdrop}>
                        <td><CircularProgress color="primary" /></td>
                      </tr>
                    )
                    : (<>
                      {
                        !page.length
                          ? <tr>
                            <td style={{ display: 'block', width: '100%' }}>
                              <Typography variant="subtitle2" align="center" style={{ textTransform: 'initial' }}>{t('No data found')}.</Typography>
                            </td>
                          </tr>
                          : page.map((row, i) => {
                            prepareRow(row);
                            return (
                              <tr
                                {...row.getRowProps()}
                                className={classnames(
                                  "rt-tr",
                                  { " -odd": i % 2 === 0 },
                                  { " -even": i % 2 === 1 }
                                )}
                              >
                                {row.cells.map(cell => {
                                  if (cell.column.editable === undefined) {
                                    // Set column editable by default 'true' if it wasn't defined in the parent.
                                    cell.column.editable = true;
                                  }

                                  return (
                                    <td {...cell.getCellProps()} className={
                                      classnames({
                                        'rt-td': true,
                                        [classes.cell]: true,
                                        [classes.textRight]: cell.column.type && cell.column.type === 'numeric'
                                      })
                                    }>
                                      {
                                        cell.column.Header === t('Actions')
                                          ? (<div className={classes.center}>
                                            {
                                              mode === 'edit' && editingObject.Id === row.original.Id
                                                ? (<>
                                                  <Tooltip id="tooltip-top" title={t("Save")} placement="top" classes={{ tooltip: classes.tooltip }}>
                                                    <IconButton size="small" aria-label="Save" color="secondary" onClick={() => onUpdate(row, cell)}>
                                                      <Done />
                                                    </IconButton>
                                                  </Tooltip>
                                                  <Tooltip id="tooltip-top" title={t('Cancel')} placement="top" classes={{ tooltip: classes.tooltip }}>
                                                    <IconButton size="small" aria-label="Cancel" color="default" onClick={() => setMode('')}>
                                                      <Close />
                                                    </IconButton>
                                                  </Tooltip>
                                                </>)
                                                : (<>
                                                  {
                                                    extraActionButtons.map((button, index) => (
                                                      <Tooltip id="tooltip-top" title={button.title} placement="top" classes={{ tooltip: classes.tooltip }} key={index}>
                                                        <IconButton
                                                          size="small"
                                                          aria-label={button.title}
                                                          color="secondary"
                                                          onClick={() => button.callback(row.original)}>
                                                          <button.icon />
                                                        </IconButton>
                                                      </Tooltip>
                                                    ))
                                                  }
                                                  {
                                                    onUpdateQuery && (<Tooltip id="tooltip-top" title={t("Edit")} placement="top" classes={{ tooltip: classes.tooltip }}>
                                                      <IconButton size="small" aria-label="Edit" color="secondary" onClick={() => {
                                                        setMode('edit');
                                                        setEditingObject(row.original);
                                                      }}>
                                                        <Edit />
                                                      </IconButton>
                                                    </Tooltip>
                                                    )
                                                  }
                                                  {
                                                    onDeleteQuery && (<Tooltip id="tooltip-top" title={t('Delete')} placement="top" classes={{ tooltip: classes.tooltip }}>
                                                      <IconButton size="small" aria-label="Delete" className={classes.dangerButton} onClick={() => {
                                                        setModalVisible(true);
                                                        const objectToDelete = data.find(item => item.Id === row.original.Id);
                                                        setEditingObject(objectToDelete);
                                                      }}>
                                                        <Delete />
                                                      </IconButton>
                                                    </Tooltip>
                                                    )
                                                  }
                                                </>)
                                            }
                                          </div>)
                                          : mode === 'edit' && editingObject.Id === row.original.Id && cell.column.editable
                                            ? renderEditingComponent(cell)
                                            : renderCell(cell)
                                      }
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                    </>)
                }
              </tbody>
            </table>
          </PerfectScrollbar>
        }
        <div className="pagination-bottom">
          <br />
          <div className="-pagination">
            <div className="-previous">
              <button
                type="button"
                onClick={() => {
                  setPagination({ ...pagination, page: pagination.page - 1 });
                  previousPage();
                }}
                disabled={pagination.page === 0 || !page.length}
                className="-btn"
              >
                {t('Previous')}
              </button>
            </div>
            <div className="-center">
              <GridContainer className={classes.gridContainer}>
                <GridItem xs={12} sm={6} md={4}>
                  <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.formControlMargins
                    }
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={pagination.page}
                      onChange={({ target: { value } }) => {
                        setPagination({ ...pagination, page: value });
                        gotoPage(value)
                      }}
                      readOnly={!page.length}
                      inputProps={{
                        name: "pageSelect",
                        id: "page-select"
                      }}
                    >
                      {pageOptionsArray.map(option => {
                        return (
                          <MenuItem
                            key={option}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={option}
                          >
                            {t('Page')} {option + 1}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.formControlMargins
                    }
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={pagination.size}
                      onChange={({ target: { value } }) => {
                        setPagination({ page: 0, size: value });
                      }}
                      inputProps={{
                        name: "numberOfRows",
                        id: "number-of-rows"
                      }}
                    >
                      {numberOfRowsData.map(prop => {
                        return (
                          <MenuItem
                            key={prop}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={prop}
                          >
                            {prop} {t('rows')}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </div>
            <div className="-next">
              <button
                type="button"
                onClick={() => {
                  setPagination({ ...pagination, page: pagination.page + 1 });
                  nextPage();
                }}
                disabled={pagination.page + 1 === pageCount || !page.length}
                className="-btn"
              >
                {t('Next')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

filterGreaterThan.autoRemove = val => typeof val !== "number";

export default Table;
