/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import BaseLayout from "layouts/Base.js";
import GuestLayout from "layouts/Guest.js";
import ErrorsLayout from "layouts/Errors";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import { ApolloProvider } from "@apollo/client";
import client from 'apollo/client';

// MobX
import { Provider as MobXProvider } from "mobx-react";
import { Session, Widgets, Locations } from "stores";

// i18n
import i18n from "i18next";
import en from './translations/en.json';
import de from './translations/de.json';
import { initReactI18next } from "react-i18next";
import { DEFAULT_LANGUAGE, loadLanguage } from 'utils/language';

// Styles
import { theme } from "theme";
import { ThemeProvider } from "@material-ui/styles";
import 'assets/scss/material-dashboard-pro-react/plugins/_plugin-perfect-scrollbar.scss';
import App from "containers/App";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';

const hist = createBrowserHistory();
const session = new Session();
const widgets = new Widgets();
const locations = new Locations();
dayjs.extend(customParseFormat);


i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      de: { translation: de }
    },
    lng: loadLanguage(),
    fallbackLng: DEFAULT_LANGUAGE,

    interpolation: {
      escapeValue: false
    }
  });

ReactDOM.render(
  <ApolloProvider client={client}>
    <MobXProvider session={session} widgets={widgets} locations={locations}>
      <ThemeProvider theme={theme}>
        <Router history={hist}>
          <App>
            <Switch>
              <Route path="/auth" component={props => <AuthLayout {...props} session={session} />} />
              <Route path="/guest" component={props => <GuestLayout {...props} session={session} />} />
              <Route path="/error" component={ErrorsLayout} />
              <Route path="/" component={props => <BaseLayout {...props} session={session} />} />
              <Redirect from="/" to="/profile" exact />       
            </Switch>
          </App>
        </Router>
      </ThemeProvider>
    </MobXProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
