import React, { useEffect, useState } from "react";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

// Styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

// MobX
import { useHistory } from "react-router";
import { setLSValue } from "utils/localStorage";

// Validate.js
import validate from "validate.js";
import { useTranslation } from "react-i18next";

import { useLazyQuery } from "@apollo/client";
import LOGIN from "queries/login/login";
import { inject, observer } from "mobx-react";

import jwtDecode from "jwt-decode";
import Login from "components/Cards/Login";
import ChooseAccount from "components/Cards/ChooseAccount";
import { getLSValue } from "utils/localStorage";
import { clearLSValue } from "utils/localStorage";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  cardFooter: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 0,
  },
  loginPageFormContainer: {
    "@media (max-width: 1199px)": {
      width: "450px",
    },
    "@media (min-width: 1200px)": {
      width: "450px",
    },
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const LoginPage = ({ session }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [loginData, setLoginData] = useState([]);
  const [login, { loading, error: loginErrors }] = useLazyQuery(LOGIN, {
    onCompleted: (data) => persistLogin(data),
    fetchPolicy: "no-cache",
  });
  const [error, setError] = useState("");
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const schema = {
    Email: {
      email: true,
      presence: { allowEmpty: false, message: t("is required") },
    },
    Password: {
      presence: { allowEmpty: false, message: t("is required") },
      length: { minimum: 6, message: t("minimum 6 characters") },
    },
  };

  useEffect(() => {
    if (session.user.token) history.push("/");
  }, []);

  useEffect(() => {
    if (loginErrors) {
      loginErrors.graphQLErrors.map((error) => setError(error.message));
    }
  }, [loginErrors]);

  useEffect(() => {
    validateForm();
  }, [formState.values, session]);

  const validateForm = () => {
    const errors = validate(formState.values, schema);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  };

  const persistLogin = (data) => {
    setError("");

    if (data.login.tokens) {
      const tokens = data.login.tokens;
      if (!tokens.length) return;
      const filteredTokens = tokens.filter((data) => !data.error);
      setLSValue("tokens", JSON.stringify(filteredTokens));

      if (tokens.length > 1) {
        const decodedLoginData = tokens.map((login) => {
          try {
            const decodedToken = jwtDecode(login.token);
            return {
              account: decodedToken.account,
              token: login.token,
              error: login.error,
            };
          } catch (err) {
            console.error(err);
          }
        });
        setLoginData(decodedLoginData);
        session.setUserAccounts(
          decodedLoginData
            .filter((data) => !data.error)
            .map((token) => token.account)
        );
      } else {
        signUserIn(tokens[0].token);
      }
    }
  };

  const signUserIn = (token) => {
    setLSValue("token", token);
    session.saveToken(token);
    history.push(`/location/${session.activeAccount.locationId}`);
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    const { Email, Password } = formState.values;

    if (!Object.entries(formState.errors).length) {
      login({ variables: { Email, Password} });
    }
  };

  const inputHandler = (event) => {
    event.persist();

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.id]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.id]: true,
      },
    }));
  };

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  const selectAccount = (account) => {
    const foundItem = loginData.find((data) => data.account.Id === account.Id);
    signUserIn(foundItem.token);
    setSelectedAccount(account.Id);
  };

  const backToLogin = () => {
    setLoginData([]);
    clearLSValue("tokens");
    setSelectedAccount(null);
  };

  return (
    <div
      className={classNames(classes.container, classes.loginPageFormContainer)}
    >
      <GridContainer justify="center">
        <GridItem xs={12}>
          <form onSubmit={onFormSubmit}>
            {!loginData.length ? (
              <Login
                error={t(error)}
                loading={loading}
                hasError={hasError}
                formState={formState}
                inputHandler={inputHandler}
              />
            ) : (
              <ChooseAccount
                error={t(error)}
                loading={loading}
                loginData={loginData}
                backToLogin={backToLogin}
                selectAccount={selectAccount}
                selectedAccount={selectedAccount}
              />
            )}
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default inject("session")(observer(LoginPage));
