import React, { useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";

import { routes } from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

import login from "assets/img/login-page-background.jpeg";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  ...styles(theme),
  ...sweetAlertStyles,
  ...buttonsStyle
}));

const Guest = ({ session, ...props }) => {
  const { ...rest } = props;
  const history = useHistory();
  const [alert, setAlert] = useState(null);
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  const { t } = useTranslation();

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() { };
  });
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/guest") {
        return (
          <Route
            path={prop.layout + prop.path}
            exact={prop.exact || true}
            component={(props) => <prop.component {...props} openAlert={showAlert} alertShown={alert} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBgImage = () => {
    return login;
  };

  const hideAlert = () => {
    setAlert(null);
  }

  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return t(routes[i].name);
        }
      }
    }
    return activeRoute;
  };

  const showAlert = (title, message, redirectTo, type = 'success') => {
    setAlert(
      <SweetAlert
        success={type === 'success'}
        error={type === 'error'}
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => {
          hideAlert();

          if (redirectTo) {
            history.push(redirectTo)
          }
        }}
        onCancel={hideAlert}
        confirmBtnCssClass={
          classNames({
            [classes.button]: true,
            [classes.success]: type === 'success',
            [classes.danger]: type === 'error',
          })
        }>{message}</SweetAlert>
    );
  }

  return (
    <>
      <AuthNavbar brandText={getActiveRoute(routes)} {...rest} />
      <div className={classes.wrapper} ref={wrapper}>
        {alert}
        <div
          className={classes.fullPage}
          style={{ backgroundImage: "url(" + getBgImage() + ")" }}
        >
          <Switch>
            {getRoutes(routes)}
          </Switch>
        </div>
      </div>
    </>
  );
}

export default Guest;
