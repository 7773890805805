import palette from './palette';
import overrides from './overrides';
import typography from './typography';
import { createMuiTheme } from '@material-ui/core';

const baseTheme = {
    palette,
    typography,
    overrides
};

export const theme = createMuiTheme(baseTheme);
