import { gql } from '@apollo/client';

const PDF_DATA = gql`
  query Query($invoiceId: Int!) {
    pdfData(invoiceId: $invoiceId) {
      Id
      Currency
      Reference
      EnergyType
      Date
      BillingCycle
      Total
      BillTotalPrice
      BillTotalVat
      BillTotalPriceVat
      CreationDate
      VATNr
      Creditor {
        name
        address
        zip
        city
        country
        account
      }
      Debtor {
        name
        address
        zip
        city
        country
        company
      }
      InvoiceLines {
        invoiceId
        billingText
        usage
        sale
        singlePrice
        tarifName
        vatPercent
        costType
        vatValue
      }
    }
  }
`;

export default PDF_DATA;
