import { gql } from '@apollo/client';

const UPLOAD_WIDGET_IMAGE = gql`
  mutation uploadWidgetImage($file: Upload!) {
    uploadWidgetImage(file: $file) {
      response
      message
    }
  }
`;

export default UPLOAD_WIDGET_IMAGE;
