import React, { useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Skeleton, TreeView } from '@material-ui/lab';
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { useQuery } from '@apollo/client';
import { LOCATIONS } from 'queries';
import { Home, ArrowDropDown, ArrowRight } from "@material-ui/icons";
import { StyledTreeItem } from "./StyledTreeItem.js";
import cloneDeep from 'lodash/cloneDeep';
import { useHistory } from 'react-router';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles((theme) => ({
  ...styles,
  container: {
    marginTop: '20px',
  },
  itemLink: {
    padding: "0 10px",
    margin: "10px 15px 0",
    borderRadius: "3px",
    width: "auto",
    backgroundColor: 'rgba(200, 200, 200, 0.3)'
  }
}));

const SidebarDynamic = ({ locations, session, selectedLocationId } ) => {
  const classes = useStyles();
  const history = useHistory();
  const currentUrlParams = new URLSearchParams(history.location.search);
  const [selectedLocation, setSelectedLocation] = useState(selectedLocationId);
  const [expandedLocations, setExpandedLocations] = useState([]);
  const fetchLocations = useQuery(LOCATIONS, { variables: { personGroupPos: session.activeAccount.PersonGroupPos } });

  useEffect(() => {
    setSelectedLocation(selectedLocationId);
  }, [selectedLocationId]);

  useEffect(() => {
    const data = fetchLocations?.data?.locations;
    if (!data || !data.length) return
    const items = [...data];
    const nestedArray = createNested(cloneDeep(items));
    locations.setLocations(nestedArray);
  }, [fetchLocations]);

  function findFor(a, parentId) {
    var z = {};

    for (var i = 0; i < a.length; i++) {
      if (a[i].LocationPartnerPos === parentId) {
        var ch = findFor(a, a[i].Id);
        var o = Object.keys(ch).length === 0 ? {} : { children: ch };
        z[a[i].Id] = Object.assign(o, a[i]);
      }
    }

    return z;
  }

  function createNested(array) {
    let result = [];
    const accountRootLocation = array[0].Id;

    const rootArray = array.filter(item => item.Id === accountRootLocation);
    const nonRootArray = array.filter(item => item.Id !== accountRootLocation);

    rootArray.forEach(item => {
      const children = findFor(nonRootArray, item.Id);
      item.children = children;
      result.push(item);
    });

    return result;
  }

  function mapRecursively(location) {
    let children = null;
    if (location.children) {
      const expandedLocationIds = [...expandedLocations];
      const locationId = location.Id.toString();

      if (selectedLocation && locationId !== selectedLocation && expandedLocationIds.indexOf(locationId) === -1) {
        expandedLocationIds.push(locationId);
        setExpandedLocations(expandedLocationIds);
      }

      children = mapLocations(Object.values(location.children));
    }

    return (
      <StyledTreeItem
        nodeId={String(location.Id)}
        key={location.Id}
        labelText={location.Name}
        labelIcon={Home}
        onLabelClick={(event) => {
          event.preventDefault();
          history.push(`/location/${location.Id}/?energyType=${session.activeEnergyTab}&sensorId=0&period=${currentUrlParams.get('period') || 'week'}`);
        }}>
        {children}
      </StyledTreeItem>
    );
  }

  function mapLocations(array) {
    return array.map(location => mapRecursively(location));
  }

  function buildLocationsTree() {
    if (!locations.userLocations) return 'Loading...';
    if (!locations.userLocations.length) return null;
    return locations.userLocations.map(location => mapRecursively(location));
  }

  const selectTreeItemHandler = (event, id) => {
    setSelectedLocation('');
  }

  const expandTreeItemHandler = (event, nodeIds) => {
    setExpandedLocations(nodeIds);
  }

  return (
      fetchLocations.loading
      ? 
        <Skeleton height={60}  className={classes.itemLink} animation="pulse" />
      :<>
        <Box className={classes.container}>
          <TreeView
            selected={selectedLocation}
            expanded={expandedLocations}
            onNodeSelect={selectTreeItemHandler}
            onNodeToggle={expandTreeItemHandler}
            defaultCollapseIcon={<ArrowDropDown />}
            defaultExpandIcon={<ArrowRight />}
          >
            { buildLocationsTree() }
          </TreeView>
        </Box>
       </>
  );
}

SidebarDynamic.propTypes = {
};

export default inject('locations', 'session')(observer(SidebarDynamic));
