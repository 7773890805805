import React, { useCallback } from "react";
// Core components
import Card from "components/Card/Card";
import GridItem from "components/Grid/GridItem";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import AuthGuard from "components/AuthGuard/AuthGuard";
import GridContainer from "components/Grid/GridContainer";
import ReactTable from "components/ReactTable/ReactTable";
// i18n
import { useTranslation } from "react-i18next";
// Icons
import { Assignment, Lock } from "@material-ui/icons";
// Styles
import { makeStyles, MenuItem, Select } from "@material-ui/core";
import DashBoardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
// Account mutations and query
import { ACCOUNTS } from "queries";
import ALL_PERSON_GROUPS from "queries/allPersonGroups";
import { UPDATE_ACCOUNT } from "mutations/account";
import { useLazyQuery, useQuery } from "@apollo/client";
import { PERMISSIONS } from "utils/session";

const styles = {
  ...DashBoardStyles,
};

const useStyles = makeStyles(styles);

const Accounts = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: allPersonGroups, loading: personGroupsLoading } = useQuery(
    ALL_PERSON_GROUPS
  );
  const [loadData, { loading, data }] = useLazyQuery(ACCOUNTS, {
    variables: {
      page: 0,
      size: 10,
    },
  });

  const getData = useCallback(({ page, size }) => {
    loadData({ variables: { page, size } });
  }, []);

  const columns = [
    {
      Header: t("First name"),
      accessor: "FirstName",
    },
    {
      Header: t("Last name"),
      accessor: "LastName",
    },
    {
      Header: t("Company"),
      accessor: "Company",
    },
    {
      Header: t("Street"),
      accessor: "Street",
    },
    {
      Header: t("Zip"),
      accessor: "ZIP",
    },
    {
      Header: t("City"),
      accessor: "City",
    },
    {
      Header: t("Country"),
      accessor: "Country",
    },
    {
      Header: t("Person Group"),
      accessor: "PersonGroupPos",
      type: "number",
      lookUp: (id) => {
        const foundItem =
        allPersonGroups && allPersonGroups.allPersonGroups.find((pg) => pg.Id === id);
        return foundItem ? foundItem.Name : "";
      },
      editing: {
        component: (
          <Select>
            {allPersonGroups &&
              allPersonGroups.allPersonGroups.map(({ Id, Name }) => (
                <MenuItem value={Id} key={Id}>
                  {Name}
                </MenuItem>
              ))}
          </Select>
        ),
        label: "Person group",
      }
    },
    {
      Header: t("Actions"),
    },
  ];

  const tableData = data ? data.accounts : { data: [], totalCount: 0, page: 0 };

  return (
    <AuthGuard permission={PERMISSIONS.accounts}>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("Accounts")}</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={columns}
                data={tableData}
                loading={personGroupsLoading || loading}
                fetchData={getData}
                addRowText={t("Add account")}
                addRowLink={"/accounts/add"}
                getQuery={{ query: ACCOUNTS, variables: {} }}
                deleteRowText={t("Are you sure you want to delete account?")}
                onUpdateQuery={UPDATE_ACCOUNT}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AuthGuard>
  );
};

export default Accounts;
