import React, { useCallback, useState } from "react";

// Core components
import Card from "components/Card/Card";
import GridItem from "components/Grid/GridItem";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
// i18n
import { useTranslation } from "react-i18next";
// Icons
import { Assignment } from "@material-ui/icons";
// Styles
import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import DashBoardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { useLazyQuery, useQuery } from "@apollo/client";
import { inject, observer } from "mobx-react";
import CollapsibleTable from "./CollapsibleTable";
import USER_ACCOUNT_LOCATIONS from "queries/users/userAccountLocations";

const styles = {
  ...DashBoardStyles,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);

const SuperAdmin = ({ session, showAlert }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, data } = useQuery(USER_ACCOUNT_LOCATIONS);

  const getData = useCallback(({ page, size }) => {
    loadData({ variables: { page, size } });
  }, []);

  const tableData = data
    ? data.allUserAccountsAndLocations
    : { data: [], totalCount: 0, page: 0 };

  const sortedAccounts = tableData.data.reduce((location, account) => {
    const { LocationId } = account;
    location[LocationId] = location[LocationId] ?? [];
    location[LocationId].push(account);
    return location;
  }, {});
  const nestedLocations = Object.values(sortedAccounts);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <GridContainer>
      <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("Locations")}</h4>
            </CardHeader>
            <CardBody>
              <CollapsibleTable
                loading={loading}
                rows={nestedLocations}
                getQuery={{ query: USER_ACCOUNT_LOCATIONS }}
              ></CollapsibleTable>
            </CardBody>
          </Card>
      </GridItem>
    </GridContainer>
  );
};

export default inject("session")(observer(SuperAdmin));
