const billingStyle = theme => ({
  padding: {
    paddingLeft: '0px'
  },
  billingCardsWrapper: {
    '& > div:nth-child(1)': {
      marginBottom: '15px'
    }
  },
  actionsContainer: {
    padding: '0 1rem'
  },
  m0: {
    margin: '0px'
  },
  cell: {
    padding: '10px 15px',
    fontSize: '15px'
  },
  textLeft: {
    textAlign: 'left'
  },
  childrenSpacing: {
    '& > div': {
      marginTop: '3px',
      marginBottom: '3px',
      textAlign: 'left',
    }
  },
  paper: {
    padding: theme.spacing(3),
  },
  billingContainer: {
    padding: theme.spacing(3),
    margin: theme.spacing(4, 0),
  },
  billingPaper: {
    padding: theme.spacing(0, 3, 1)
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  mB5: {
    marginBottom: '5px'
  },
  mB15: {
    marginBottom: '15px'
  },
  text: {
    color: '#777'
  },
  p0: {
    padding: '0'
  },
  m0: {
    margin: '0'
  },
  report: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: '6px'
    }
  },
  h5: {
    margin: 0,
    padding: 0,
    fontWeight: 'bolder',
    fontSize: '1.05em',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    }
  },
  billingTitle: {
    marginBottom: '10px'
  },
  billingDetails: {
    margin: '3px 0',
    lineHeight: '1rem',
    [theme.breakpoints.up('md')]: {
      '& > div > div': {
        lineHeight: '1.25rem',
        textAlign: 'center'
      }
    }
  }
});

export default billingStyle;
