import React from "react";
// Material UI core
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import { Skeleton } from "@material-ui/lab";
import { yearPicker } from "utils/billing";
// Styles
import { makeStyles, Paper } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import billingStyles from "assets/jss/material-dashboard-pro-react/views/billingStyle.js";
import { useTranslation } from "react-i18next";
import { getMonthNames } from "utils/graph";

const useStyles = makeStyles((theme) => ({
  ...styles,
  ...billingStyles(theme),
}));

export default function BillingActions({
  year,
  month,
  setYear,
  setMonth,
  loading,
  color,
  ...props
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const monthPicker = [{ divider: true }, ...getMonthNames(), t('all months')];
  return (
    <GridContainer className={classes.actionsContainer}>
      <GridItem xs={12} sm={6} md={6} lg={5}>
        <GridContainer className={classes.m0}>
          {loading ? (
            <>
              <GridItem className={classes.p0}>
                <Skeleton height={50} width={50} />
              </GridItem>
              <GridItem className={classes.p0}>
                <Skeleton height={50} width={50} />
              </GridItem>
              <GridItem className={classes.p0}>
                <Skeleton height={50} width={50} />
              </GridItem>
            </>
          ) : (
            <>
              <GridItem className={classes.p0}>
                {/* <CustomDropdown
                  caretActive
                  dropdownHeader={t("House picker")}
                  buttonProps={{ color: "warning" }}
                  dropdownList={housePicker(locations)}
                /> */}
              </GridItem>
              <GridItem className={classes.p0}>
                <CustomDropdown
                  caretActive
                  buttonText={year}
                  dropdownHeader={t("Year picker")}
                  selected={year}
                  onClick={(value) => setYear(value)}
                  buttonProps={{ color: color }}
                  dropdownList={yearPicker}
                />
              </GridItem>
              <GridItem className={classes.p0}>
                <CustomDropdown
                  caretActive
                  buttonText={monthPicker[month]}
                  dropdownHeader={t("Month picker")}
                  selected={monthPicker[month]}
                  onClick={(value) => setMonth(monthPicker.indexOf(value))}
                  buttonProps={{ color: color }}
                  dropdownList={monthPicker}
                />
              </GridItem>
            </>
          )}
        </GridContainer>
      </GridItem>
      {/* <GridItem xs={12} sm={6} md={6} lg={3}>
        <Button
          className={classes.report}
          round
          color="warning"
          onClick={(event) => console.log(event)}
          disabled={loading}
        >
          {t("Export excel")}
        </Button>
      </GridItem> */}
    </GridContainer>
  );
}