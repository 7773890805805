import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 1, 0, 0),
    '& $label': {
      borderRadius: '3px',
      transition: 'all 300ms ease 0s'
    },
    '&$selected > $content $label': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.text.secondary,
    },
    '& > $content $label:hover': {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.text.secondary,
    },
    '&$selected:focus > $content $label, &$selected:hover > $content $label': {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.text.secondary,
    },
  },
  content: {
    color: '#d5d4d5',
    marginTop: '3px',
    width: 'max-content',
    padding: theme.spacing(0, 3, 0, 1),
    fontWeight: theme.typography.fontWeightMedium
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  iconContainer: {
    margin: '0'
  },
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
    padding: '3px 0'
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 1)
  },
  labelIcon: {
    marginRight: theme.spacing(2),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
    color: 'inherit',
    fontSize: '14px',
  },
}));

export function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color,
    bgColor, onIconClick, onLabelClick, ...other } = props;

  function handleIconClick(event) {
    if (onIconClick) return onIconClick(event);
  }

  function handleLabelClick(event) {
    if (onLabelClick) return onLabelClick(event);
  }

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        iconContainer: classes.iconContainer,
        label: classes.label,
      }}
      onIconClick={(event) => handleIconClick(event)}
      onLabelClick={(event) => handleLabelClick(event)}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  onIconClick: PropTypes.func,
  onLabelClick: PropTypes.func,
};
