import { getLSValue, setLSValue } from "./localStorage";

export const DEFAULT_LANGUAGE = 'de';
const supportedLanguages = ['de', 'en'];

export const loadLanguage = () => {
  const currentLanguage = getLSValue('language');

  if (!currentLanguage || supportedLanguages.indexOf(currentLanguage) === -1) {
    setLSValue('language', DEFAULT_LANGUAGE);
  }

  return currentLanguage;
}

export const setLanguage = lang => {
  setLSValue('language', lang);
}
