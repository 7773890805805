import { gql } from '@apollo/client';

const UPLOAD_PROFILE_IMAGE = gql`
  mutation uploadProfileImage($file: Upload!) {
    uploadProfileImage(file: $file) {
      response
      message
    }
  }
`;

export default UPLOAD_PROFILE_IMAGE;
