import React from "react";
// Components
import Card from "components/Card/Card";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// Styles
import { makeStyles } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import billingStyles from "assets/jss/material-dashboard-pro-react/views/billingStyle.js";
// Classnames
import classNames from "classnames";
// i18n
import { useTranslation } from "react-i18next";
import { getMonthNames } from "utils/graph";

const useStyles = makeStyles((theme) => ({
  ...styles,
  ...billingStyles(theme),
}));

const IconCard = ({ hover, data, title, color, ...props }) => {
  const classes = useStyles();
  const months = getMonthNames();
  const { t } = useTranslation();

  if (!data || !data.length) return <></>;

  return (
    <Card hover={hover} {...props}>
      <CardHeader color={color} stats icon>
        <CardIcon
          color={color}
          style={
            color === "danger"
              ? {
                  background: "linear-gradient(220deg, #8150ef, #b235e5)",
                  boxShadow:
                    "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(136, 76, 237, 0.49)",
                }
              : {}
          }
        >
          <props.icon />
        </CardIcon>
        <p
          className={classNames(
            classes.cardCategory,
            classes.padding,
            classes.textLeft,
            classes.m0
          )}
        >
          {title}
        </p>
        <br />
        <br />
        <br />
        {data ? (
          <div className={classNames(classes.cardTitle, classes.padding)}>
            <GridContainer align="center">
              <TableContainer
                component={(props) => <Paper elevation={0} {...props} />}
                style={{ margin: "5px 10px" }}
              >
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    {data.map((item) => (
                      <TableRow key={item.month}>
                        <TableCell className={classes.cell}>
                          {`${t("Total")} ${months[item.month - 1]}`}
                        </TableCell>
                        <TableCell className={classes.cell} align="right">
                          <b>{`${Number(item.total).toFixed(2)} CHF`}</b>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </GridContainer>
          </div>
        ) : (
          <>
            <br />
            <br />
          </>
        )}
      </CardHeader>
    </Card>
  );
};

export default IconCard;