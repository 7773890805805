import { gql } from '@apollo/client';

const CREATE_USER = gql`
  mutation createUser($Email: String, $Password: String, $accountId: Int) {
    createUser(Email: $Email, Password: $Password, accountId: $accountId) {
      response
      message
    }
  }
`;

export default CREATE_USER;
