import React, { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import PropTypes from "prop-types";
import classNames from "classnames";
import { MP_INPUT_TYPE, MP_CONSUMPTION_TYPE, MP_OUTPUT_TYPE, MeasurePointItem} from '../MeasurePointItem/MeasurePointItem';

const useStyles = makeStyles({
  ...styles,
  locationContainer: {
    padding: '20px',
    border: '2px solid #ddd',
    borderRadius: '10px',
    background: '#fff',
    margin: '10px 25px',
  },
  active: {
    background: 'lightyellow',
    cursor: 'pointer',
  },
  locationName: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  inputPoints: {},
  mainContent: {},
  outputPoints: {},
});

export function LocationItem(props) {
  const classes = useStyles();
  const { location, children, active, onChangeActiveLocation, onClickLocation } = props;
  let measurePoints = {
    [MP_INPUT_TYPE]: [],
    [MP_CONSUMPTION_TYPE]: [],
    [MP_OUTPUT_TYPE]: [],
  };

  if (location.measurePoints && location.measurePoints.length) {
    measurePoints = location.measurePoints.reduce((acc, point) => {
      const component = (<MeasurePointItem point={point} key={point.Id}/>);
      acc[point.DirectionPos] = acc[point.DirectionPos] || [];
      return {...acc,
        [point.DirectionPos]: [...acc[point.DirectionPos], component]
      };
    }, {});
  }

  const containerClasses = classNames({
    [classes.locationContainer]: true,
    [classes.active]: active,
  });


  return (
  <Box
      className={containerClasses} display="flex" flexDirection="row"
      alignItems="center" justifyContent="space-around"
      onMouseOver={(event) => {
        if (active !== location.Id) {
          onChangeActiveLocation(location.Id);
        }
        event.stopPropagation();
      }}
      onClick={(event) => {
        onClickLocation(location.Id);
        event.stopPropagation();
      }}
      onMouseLeave={() => onChangeActiveLocation(0)}
  >
    <Box p={1} className={classes.inputPoints}>
      { measurePoints[MP_INPUT_TYPE] }
    </Box>
    <Box width="100%" className={classes.mainContent} display="flex" flexDirection="column" alignItems="center" justifyContent="space-around" >
      <Box className={classes.locationName}>{location?.Name || 'Location'}</Box>
      <Box width="100%" display="flex" flexDirection="row" alignItems="center" justifyContent="space-around" flexWrap="wrap">
        {children}
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-around">
        { measurePoints[MP_CONSUMPTION_TYPE] }
      </Box>
    </Box>
    <Box p={1} className={classes.outputPoints}>
        { measurePoints[MP_OUTPUT_TYPE] }
    </Box>
  </Box>
  )
}

const lazyLocationType = lazyFunction(function () { 
  return locationType;
}, []);

function lazyFunction(f, _lazyCheckerHasSeen) {
  return function() {
      if (_lazyCheckerHasSeen.indexOf(arguments[0]) != -1) {
          return true;
      }

      _lazyCheckerHasSeen.push(arguments[0]);
      return f().apply(this, arguments);
  }
}

const locationType = PropTypes.shape({
  Id: PropTypes.number,
  LocationPartnerPos: PropTypes.number,
  Name: PropTypes.string,
  ClientPos: PropTypes.number,
  measurePoints: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.number.isRequired,
    SensorPos: PropTypes.number,
    DirectionPos: PropTypes.number,
    Name: PropTypes.string,
    EnergyTypPos: PropTypes.number,
    StatusPos: PropTypes.number,
    TarifPos: PropTypes.number,
    JsonFieldNameValue: PropTypes.string,
    JsonFieldNameUnit: PropTypes.string,
    ClientPos: PropTypes.number,
    IsCalculated: PropTypes.number,
    LastHearbeat: PropTypes.string,
    IsMainDashboard: PropTypes.number,
  })),
  children: PropTypes.arrayOf(lazyLocationType),
});


LocationItem.propTypes = {
  location: locationType,
  hovered: PropTypes.number,
  children: PropTypes.node,
  onChangeActiveLocation: PropTypes.func,
  onClickLocation: PropTypes.func,
  active: PropTypes.bool,
};
