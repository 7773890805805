import { makeAutoObservable } from 'mobx';

class Locations {
  locations = [];

  constructor() {
    makeAutoObservable(this);
  }

  get userLocations() {
    return this.locations;
  }

  get houses() {
    return this.locations.reduce((acc, contractor) => {
      contractor.children.forEach(item => acc.push(item.Name));
      return acc;
    }, []);
  }

  setLocations(data) {
    this.locations = data;
  }
}

export default Locations;
