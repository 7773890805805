import { gql } from '@apollo/client';

const MEASURE_VALUES = gql`
  query measureValues($MeasurePointPos: Int!, $StartDate: String, $Period: MeasurePeriodEnum){
    measureValues(MeasurePointPos: $MeasurePointPos, StartDate: $StartDate, Period: $Period) {

      Company {
        name
        data {
          Id
          Name
          MeasureDateLocal
          MeasureXaxis
          unit
          used
          sale
        }
      }
      
      Solar {
        name
        data {
          Id
          Name
          MeasureDateLocal
          MeasureXaxis
          unit
          used
          sale
        }
      }

      Comparison {
        type
        current
        previous
        difference
      }

    }
  }
`;

export default MEASURE_VALUES;
