import { gql } from '@apollo/client';

const BILLING_DETAILS = gql`
  query billingDetails($month: Int, $year: Int!, $energyTypePos: Int) {
    billingDetails(month: $month, year: $year, energyTypePos: $energyTypePos) {
      payer {
        AccountType
        BillingTitle
        BillingType
        ServiceTotal
        UsageTotal
        Services {
          name
          total
        }
        Company {
          name
          used
          sale
          tarif
        }
        Solar {
          name
          used
          sale
          tarif
        }
      }

      biller {
        AccountType
        BillingTitle
        BillingType
        ServiceTotal
        UsageTotal
        Services {
          name
          total
        }
        Company {
          name
          used
          sale
        }
        Solar {
          name
          used
          sale
        }
      }
    }
  }
`;

export default BILLING_DETAILS;
