import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const exportCSVData = (data, title, consummated, pricing) => {
  if (!data.length) return;

  const result = data.map(({ Name, sale, used, MeasureDateLocal }) => {
    return {
      'Measure Point Name': Name,
      'Measure Date': MeasureDateLocal,
      Used: used,
      Cost: sale
    };
  });

  result.push({ 'Measure Point Name': '', 'Measure Date': '', Used: '', Cost: '' });
  result.push({ 'Measure Point Name': 'Total', 'Measure Date': '', Used: consummated, Cost: pricing });
  exportToCSV(result, title);
}

export const exportToCSV = (csvData, fileName) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}
