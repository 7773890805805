import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import { routes } from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import error from "assets/img/clint-mckoy.jpg";

const useStyles = makeStyles(theme => ({
  ...styles(theme),
  ...sweetAlertStyles,
  ...buttonsStyle
}));

const Errors = ({ session, ...props }) => {
  const wrapper = React.createRef();
  const classes = useStyles();

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    return function cleanup() { };
  });
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/error") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={props => <prop.component {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBgImage = () => {
    return error;
  };

  return (
    <>
      <div className={classes.wrapper} ref={wrapper}>
        <div className={classes.fullPage} style={{ backgroundImage: "url(" + getBgImage() + ")" }}>
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/error" to="/error/404" />
          </Switch>
        </div>
      </div>
    </>
  );
}

export default Errors;
