import React from 'react';
// Styles
import { Grid, makeStyles, Tooltip, Collapse } from '@material-ui/core';
// Components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
// Icons
import { Schedule, Cloud, Opacity } from "@material-ui/icons";
// Utils
import { getDayOfWeek, getIcon } from 'utils/weather';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
// Styles
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  ...styles,
  text: {
    color: 'black',
    fontSize: '16px'
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  hourlyForecastWrapper: {
    padding: theme.spacing(2, 1),
    borderTop: '1px solid rgba(201, 201, 201, 0.2)',
    paddingBottom: '5px'
  },
  icon: {
    width: '18px !important',
    height: '18px !important',
    margin: '0px !important',
    marginRight: '5px !important'
  },
  tempWrapper: {
    marginBottom: '10px',
  },
  centered: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  bolder: {
    fontWeight: 'bolder'
  }
}));

export default function WeatherPreview({ data: { data, temp_min, temp_max }, isCollapsed = false }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const previewData = data[0];
  const currentWeather = previewData.weather[0];
  const iconUrl = getIcon(previewData.weather[0].icon);

  return (
    <GridContainer justify="center" align="center">
      <GridItem xs={12} className={classNames(classes.text, classes.bolder)} style={{ fontSize: '18px' }}>
        {`${getDayOfWeek(previewData.dt_txt)}`} <small>{`${dayjs(previewData.dt_txt).format('DD/MM')}`}</small>
      </GridItem>
      <GridItem xs={12} className={classes.text}>
        <img src={iconUrl} alt={previewData.weather[0].icon} />
      </GridItem>
      <GridItem xs={12} className={classNames(classes.text, classes.tempWrapper)}>
        <GridContainer direction="row" justify="center" align="center">
          <GridItem xs={2} />
          <GridItem xs>{`${Math.floor(temp_min)} °C`}</GridItem>
          <GridItem xs>{`${Math.floor(temp_max)} °C`}</GridItem>
          <GridItem xs={2} />
        </GridContainer>
      </GridItem>
      <GridItem xs={12} className={classNames(classes.text, classes.capitalize)}>{currentWeather.description}</GridItem>

      <Collapse in={isCollapsed} unmountOnExit>
        <GridItem xs className={classes.text}>
          {
            data.map((item, index) => (
              <GridContainer className={classes.hourlyForecastWrapper} justify="center" align="center" key={index}>
                <GridItem xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Schedule className={classes.icon} /> {dayjs(item.dt_txt).format('HH:mm')}
                </GridItem>

                <GridItem xs={6}>
                  <GridContainer direction="column">
                    <GridItem xs>
                      <GridContainer direction="column" justify="flex-start" align="flex-start">
                        <GridItem xs>
                          <Tooltip id="tooltip-top" title={t('Cloudiness')} placement="top" classes={{ tooltip: classes.tooltip }}>
                            <div className={classes.centered}><Cloud color="primary" className={classes.icon} /> {item.clouds.all}%</div>
                          </Tooltip>
                        </GridItem>
                        <GridItem xs>
                          <Tooltip id="tooltip-top" title={t('Probability of precipitation')} placement="top" classes={{ tooltip: classes.tooltip }}>
                            <div className={classes.centered}><Opacity color="primary" className={classes.icon} /> {Math.floor(item.pop * 100)}%</div>
                          </Tooltip>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            ))
          }
        </GridItem>
      </Collapse>

    </GridContainer>
  );
}
