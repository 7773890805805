import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

// @material-ui/icons
import Person from "@material-ui/icons/Person";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

// MobX
import { inject, observer } from "mobx-react";

// i18n
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import CHANGE_ACCOUNT from "queries/login/changeAccount";
import { getLSValue } from "utils/localStorage";
import { setLSValue } from "utils/localStorage";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  divider: {
    marginTop: "5px",
  },
  root: {
    width: 500,
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

const HeaderLinks = ({ session, ...props }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [alert, setAlert] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [accountToChange, setAccountToChange] = useState(null);
  const [changeAccount] = useLazyQuery(CHANGE_ACCOUNT, {
    onCompleted: (data) => changeUserAccount(data),
  });

  const [openNotification, setOpenNotification] = React.useState(null);
  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const [openProfile, setOpenProfile] = React.useState(false);
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const classes = useStyles();
  const { rtlActive } = props;
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  const handleLogOut = () => {
    session.logOut();
    setOpenProfile(null);
    history.push("/auth/login-page");
  };

  const redirectTo = (route) => {
    setOpenProfile(null);
    history.push(route);
  };

  const handleClickProfile = (event) => {
    setOpenProfile((prev) => !prev);
    setAnchorEl(event.currentTarget);
  };

  const clickAway = () => {
    setOpenProfile(false);
    setAnchorEl(null);
  };

  const changeUserAccount = (data) => {
    const tokens = data.changeAccount.tokens;

    if (tokens.length) {
      const foundItem = data.changeAccount.tokens.find(
        (item) => item.accountPos === accountToChange
      );
      setLSValue("tokens", JSON.stringify(tokens));
      setLSValue("token", foundItem.token);

      session.saveToken(foundItem.token);
      setOpenProfile(false);
      history.push("/profile");
    }
  };

  const changeAccountHandler = (accountPos) => {
    const lsTokens = getLSValue("tokens");
    const parsedTokens = JSON.parse(lsTokens).map(
      ({ token, accountPos, error }) => ({
        token,
        accountPos,
        error,
      })
    );

    changeAccount({ variables: { oldTokens: parsedTokens } });
    setAccountToChange(accountPos);
  };

  return (
    <div className={wrapper}>
      <div className={managerClasses}>
        <Popper
          open={openProfile}
          anchorEl={anchorEl}
          placement="bottom-start"
          transition
          style={{ zIndex: "1301" }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper
                className={classes.dropdown}
                style={{ paddingBottom: "0px" }}
              >
                <ClickAwayListener onClickAway={clickAway}>
                  <MenuList role="menu">
                    {session.user.accounts.map((account) => (
                      <ListItem
                        button
                        key={account.Id}
                        selected={session.user.activeAccount.Id === account.Id}
                        onClick={() => changeAccountHandler(account.Id)}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <Person />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${account.FirstName} ${account.LastName}`}
                          secondary={account.Company}
                        />
                      </ListItem>
                    ))}

                    {session.user.accounts.length ? (
                      <Divider
                        className={classes.divider}
                        style={{ marginBottom: "5px" }}
                      />
                    ) : null}

                    <MenuItem onClick={handleLogOut} className={dropdownItem}>
                      {t("Log out")}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}
        >
          <Person className={classes.headerLinksSvg} />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>Profile</span>
          </Hidden>
        </Button>

        <LanguageSwitcher />
      </div>
    </div>
  );
};

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};

export default inject("session")(observer(HeaderLinks));
