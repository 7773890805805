import { gql } from '@apollo/client';

const DELETE_USER = gql`
  mutation deleteUser($id: Int!) {
    deleteUser(id: $id) {
      response
      message
    }
  }
`;

export default DELETE_USER;
