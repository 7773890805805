import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { getLSValue } from 'utils/localStorage';
import jwtDecode from 'jwt-decode';

const App = (props) => {
  const [loaded, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (!props.session.user.token) {
      const lsToken = getLSValue('token');
      const lsTokens = getLSValue('tokens');

      if (lsTokens) {
        try {
          const decodedAccounts = JSON.parse(lsTokens).map(tokenAccount => {
            const decodedToken = jwtDecode(tokenAccount.token);
            return decodedToken.account;
          });
  
          props.session.setUserAccounts(decodedAccounts); 
        } catch(err) {
          console.error(err);
        }
      }

      if (lsToken) {
        props.session.saveToken(lsToken);
      } else {
        props.session.logOut();
      }
    }
  }, [props.session.token]);

  return <>{loaded && props.children}</>;
}

export default inject('session')(observer(App));
