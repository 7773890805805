import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Card from 'components/Card/Card';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import ReactTable from "components/ReactTable/ReactTable.js";
import { makeStyles } from '@material-ui/styles';
import { RemoveRedEye, Folder, FilterList } from '@material-ui/icons';
import { InputAdornment } from '@material-ui/core';
import AuthGuard from 'components/AuthGuard/AuthGuard';
import CustomInput from "components/CustomInput/CustomInput.js";
import { PAGINATED_INVOICES } from 'queries';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { useHistory } from 'react-router';
import { useDebounce } from '@react-hook/debounce';

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: '#dedede'
  },
  h2: {
    marginBottom: '10px'
  },
  cardIconTitle: {
    color: 'black'
  }
}));

export default function PayerInvoices() {
  const history = useHistory();
  const [loadData, { loading, data }] = useLazyQuery(PAGINATED_INVOICES, {
    variables: {
      page: 0,
      size: 5
    },
    fetchPolicy: 'no-cache'
  });
  const [filteredInvoice, setFilteredInvoice] = useDebounce({}, 200);
  const { t } = useTranslation();
  const classes = useStyles();

  const getData = useCallback(props => {
    loadData({ variables: { ...props } });
  }, []);

  const columns = [
    {
      Header: t('Date'),
      accessor: 'Date',
      disableFilters: true,
      editable: false,
    },
    {
      Header: t('Energy type'),
      accessor: 'EnergyType',
      disableFilters: true,
      editable: false,
    },
    {
      Header: t('Billing text'),
      accessor: 'BillingText',
      disableSortBy: true,
      Filter: () => {
        return (
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: filteredInvoice.BillingText,
              onChange: e => {
                const filteredObject = e.target.value ? { "BillingText": e.target.value } : {};
                setFilteredInvoice(filteredObject);
              },
              startAdornment: (
                <InputAdornment position="start">
                  <FilterList />
                </InputAdornment>
              )
            }}
          />
        );
      },
      editable: false,
    },
    {
      Header: t('Total (CHF)'),
      accessor: 'BillTotalPrice',
      type: 'float',
      disableFilters: true,
      editable: false,
    },
    {
      Header: t('Actions')
    }
  ];

  const state = useMemo(() => columns, []);
  const payerTableData = data ? data.paginatedInvoices.payer : { data: [], totalCount: 0, page: 0 };

  return (
    <AuthGuard permission="all">
      <GridContainer direction="column">
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Folder />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("Payer invoices")}</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={state}
                data={payerTableData}
                loading={loading}
                fetchData={getData}
                filteredObject={filteredInvoice}
                extraActionButtons={[{
                  title: t('Preview'),
                  icon: RemoveRedEye,
                  callback: row => history.push(`/invoices/${row.Id}`)
                }]}
                getQuery={{ query: PAGINATED_INVOICES }} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AuthGuard>
  );
}
