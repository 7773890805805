/*eslint-disable*/
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import { useTranslation } from "react-i18next";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import SidebarDynamic from "../SidebarDynamic/SidebarDynamic.js";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";
import { inject, observer } from "mobx-react";

const useStyles = makeStyles(sidebarStyle);

import avatar from "assets/img/faces/marc.jpg";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { fetchingImage } from "utils/image.js";
import LOGO_IMG from "assets/img/logo.png";
import { Skeleton } from "@material-ui/lab";

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, user, headerLinks, links } = this.props;
    return (
      <div className={className} ref={this.sidebarWrapper}>
        {user}
        {headerLinks}
        {links}
        <SidebarDynamic selectedLocationId={this.props.selectedLocationId} />
      </div>
    );
  }
}

const Sidebar = (props) => {
  const { activeAccount } = props.session;
  const [profileImage, setProfileImage] = useState(null);
  const [companyLogo, setCompanyLogo] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const [state, setState] = useState({
    openAvatar: false,
    miniActive: true,
    ...getCollapseStates(props.routes),
  });

  React.useEffect(() => {
    if (props.session.companyLogo) {
      fetchServerImage(props.session.companyLogo);
    } else {
      setCompanyLogo(null);
    }
  }, [props.session.companyLogo]);

  useEffect(() => {
    if (activeAccount.ImageURL) {
      fetchImage(activeAccount.ImageURL);
    } else {
      setProfileImage(null);
    }
  }, [activeAccount.ImageURL]);

  const fetchServerImage = (url) => {
    setLoading(true);

    fetchingImage(url)
      .then((res) => {
        setCompanyLogo(res);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const fetchImage = (url) => {
    fetchingImage(url)
      .then((res) => setProfileImage(res))
      .catch((e) => console.error(e));
  };

  const sessionUser = props.session.user;
  const mainPanel = React.createRef();
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  function getCollapseStates(routes) {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  }
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  function getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };
  const openCollapse = (collapse) => {
    var st = {};
    st[collapse] = !state[collapse];
    setState({ ...state, st });
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    const { color, rtlActive } = props;

    return routes.map((prop, key) => {
      if (prop.private && !props.session.user.activeAccount.IsPersonGroupAdmin)
        return;

      if (prop.permission && !sessionUser.permissions.includes(prop.permission))
        return;
      if (prop.redirect || prop.hideInSidebar) return null;

      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        const navLinkClasses =
          classes.itemLink +
          " " +
          cx({
            [" " + classes.collapseActive]: getCollapseInitialState(prop.views),
          });
        const itemText =
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]: props.miniActive && state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && props.miniActive && state.miniActive,
            [classes.itemTextRTL]: rtlActive,
          });
        const collapseItemText =
          classes.collapseItemText +
          " " +
          cx({
            [classes.collapseItemTextMini]:
              props.miniActive && state.miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && props.miniActive && state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive,
          });
        const itemIcon =
          classes.itemIcon +
          " " +
          cx({
            [classes.itemIconRTL]: rtlActive,
          });
        const caret =
          classes.caret +
          " " +
          cx({
            [classes.caretRTL]: rtlActive,
          });
        const collapseItemMini =
          classes.collapseItemMini +
          " " +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive,
          });
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, st });
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
              )}
              <ListItemText
                primary={rtlActive ? prop.rtlName : t(prop.name)}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      (state[prop.state] ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>
            <Collapse in={state[prop.state]} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        cx({
          [" " + classes[color]]: activeRoute(prop.path),
        });
      const collapseItemMini =
        classes.collapseItemMini +
        " " +
        cx({
          [classes.collapseItemMiniRTL]: rtlActive,
        });
      const navLinkClasses =
        classes.itemLink +
        " " +
        cx({
          [" " + classes[color]]: activeRoute(prop.path),
        });
      const itemText =
        classes.itemText +
        " " +
        cx({
          [classes.itemTextMini]: props.miniActive && state.miniActive,
          [classes.itemTextMiniRTL]:
            rtlActive && props.miniActive && state.miniActive,
          [classes.itemTextRTL]: rtlActive,
        });
      const collapseItemText =
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]: props.miniActive && state.miniActive,
          [classes.collapseItemTextMiniRTL]:
            rtlActive && props.miniActive && state.miniActive,
          [classes.collapseItemTextRTL]: rtlActive,
        });
      const itemIcon =
        classes.itemIcon +
        " " +
        cx({
          [classes.itemIconRTL]: rtlActive,
        });

      let to = "";

      if (prop.layout === "/") {
        to = prop.path;
      } else {
        to = props.layout + prop.path;
      }

      if (prop.path == "/billing/:id") {
        to = `/billing/${props.session.activeBillingEnergyTab}`;
      }

      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined }
          )}
        >
          <NavLink
            to={to}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined }
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === "string" ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={itemIcon} />
              )
            ) : (
              <span className={collapseItemMini}>
                {rtlActive ? prop.rtlMini : prop.mini}
              </span>
            )}
            <ListItemText
              primary={rtlActive ? prop.rtlName : t(prop.name)}
              disableTypography={true}
              className={cx(
                { [itemText]: prop.icon !== undefined },
                { [collapseItemText]: prop.icon === undefined }
              )}
            />
          </NavLink>
        </ListItem>
      );
    });
  };
  const {
    image,
    logoText,
    routes,
    bgColor,
    selectedLocationId,
    rtlActive,
  } = props;
  const itemText =
    classes.itemText +
    " " +
    cx({
      [classes.itemTextMini]: props.miniActive && state.miniActive,
      [classes.itemTextMiniRTL]:
        rtlActive && props.miniActive && state.miniActive,
      [classes.itemTextRTL]: rtlActive,
    });
  const collapseItemText =
    classes.collapseItemText +
    " " +
    cx({
      [classes.collapseItemTextMini]: props.miniActive && state.miniActive,
      [classes.collapseItemTextMiniRTL]:
        rtlActive && props.miniActive && state.miniActive,
      [classes.collapseItemTextRTL]: rtlActive,
    });
  const userWrapperClass =
    classes.user +
    " " +
    cx({
      [classes.whiteAfter]: bgColor === "white",
    });
  const caret =
    classes.caret +
    " " +
    cx({
      [classes.caretRTL]: rtlActive,
    });
  const collapseItemMini =
    classes.collapseItemMini +
    " " +
    cx({
      [classes.collapseItemMiniRTL]: rtlActive,
    });
  const photo =
    classes.photo +
    " " +
    cx({
      [classes.photoRTL]: rtlActive,
    });

  const fullName = `${sessionUser.firstName} ${sessionUser.lastName}`;

  var user = (
    <div className={userWrapperClass}>
      <div className={photo}>
        <img
          src={profileImage || avatar}
          className={classes.avatarImg}
          alt="..."
        />
      </div>
      <List className={classes.list}>
        <ListItem className={classes.item + " " + classes.userItem}>
          <NavLink
            to="/profile"
            className={classes.itemLink + " " + classes.userCollapseButton}
            onClick={() => openCollapse("openAvatar")}
          >
            <ListItemText
              primary={fullName}
              disableTypography={true}
              className={itemText + " " + classes.userItemText}
            />
          </NavLink>
        </ListItem>
      </List>
    </div>
  );
  var links = <List className={classes.list}>{createLinks(routes)}</List>;

  const logoNormal =
    classes.logoNormal +
    " " +
    cx({
      [classes.logoNormalSidebarMini]: props.miniActive && state.miniActive,
      [classes.logoNormalSidebarMiniRTL]:
        rtlActive && props.miniActive && state.miniActive,
      [classes.logoNormalRTL]: rtlActive,
    });
  const logoMini =
    classes.logoMini +
    " " +
    cx({
      [classes.logoMiniRTL]: rtlActive,
    });
  const logoClasses =
    classes.logo +
    " " +
    cx({
      [classes.whiteAfter]: bgColor === "white",
    });
  var brand = (
    <div className={logoClasses}>
      <a
        href="https://www.creative-tim.com?ref=mdpr-sidebar"
        target="_blank"
        className={logoMini}
      >
        {loading ? (
          <Skeleton height={40} width={100} style={{ backgroundColor: "rgba(255,255,255,0.2)" }}/>
        ) : (
          <img
            src={companyLogo || LOGO_IMG}
            alt="logo"
            className={classes.img}
          />
        )}
      </a>
      <a
        href="https://www.creative-tim.com?ref=mdpr-sidebar"
        target="_blank"
        className={logoNormal}
      >
        {logoText}
      </a>
    </div>
  );
  const drawerPaper =
    classes.drawerPaper +
    " " +
    cx({
      [classes.drawerPaperMini]: props.miniActive && state.miniActive,
      [classes.drawerPaperRTL]: rtlActive,
    });
  const sidebarWrapper =
    classes.sidebarWrapper +
    " " +
    cx({
      [classes.drawerPaperMini]: props.miniActive && state.miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  return (
    <div ref={mainPanel}>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: drawerPaper + " " + classes[bgColor + "Background"],
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          {"before"}
          <SidebarWrapper
            className={sidebarWrapper}
            selectedLocationId={selectedLocationId}
            user={user}
            headerLinks={<AdminNavbarLinks rtlActive={rtlActive} />}
            links={links}
          />
          {"after"}
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          onMouseOver={() => setState({ ...state, miniActive: false })}
          onMouseOut={() => setState({ ...state, miniActive: true })}
          anchor={rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: drawerPaper + " " + classes[bgColor + "Background"],
          }}
        >
          {brand}
          {"before"}
          <SidebarWrapper
            className={sidebarWrapper}
            selectedLocationId={selectedLocationId}
            user={user}
            links={links}
          />
          {"before"}
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.defaultProps = {
  bgColor: "blue",
};

Sidebar.propTypes = {
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
  ]),
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  headerLinks: PropTypes.object,
  selectedLocationId: PropTypes.string,
  links: PropTypes.object,
};

export default inject("session")(observer(Sidebar));
