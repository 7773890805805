import React, { useEffect, useState } from "react";

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { LinearProgress } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

// Icons
import { Person } from "@material-ui/icons";

// Styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  cardFooter: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 0,
  },
}));

export default function ChooseAccount({
  loginData,
  backToLogin,
  selectAccount,
  selectedAccount,
  error,
  loading,
  ...props
}) {
  const classes = useStyles();
  const [cardAnimation, setCardAnimation] = useState("cardHidden");
  const { t } = useTranslation();

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 300);
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const loginHandler = (account, error) => () => {
    if (error) return;
    selectAccount(account);
  };

  return (
    <Card login className={classes[cardAnimation]}>
      <CardHeader
        className={`${classes.cardHeader} ${classes.textCenter}`}
        color="rose"
      >
        <h4 className={classes.cardTitle}>{t("Choose account")}</h4>
      </CardHeader>
      <CardBody>
        <List className={classes.root}>
          {loginData.map(({ account, error }) => (
            <div key={account.Id}>
              <ListItem
                button
                disabled={Boolean(error)}
                selected={selectedAccount === account.Id}
                onClick={loginHandler(account, error)}
              >
                <ListItemAvatar>
                  <Avatar>
                    <Person />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${account.FirstName} ${account.LastName}`}
                  secondary={account.Company}
                />
              </ListItem>
              {error && <div className={classes.error}>{error}</div>}
            </div>
          ))}
        </List>
      </CardBody>
      <CardFooter
        className={classNames(classes.justifyContentCenter, classes.cardFooter)}
      >
        <Button
          block
          simple
          size="lg"
          color="primary"
          type="button"
          onClick={backToLogin}
        >
          {t("Back")}
        </Button>
      </CardFooter>
      {loading && <LinearProgress />}
    </Card>
  );
}
