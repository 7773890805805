import { gql } from '@apollo/client';

const DELETE_ACCOUNT = gql`
  mutation($id: Int!) {
    deleteAccount(id: $id){
      response
      message
    }
  }
`;

export default DELETE_ACCOUNT;