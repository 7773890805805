import { gql } from "@apollo/client";

const PAGINATED_PERSON_GROUP_TYPES = gql`
  query paginatedPersonGroupTypes($page: Int!, $size: Int!) {
    paginatedPersonGroupTypes(page: $page, size: $size) {
      data {
        Id
        Name
        Title
        Admin
        Permissions {
          Id
          Name
          Area
          Description
        }
      }
      page
      totalCount
    }
  }
`;

export default PAGINATED_PERSON_GROUP_TYPES;
