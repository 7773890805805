import { gql } from '@apollo/client'; 

const REFRESH_TOKEN = gql`
  query refreshToken {
    refreshToken {
      tokens {
        accountPos
        token
        error
      }
    }
  }
`;

export default REFRESH_TOKEN;
