import { gql } from '@apollo/client';

const REMOVE_PDF_IMAGE = gql`
  mutation removePdfImage {
    removePdfImage {
      response
      message
    }
  }
`;

export default REMOVE_PDF_IMAGE;
