import React, { useEffect, useState } from "react";
// Apollo
import { useQuery } from "@apollo/client";
import SMART_ME_REAL_TIME_CURRENT_POWER from "queries/smartMeDevice/smartMeRealTimeCurrentpower";
// Material UI
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
// Components
import AnimatedArrows from "components/AnimatedArrows";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// Icons
import { House, Cancel } from '@material-ui/icons';
import { ReactComponent as ElectricityTower } from 'assets/img/electricity-tower.svg';
import { ReactComponent as SolarPanel } from 'assets/img/solar-panel.svg';
// i18n
import { useTranslation } from "react-i18next";
// Styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { inject, observer } from "mobx-react";
import classNames from "classnames";
import { fetchingImage } from "utils/image";

const useStyles = makeStyles(theme => ({
  ...styles,
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(4)
  },
  text: {
    color: 'black',
    textAlign: 'center'
  },
  bordered: {
    padding: '0px',
    border: '1px solid gray',
    borderRadius: '5px'
  },
  unitValue: {
    color: 'black',
    textAlign: 'right',
    margin: '10px 10px 0 0'
  },
  emptyPipe: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20px',
    borderWidth: '1px 0',
    borderColor: 'grey',
    borderStyle: 'solid'
  },
  centerBlock: {
    width: '100%',
    padding: '0 !important',
    overflow: 'hidden',
    border: '1px solid gray',
    borderRadius: '5px'
  },
  defaultHouseImage: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  houseImage: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    "& img": {
      width: '100%',
      height: '100%'
    }
  },
  boxesWrapper: {
    [theme.breakpoints.down('md')]: {
      marginTop: '15px',
      padding: '0 50px !important'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 25px !important'
    }
  },
  pipe: {
    [theme.breakpoints.down('xs')]: {
      width: '55px !important',
      transform: 'rotate(90deg)',
      zIndex: '-1'
    }
  },
  pipeContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: '15px 0',
      flexDirection: 'row'
    }
  },
  centeredImageContainer: {
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  centeredImage: {
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '80%'
    }
  }
}));

const CurrentPower = ({ session }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [solarPower, setSolarPower] = useState({ value: 0, unit: '' });
  const [companyPower, setCompanyPower] = useState({ value: 0, unit: '' });
  const [housePower, setHousePower] = useState(0);
  const [loading, setLoading] = useState(false);
  const realTimeCurrentPower = useQuery(SMART_ME_REAL_TIME_CURRENT_POWER, {
    variables: { ClientPos: session.activeAccount.clientId }, fetchPolicy: 'no-cache',
  });
  const [houseImage, setHouseImage] = useState('');

  useEffect(() => {
    if (session.activeAccount.WidgetImageUrl) {
      setLoading(true);
      fetchingImage(session.activeAccount.WidgetImageUrl)
        .then(res => {
          setHouseImage(res);
          setLoading(false);
        })
        .catch(e => {
          console.error(e);
          setLoading(false);
        });
    }
  }, [session.activeAccount.WidgetImageUrl]);

  useEffect(() => {
    realTimeCurrentPower.startPolling(5000)
    return () => {
      realTimeCurrentPower.stopPolling()
    }
  }, [realTimeCurrentPower.startPolling, realTimeCurrentPower.stopPolling]);

  useEffect(() => {
    if (realTimeCurrentPower.data) {
      const data = realTimeCurrentPower.data.smartMeRealTimeCurrentPower;
      data.map(({ ActivePower, ActivePowerUnit, MeasurePoint }) => {
        const solarPoint = MeasurePoint.match('Solar');
        const value = Number(ActivePower).toFixed(3);

        if (solarPoint) {
          setSolarPower({ ...solarPower, value: Number(value), unit: ActivePowerUnit });
        } else {
          setCompanyPower({ ...companyPower, value: Number(value), unit: ActivePowerUnit });
        }
      });
    }
  }, [realTimeCurrentPower]);

  useEffect(() => {
    calculateHousePower();
  }, [solarPower.value, companyPower.value]);

  const calculateHousePower = () => {
    const result = solarPower.value + companyPower.value;
    setHousePower(Math.abs(Number(result)).toFixed(3));
  }

  const renderHouseImage = () => {
    if (loading) {
      return <Skeleton />;
    }

    if (!houseImage) {
      return (<div className={classNames(classes.text, classes.defaultHouseImage)}>
        <House />
      </div>);
    }

    return <div className={classes.houseImage}><img src={houseImage} alt="..." /></div>;
  }

  return (
    <div className={classes.root}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} lg={8} className={classes.boxesWrapper}>
          <GridContainer alignItems="center">
            <GridItem xs={12} sm={4} md={4} lg={4}>
              <GridContainer direction="row" justify="center" alignItems="center" spacing={0}>
                <GridItem xs={12} sm={4} md={4} lg={4} className={classes.bordered}>
                  <div className={classes.text}>
                    <SolarPanel />
                  </div>
                </GridItem>

                <GridItem xs={12} sm={8} md={8} lg={8}>
                  <GridContainer direction="column" justify="center" align="center" className={classes.pipeContainer}>
                    <div className={classes.text}>{`${solarPower.value} ${solarPower.unit}`}</div>
                    {
                      solarPower.unit > 0
                        ? (<AnimatedArrows direction="right" className={classes.pipe} />)
                        : (<div className={classes.emptyPipe}></div>)
                    }
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={12} sm={4} md={4} lg={4} style={{ height: houseImage ? '200px' : '100px' }} className={classes.centeredImageContainer}>
              <GridContainer direction="column" justify="center" alignItems="center" spacing={0} className={classes.centeredImage}>
              <GridItem xs className={classes.bordered && classes.centerBlock}>
                  {renderHouseImage()}
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={12} sm={4} md={4} lg={4}>
              <GridContainer direction="row" justify="center" alignItems="center" spacing={0}>
                <GridItem xs={12} sm={8} md={8} lg={8}>
                  <GridContainer justify="center" align="center" className={classes.pipeContainer}>
                    <div className={classes.text}>{`${companyPower.value} ${companyPower.unit}`}</div>
                    <AnimatedArrows direction={Number(companyPower.value) < 0 ? 'right' : 'left'} className={classes.pipe} />
                  </GridContainer>
                </GridItem>

                <GridItem xs={12} sm={4} md={4} lg={4} className={classes.bordered}>
                  <div className={classes.text}>
                    <ElectricityTower />
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>

          <GridContainer align="center" justify="center">
            <GridItem xs>
              <GridContainer justify="center" align="center">
                <div className={classes.text} style={{ marginTop: '5px' }}>{housePower} kW</div>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default inject('session')(observer(CurrentPower));
