import { useLocation } from 'react-router-dom'

export default function useQueryParams() {
  const location = useLocation()

  const getKey = (key) => {
    const queryParams = new URLSearchParams(location.search)
    return queryParams.get(key)
  }

  const setKey = (key, value) => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.set(key, value)
    window.history.replaceState('', '', `?${queryParams.toString()}`)
    return queryParams.toString()
  }

  const setKeys = (keys) => {
    const queryParams = new URLSearchParams(location.search)
    if (!keys.length) return
    keys.forEach(({ key, value }) => queryParams.set(key, value))
    window.history.replaceState('', '', `?${queryParams.toString()}`)
    return queryParams.toString()
  }

  return {
    getKey,
    setKey,
    setKeys,
  }
}
