import { useCallback } from "react";
import { useLazyQuery } from "@apollo/client";
import PAGINATED_PERSON_GROUP_TYPES from "queries/personGroupTypes";

export default function usePersonGroupTypes({ defaultSize }) {
  const [loadData, { loading, data }] = useLazyQuery(
    PAGINATED_PERSON_GROUP_TYPES,
    {
      variables: {
        page: 0,
        size: defaultSize,
      },
      fetchPolicy: "no-cache",
    }
  );

  const fetchPersonGroupTypes = useCallback(({ page, size }) => {
    loadData({ variables: { page, size } });
  }, []);

  return {
    data,
    loading,
    fetchPersonGroupTypes,
  };
}
