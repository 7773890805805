import PERMISSIONS_QUERY from "queries/permissions";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { REFRESH_TOKEN } from "queries";
import { useState } from "react";
import { setLSValue, getLSValue } from "utils/localStorage";
import { UPDATE_PERMISSIONS} from "mutations/permissions";

export const usePermissions = (session) => {
  const { data: permissions, loading: permissionsLoading } = useQuery(
    PERMISSIONS_QUERY,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [refreshToken, { loading: refreshTokenLoading }] = useLazyQuery(
    REFRESH_TOKEN,
    {
      onCompleted: (data) => {
        if (data.refreshToken.tokens) {
          updateLocalStorageTokens(data.refreshToken);
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  const [updatePermissions, { loading: updatePermissionsLoading }] = useMutation(
    UPDATE_PERMISSIONS,
    {
      onCompleted: (data) => {
        if (data.updatePermissions.response) refreshToken();
      },
      fetchPolicy: "no-cache",
    }
  );
  const [personGroupTypId, setPersonGroupTypId] = useState(null);
  const [modalValues, setModalValues] = useState([]);

  const updateLocalStorageTokens = ({ tokens }) => {

    const newToken = tokens.find( t => t.accountPos === session.user.activeAccount.Id ).token;
    setLSValue("token", newToken);
    const oldTokens = getLSValue("tokens");
    session.saveToken(newToken);

    if (oldTokens) {
      setLSValue("tokens", JSON.stringify(tokens));
    }
  };

  return {
    permissions,
    loading:
      permissionsLoading ||
      refreshTokenLoading ||
      updatePermissionsLoading,
    updatePermissions,
    personGroupTypId,
    setPersonGroupTypId,
    modalValues,
    setModalValues,
  };
};
