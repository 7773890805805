import { gql } from '@apollo/client';

const GENERATE_RESET_TOKEN = gql`
  query generateResetToken($Email: String!){
    generateResetToken(Email: $Email) {
      response
      message
    }
  }
`;

export default GENERATE_RESET_TOKEN;