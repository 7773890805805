import React from 'react';
// Material UI core
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// Icons
import { FlashOn, Opacity, Whatshot, WbSunny } from '@material-ui/icons';
import { ReactComponent as Gas } from 'assets/img/gas.svg';
// Styles
import { makeStyles } from '@material-ui/core';
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import billingStyles from "assets/jss/material-dashboard-pro-react/views/billingStyle.js";
// Utils
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  ...styles,
  ...billingStyles(theme),
  billingValue: {
    [theme.breakpoints.down("sm")]: {
      textAlign: 'center',
      margin: '5px 0'
    }
  }
}));

const BillingDetail = ({ company, solar, energyType }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const renderDetails = (data, icon) => {
    if (!data.length) return;
    let Icon, iconColor = '#ff9800', measureUnit = 'kWh';

    if (icon) {
      Icon = icon;
    } else {
      const iconHash = {
        '1': { i: FlashOn, color: '#ff9800', mUnit: 'kWh' },
        '2': { i: Opacity, color: '#ec407a', mUnit: 'm3' },
        '3': { i: Opacity, color: '#00acc1', mUnit: 'm3' },
        '4': { i: Whatshot, color: '#9c27b0', mUnit: 'm3' }
      };
      const { i, color, mUnit } = iconHash[energyType.id];
      Icon = i;
      iconColor = color;
      measureUnit = mUnit;
    }

    return (
      <GridContainer>
        <GridItem xs={2} sm={2} md={3} lg={2}>
          <GridContainer justify="center">
            <Icon style={{ color: iconColor }} fontSize="large" />
          </GridContainer>
        </GridItem>

        <GridItem xs={10} m={10} md={9} lg={8}>
          <GridContainer>
            {data.map(({ name, used, sale, tarif }, index) => (
              <GridItem className={classes.billingDetails} xs={12} sm={12} md={12} lg={12} style={{ marginBottom: '10px' }} key={index}>
                <GridContainer alignItems="center">
                  <GridItem xs={12} sm={12} md={12} lg={3} className={classes.billingValue}>
                    <h5 className={classNames(classes.text, classes.h5)}>{t(name)}</h5>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={3} className={classes.billingValue}>
                    {Number(used).toFixed(2)} <small><b>{measureUnit}</b></small>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={3} className={classes.billingValue}>
                    <small>{Number(tarif).toFixed(4)} <b>CHF/{measureUnit}</b></small>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={3} className={classes.billingValue}>
                    {Number(sale).toFixed(2)} <small><b>CHF</b></small>
                  </GridItem>
                </GridContainer>
              </GridItem>
            ))}
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  };

  return (
    <>
      {renderDetails(solar, WbSunny)}
      {renderDetails(company)}
    </>
  );
}

export default BillingDetail;
