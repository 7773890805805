import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { Email, Face, RecordVoiceOver } from "@material-ui/icons";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  ...customSelectStyle
};

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      FirstName: "",
      FirstNameState: "",
      LastName: "",
      LastNameState: "",
      Email: "",
      EmailState: "",
    };
  }
  sendState() {
    return {
      FirstName: this.state.FirstName,
      LastName: this.state.LastName,
      Email: this.state.Email
    };
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    if (type === 'email') {
      if (this.verifyEmail(event.target.value)) {
        this.setState({ [stateName + "State"]: "success" });
      } else {
        this.setState({ [stateName + "State"]: "error" });
      }
    } else {
      if (this.verifyLength(event.target.value, stateNameEqualTo)) {
        this.setState({ [stateName + "State"]: "success" });
      } else {
        this.setState({ [stateName + "State"]: "error" });
      }
    }

    this.setState({ [stateName]: event.target.value });
  }
  isValidated() {
    if (
      this.state.FirstNameState === "success" &&
      this.state.LastNameState === "success" &&
      this.state.EmailState === "success"
    ) {
      return true;
    } else {
      if (this.state.FirstNameState !== "success") {
        this.setState({ FirstNameState: "error" });
      }
      if (this.state.LastNameState !== "success") {
        this.setState({ LastNameState: "error" });
      }
      if (this.state.EmailState !== "success") {
        this.setState({ EmailState: "error" });
      }
    }
    return false;
  }
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={6}>
          <CustomInput
            success={this.state.FirstNameState === "success"}
            error={this.state.FirstNameState === "error"}
            labelText={<span>First Name <small>(required)</small></span>}
            id="FirstName"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              onChange: event => this.change(event, "FirstName", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            success={this.state.LastNameState === "success"}
            error={this.state.LastNameState === "error"}
            labelText={<span>Last Name <small>(required)</small></span>}
            id="LastName"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              onChange: event => this.change(event, "LastName", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <RecordVoiceOver className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            success={this.state.EmailState === "success"}
            error={this.state.EmailState === "error"}
            labelText={<span>Email <small>(required)</small></span>}
            id="Email"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: 'email',
              onChange: event => this.change(event, "Email", "email"),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

Step3.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Step3);
