import React, { useState } from "react";
// Datepicker
import Datetime from "react-datetime";
// Components
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
// i18n
import { useTranslation } from "react-i18next";
// Styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  ...styles,
  color: {
    color: '#3c4858'
  },
  wrapper: {
    paddingBottom: theme.spacing(3)
  }
}));

export default function Earnings(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [timeRange, setTimeRange] = useState({
    startDate: '',
    endDate: ''
  });

  return (
    <GridContainer className={classNames(classes.color, classes.wrapper)}>
      <GridItem xs={4} style={{ alignItems: 'center', display: 'flex' }}>
        <GridContainer direction="column" justify="center" align="center">
          <GridItem xs>
            <Datetime
              timeFormat={false}
              className={classes.color}
              onChange={event => console.log(event)}
              inputProps={{ placeholder: t('Start date') }}
            />
          </GridItem>
          <GridItem xs>
            <Datetime
              timeFormat={false}
              className={classes.color}
              onChange={event => console.log(event)}
              inputProps={{ placeholder: t('End date') }}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={8}>
        <GridContainer direction="column" style={{ height: '100%' }}>
          <GridItem xs><h5><b>{t('Cost')}</b> - 1231 <small><b>CHF</b></small></h5></GridItem>
          <GridItem xs><h5><b>{t('Sell')}</b> - 1436 <small><b>CHF</b></small></h5></GridItem>
          <GridItem xs><h5><b>{t('Earnings')}</b> - 9800 <small><b>CHF</b></small></h5></GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
}
