import { gql } from '@apollo/client'; 

const LOCATIONS = gql`
  query locations($personGroupPos: Int!){
    locations(personGroupPos: $personGroupPos) {
      Id
      LocationPartnerPos
      Name
      ClientPos
    }
  }
`;

export default LOCATIONS;
