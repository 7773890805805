import React from "react";
// Material UI core
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import IconCard from "../IconCard";
// Icons
import { FlashOn, Opacity, Whatshot } from "@material-ui/icons";
import { ReactComponent as Gas } from "assets/img/gas.svg";
import { Skeleton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { useMonthlyBilling } from "../hooks/useMonthlyBilling";

const MonthlyBilling = ({ energyType, onChangeEnergyType }) => {
  const { t } = useTranslation();
  const { data, loading, getBillingMonthlyByEnergyType } = useMonthlyBilling();

  return (
    <GridContainer>
      {!data || loading ? (
        [...Array(4)].map((_, i) => (
          <GridItem xs={12} sm={6} md={6} lg={3} key={i}>
            <Skeleton height={150} />
          </GridItem>
        ))
      ) : (
        <>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <IconCard
              icon={FlashOn}
              color="warning"
              title={t("Electricity")}
              hover={energyType.color !== "warning"}
              onClick={() => onChangeEnergyType({ id: 1, color: "warning" })}
              data={getBillingMonthlyByEnergyType("Electricity")}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <IconCard
              hover={energyType.color !== "info"}
              icon={Opacity}
              title={t("Water warm")}
              color="info"
              onClick={() => onChangeEnergyType({ id: 2, color: "info" })}
              data={getBillingMonthlyByEnergyType("WaterWarm")}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <IconCard
              hover={energyType.color !== "danger"}
              icon={Whatshot}
              title={t("Heating")}
              color="danger"
              onClick={() => onChangeEnergyType({ id: 3, color: "danger" })}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <IconCard
              hover={energyType.color !== "rose"}
              icon={Gas}
              title={t("Gas")}
              color="rose"
              onClick={() => onChangeEnergyType({ id: 4, color: "rose" })}
            />
          </GridItem>
        </>
      )}
    </GridContainer>
  );
};

export default MonthlyBilling;
