import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import { setLanguage } from 'utils/language';
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  ...styles(theme),
  root: {
    width: 500,
  },
  typography: {
    padding: theme.spacing(2),
  },
  buttonLink: {
    fontSize: '16px',
    [theme.breakpoints.down("md")]: {
      marginLeft: '1.25rem'
    }
  }
}));

const LanguageSwitcher = ({ session }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const languages = Object.keys(i18n.options.resources);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpened, setOpened] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(languages.indexOf(i18n.language));

  const handleLangSelect = (lang, index) => {
    i18n.changeLanguage(lang);
    setSelectedIndex(index);
    setLanguage(lang);
    session.changeLanguage(lang);
    handleClose();
  };

  const handleClose = () => setOpened(false);

  const menuItems = languages.map((lang, i) =>
    (<MenuItem key={i} onClick={() => handleLangSelect(lang, i)} selected={i === selectedIndex}>{lang.toUpperCase()}</MenuItem>)
  );

  const handleClick = event => {
    setOpened((prev) => !prev);
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        color="transparent"
        aria-label="lang-menu"
        justIcon
        aria-owns={isOpened ? "lang-menu" : null}
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.buttonLink}
      >
        {i18n.language}
      </Button>

      <Popper open={isOpened} anchorEl={anchorEl} placement="bottom-start" transition style={{ zIndex: 1301 }}>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            id="lang-menu"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown} style={{ paddingBottom: '0px', minWidth: '75px' }}>
              <ClickAwayListener onClickAway={() => setOpened(false)}>
                <MenuList role="menu">
                  {menuItems}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default inject('session')(observer(LanguageSwitcher));
