import React, { createRef, useEffect, useState } from 'react';
import GridContainer from 'components/Grid/GridContainer';
import AuthGuard from 'components/AuthGuard/AuthGuard';
import GridItem from 'components/Grid/GridItem';
import { generatePDF } from '../pdf-helper';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Divider, Typography } from '@material-ui/core';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
// Styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { ArrowBack, OpenInNew } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router';
import { Skeleton } from '@material-ui/lab';
import ImageUpload from 'components/CustomUpload/ImageUpload';
import { HexColorPicker } from 'react-colorful';
import classNames from 'classnames';
import { useLazyQuery, useMutation } from '@apollo/client';
import PDF_DATA from 'queries/invoice/pdfData';
import UPDATE_PDF_SETTING from 'mutations/pdfSetting';
import PDF_SETTINGS from 'queries/pdfSettings';
import { useDebounce } from '@react-hook/debounce';
import { inject } from 'mobx-react';
import SweetAlert from 'react-bootstrap-sweetalert';
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { fetchingImage } from 'utils/image';
import UPDATE_PDF_IMAGE from 'mutations/pdfSetting/uploadPdfImage';
import REMOVE_PDF_IMAGE from 'mutations/pdfSetting/removePdfImage';

const useStyles = makeStyles(theme => ({
  ...styles,
  ...sweetAlertStyles,
  rightBasis: {
    height: '85vh',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down("md")]: {
      marginTop: '2rem'
    }
  },
  divider: {
    backgroundColor: '#dedede',
    marginBottom: '20px'
  },
  image: {
    width: '75%',
  },
  imageWrapper: {
    boxShadow: 'unset',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& + div': {
      width: '75%',
      '& button': {
        width: '100%'
      }
    }
  }
}));

const Invoice = ({ session }) => {
  const [pdfSettings, setPdfSettings] = useState(null);
  const [iFrameSrc, setIFrameSrc] = useState(null);
  const [color, setColor] = useDebounce('', 200);
  const [logo, setLogo] = useState(null);
  const [alert, setAlert] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const pdfRef = createRef();
  const [loading, setLoading] = useState(false);
  const [getPdfData, { data, loading: pdfDataLoading }] = useLazyQuery(PDF_DATA, {
    variables: { invoiceId: id }
  });
  const [updatePdfSetting, { loading: updatePdfSettingLoading }] = useMutation(UPDATE_PDF_SETTING);
  const [getPdfSettings, { loading: getPdfSettingsLoading }] = useLazyQuery(PDF_SETTINGS, {
    onCompleted: ({ getPdfSetting }) => {
      const updatedPdfSetting = {
        Id: getPdfSetting.Id,
        logo: getPdfSetting.logo || '',
        textColor: getPdfSetting.textColor,
        clientPos: getPdfSetting.clientPos,
        InvoiceText: getPdfSetting.InvoiceText,
        enableQRBill: getPdfSetting.enableQRBill,
        enableTotalVAT: getPdfSetting.enableTotalVAT
      }
      setPdfSettings(updatedPdfSetting);
      if (getPdfSetting.logo) fetchServerImage(getPdfSetting.logo);
    },
    fetchPolicy: 'no-cache'
  });
  const [uploadFile, { updateFileLoading }] = useMutation(UPDATE_PDF_IMAGE, {
    onCompleted: data => {
      if (data.uploadPdfImage.response) {
        fetchPdfSettings();
      }
    }
  });
  const [removePdfImage, { removePdfImageLoading }] = useMutation(REMOVE_PDF_IMAGE);

  useEffect(() => {
    if (!pdfSettings) return;
    const input = {
      ...pdfSettings,
      textColor: color,
      logo: pdfSettings.logo || ''
    }

    updatePdfSetting({ variables: { id: pdfSettings.Id, input } });
    setPdfSettings({ ...pdfSettings, textColor: color });
  }, [color]);

  useEffect(() => {
    fetchPdfSettings();
  }, []);

  const fetchPdfSettings = () => {
    getPdfSettings({ variables: { clientId: session.user.activeAccount.ClientID } });
  }

  useEffect(() => {
    getPdfData({ variables: { invoiceId: parseInt(id) } });
  }, [id]);

  useEffect(() => {
    if (data && data.pdfData && pdfSettings) {
      const { BillTotalPriceVat, VATNr, Creditor, Debtor, Currency, InvoiceLines, Date } = data.pdfData;
      const pdf = {
        currency: Currency,
        amount: BillTotalPriceVat || 0,
        vatNr: VATNr,
        creditor: {
          name: Creditor.name || "Robert Schneider AG",
          address: Creditor.address || "Rue du Lac 1268",
          zip: Creditor.zip || 2501,
          city: Creditor.city || "Biel",
          account: Creditor.account.replaceAll(/\s/g,'')|| "CH4431999123000889012",
          country: "CH",
        },
        debtor: {
          name: Debtor.name || "Pia-Maria Rutschmann-Schnyder",
          address: Debtor.address || "Grosse Marktgasse 28",
          zip: Debtor.zip || 9400,
          city: Debtor.city || "Rorschach",
          country: "CH",
          company: Debtor.company
        }
      };

      generatePDF(pdf, InvoiceLines, { date: Date, pdfSettings: { ...pdfSettings, logo }, ...data.pdfData }, t)
        .then(res => {
          setIFrameSrc(res);
        })
        .catch(err => {
          errorAlert('Failed to generate the invoice.', t(err));
        });
    }
  }, [data, pdfSettings, logo]);

  const setColorHandler = color => {
    setColor(color);
  }

  const onUploadHandler = file => {
    uploadFile({ variables: { file } });
  }

  const fetchServerImage = url => {
    setLoading(true);

    fetchingImage(url)
      .then((res) => {
        setLogo(res)
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  }

  const onRemoveImageHandler = () => {
    setLogo(null);
    setPdfSettings({ ...pdfSettings, logo: '' });
    removePdfImage();
  }

  const hideAlert = () => {
    goBack();
    setAlert(null);
  }

  const errorAlert = (title, subtitle) => {
    setAlert(
      <SweetAlert
        error
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={hideAlert}
        onCancel={hideAlert}
        confirmBtnCssClass={classes.button + " " + classes.error}
      >{subtitle}</SweetAlert>
    );
  }

  const goBack = () => {
    return history.push('/invoices');
  }

  const openPDF = () => {
    return window.open(iFrameSrc);
  }

  return (
    <AuthGuard permission="all">
      {alert}

      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={5} lg={5}>
          <GridContainer direction="column">
            <GridItem>
              <GridContainer direction="column">
                <GridItem>
                  {pdfDataLoading || updatePdfSettingLoading || getPdfSettingsLoading || updateFileLoading || loading ? <Skeleton height={40} /> : <h3>{t('Quick actions')}</h3>}
                  <Divider className={classes.divider} variant="fullWidth" />
                </GridItem>
                <GridItem>
                  <GridContainer>
                    {pdfDataLoading || updatePdfSettingLoading || getPdfSettingsLoading || updateFileLoading || loading ? <Skeleton height={30} width={30} style={{ margin: '0 15px' }} /> : (
                      <Tooltip id="tooltip-top" title={t('Back to invoices')} placement="top" classes={{ tooltip: classes.tooltip }}>
                        <IconButton aria-label="Back to invoices" className={classes.tableActionButton} onClick={goBack}>
                          <ArrowBack fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    )}

                    {pdfDataLoading || updatePdfSettingLoading || getPdfSettingsLoading || updateFileLoading || loading ? <Skeleton height={30} width={30} /> : (
                      <Tooltip id="tooltip-top" title={t('Open in a new tab')} placement="top" classes={{ tooltip: classes.tooltip }}>
                        <IconButton aria-label="Open in a new tab" className={classes.tableActionButton} onClick={openPDF}>
                          <OpenInNew fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>

            {session.user.permissions.indexOf('UpdateInvoiceSettings') !== -1 &&
              <GridItem>
                <GridContainer direction="column">
                  <GridItem>
                    {pdfDataLoading || updatePdfSettingLoading || getPdfSettingsLoading || updateFileLoading || loading
                      ? <Skeleton height={40} />
                      : <h3>{t('Settings')}</h3>}
                    <Divider className={classes.divider} variant="fullWidth" />
                  </GridItem>
                  <GridItem>
                    <GridContainer>
                      <GridItem sm={12} xs={12} md={12} lg={6}>
                        {pdfDataLoading || updatePdfSettingLoading || getPdfSettingsLoading || updateFileLoading || loading
                          ? <Skeleton height={40} />
                          : (<>
                            <h4 style={{ fontWeight: 'bolder' }}>{t('Choose your own logo')}</h4>
                            <ImageUpload
                              image={logo}
                              onRemoveImage={onRemoveImageHandler}
                              onUploadFile={onUploadHandler}
                              imageWrapperProps={{ className: classes.imageWrapper }}
                              imageProps={{ className: classNames("thumbnail", classes.image) }} />
                          </>)}
                      </GridItem>
                      <GridItem sm={12} xs={12} md={12} lg={6}>
                        {pdfDataLoading || updatePdfSettingLoading || getPdfSettingsLoading || updateFileLoading || loading
                          ? <Skeleton height={40} />
                          : (<>
                            <h4 style={{ fontWeight: 'bolder' }}>{t('Change text color')}</h4>
                            <HexColorPicker color={pdfSettings?.textColor || ''} onChange={setColorHandler} />
                          </>)}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>}
          </GridContainer>
        </GridItem>

        <GridItem className={classes.rightBasis} xs={12} sm={12} md={7} lg={7}>
          {pdfDataLoading || updatePdfSettingLoading || getPdfSettingsLoading || updateFileLoading || loading
            ? <CircularProgress size={75} />
            : <iframe id="pdf-viewer" style={{ width: '100%', height: '100%' }} src={iFrameSrc} ref={pdfRef}></iframe>}
        </GridItem>
      </GridContainer>
    </AuthGuard>
  );
}

export default inject('session')(Invoice);
