import { ApolloClient, InMemoryCache, HttpLink, gql, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from 'apollo-upload-client';
import { clearLSValue } from 'utils/localStorage';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_SERVER });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const errorLink = onError(({ graphQLErrors, networkError, ...rest }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) {
    const { statusCode } = networkError;
    console.error(`[Network error]: ${networkError}`);

    if (statusCode === 401) {
      clearLSValue('token');
      clearLSValue('tokens');
      window.location.href = `/auth/login-page`;
    } else if (statusCode === 423) {
      clearLSValue('token');
      clearLSValue('tokens');
      window.location.href = `/error/423?message=${networkError.result.message}`;
    }
  };
});

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(createUploadLink({ uri: process.env.REACT_APP_GRAPHQL_SERVER }))]),
  cache: new InMemoryCache(),
  typeDefs: gql`
    enum MeasurePeriod {
      day
      week
      month
      year
    }
  `
});

export default client;
