import React, { useCallback, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Row from "./Row";
import { useTranslation } from "react-i18next";

export default function CollapsibleTable(props) {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{t("Location ID")}</TableCell>
            <TableCell align="left">{t("Location Name")}</TableCell>
            <TableCell>{t("Add account")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => (
            <Row key={index} row={row} getQuery={props.getQuery} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
