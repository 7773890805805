import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import dutch from 'dayjs/locale/de-ch';
import french from 'dayjs/locale/fr-ch';
import italian from 'dayjs/locale/it-ch';
import english from 'dayjs/locale/en-gb';
import { getLSValue } from './localStorage';

const locales = {
  'de-ch': dutch,
  'fr-ch': french,
  'it-ch': italian,
  'en-gb': english,
};

const langToLocaleHash = {
  'de': 'de-ch',
  'fr': 'fr-ch',
  'it': 'it-ch',
  'en': 'en-gb',
}

export const MEASURE_POINT_PERIOD_DAY = 'day';
export const MEASURE_POINT_PERIOD_WEEK = 'week';
export const MEASURE_POINT_PERIOD_MONTH = 'month';
export const MEASURE_POINT_PERIOD_YEAR = 'year';
export const measurePointPeriods = [
  MEASURE_POINT_PERIOD_DAY,
  MEASURE_POINT_PERIOD_WEEK,
  MEASURE_POINT_PERIOD_MONTH,
  MEASURE_POINT_PERIOD_YEAR
];

function getDaysInMonthByDate(dt) {
  const month = dt.getMonth();
  const year = dt.getFullYear();
  return new Date(year, month, 0).getDate();
}

export const labelsForPeriod = (period, date) => {
  const periodDate = date ? date : new Date();
  const currentLang = getLSValue('language');
  const locale = langToLocaleHash[currentLang] || 'en-gb';

  dayjs.locale({ ...locales[locale], weekStart: 1 });
  dayjs.extend(localeData);

  const hash = {
    [MEASURE_POINT_PERIOD_DAY]: [...Array(24).keys()].map(i => i + 1),
    [MEASURE_POINT_PERIOD_WEEK]: dayjs.weekdaysShort(true),
    [MEASURE_POINT_PERIOD_YEAR]: dayjs.monthsShort(true),
    [MEASURE_POINT_PERIOD_MONTH]: [...Array(dayjs(periodDate).daysInMonth()).keys()].map(i => i + 1),
  };

  return hash[period];
}

export const getMonthNames = () => {
  const currentLang = getLSValue('language');
  const locale = langToLocaleHash[currentLang] || 'en-gb';

  dayjs.locale({ ...locales[locale] });
  dayjs.extend(localeData);

  return dayjs.months();
}