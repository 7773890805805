import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import { inject, observer } from 'mobx-react';

function WidgetGuard({ session, widget, children }) {
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);

    if (!session.user.permissions.includes(widget)) {
      setLoaded(false);
    }
  }, [history, session.user.token, session.user]);

  return <>{loaded && children}</>;
}

export default inject('session')(observer(WidgetGuard));
