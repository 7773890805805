import { gql } from "@apollo/client";

const PERMISSIONS = gql`
  query permissions {
    permissions {
      Id
      Name
      Area
      Description
      Title
    }
  }
`;

export default PERMISSIONS;
