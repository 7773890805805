import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";

import AuthGuard from "components/AuthGuard/AuthGuard";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import Button from "components/CustomButtons/Button";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import { Assignment, Lock } from "@material-ui/icons";
import { usePermissions } from "components/Accounts/hooks/usePermissions";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Modal from "components/Modal/Modal";
import ReactTable from "components/ReactTable/ReactTable";
import SwitchStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import CardBody from "components/Card/CardBody";
import { FormControlLabel, Switch } from "@material-ui/core";
import usePersonGroupTypes from "hooks/usePersonGroupTypes";
import PAGINATED_PERSON_GROUP_TYPES from "queries/personGroupTypes";

const styles = {
  ...SwitchStyles,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  closeButton: {
    maxWidth: "120px",
  },
  permissionWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: ".75rem 0",
  },
  permissionTitle: {
    display: "flex",
    flexDirection: "column",
  },
  slideTitle: {
    fontWeight: "bolder",
    fontSize: "18px",
  },
};

const useStyles = makeStyles(styles);

const Administration = ({ session }) => {
  const defaultRowsPerPage = 10;
  const [modalVisible, setModalVisible] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    size: defaultRowsPerPage,
  });
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    loading: permissionsLoading,
    modalValues,
    personGroupTypId,
    setPersonGroupTypId,
    permissions,
    setModalValues,
    updatePermissions,
  } = usePermissions(session);
  const {
    data,
    loading: personGroupTypeLoading,
    fetchPersonGroupTypes,
  } = usePersonGroupTypes({ defaultSize: defaultRowsPerPage });


  const handlePermissionsModal = (personGroupType) => {
    setModalVisible(true);
    setModalValues(personGroupType.Permissions.map((p) => p.Id));
    setPersonGroupTypId(personGroupType.Id);
  };

  const onCheckPermission = (permission) => () => {
    const permissionExists = modalValues.indexOf(permission.Id) !== -1;
    if (permissionExists) {
      setModalValues(modalValues.filter((p) => p !== permission.Id));
    } else {
      setModalValues([...modalValues, permission.Id]);
    }
  };
  const savePermissions = () => {
    const variables = {
      variables: { personGroupTypId, permissions: modalValues },
    };
      updatePermissions(variables);
      fetchPersonGroupTypes(pagination);
  }
  const renderModalContent = () => {
    if (!permissions || !modalVisible) return;

    return permissions.permissions.map((p) => {
      const permissionActive = modalValues.indexOf(p.Id) !== -1;

      return (
        <div className={classes.permissionWrapper} key={p.Id}>
          <div className={classes.permissionTitle}>
            <span className={classes.slideTitle}>{p.Title}</span>
            <small>{p.Description}</small>
          </div>
          <FormControlLabel
            control={
              <Switch
                onChange={onCheckPermission(p)}
                checked={permissionActive}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  thumb: classes.switchIcon,
                  track: classes.switchBar,
                }}
              />
            }
            classes={{ label: classes.label }}
          />
        </div>
      );
    });
  };

  const onCloseModal = () => {
    setModalVisible(false);
    setModalValues([]);
  };

  const columns = [
    {
      Header: t("Title"),
      disableFilters: true,
      accessor: "Title",
    },
    {
      Header: t("Admin"),
      accessor: "Admin",
      disableFilters: true,
      type: "boolean",
    },
    {
      Header: t("Actions"),
    },
  ];

  const tableData = data
    ? data.paginatedPersonGroupTypes
    : { data: [], totalCount: 0, page: 0 };

  return (
    <AuthGuard permission="all">
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("Person group types")}
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={columns}
                data={tableData}
                loading={personGroupTypeLoading}
                fetchData={fetchPersonGroupTypes}
                onPagination={(pagination) => setPagination(pagination)}
                getQuery={{
                  query: PAGINATED_PERSON_GROUP_TYPES,
                  variables: {},
                }}
                defaultRowsPerPage={defaultRowsPerPage}
                extraActionButtons={[
                  {
                    title: t("Permissions"),
                    icon: Lock,
                    callback: (personGroupType) =>
                      handlePermissionsModal(personGroupType),
                  },
                ]}
              />

              <Modal
                onModalClose={onCloseModal}
                content={renderModalContent()}
                titleText={t("Person group types")}
                isOpened={modalVisible}
              >
                <Button
                  onClick={() => {onCloseModal(); savePermissions()}}
                  className={classes.closeButton}
                  disabled={permissionsLoading}
                  color="info"
                  block
                >
                  {permissionsLoading ? "Loading..." : t("Save")}
                </Button>
                <Button
                  onClick={onCloseModal}
                  className={classes.closeButton}
                  disabled={permissionsLoading}
                  color="danger"
                  block
                >
                  {permissionsLoading ? "Loading..." : t("Close")}
                </Button>
              </Modal>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AuthGuard>
  );
};

export default inject("session")(observer(Administration));
