import React, { useState, useEffect } from 'react'
import { MEASURE_POINTS } from 'queries'
import { useLazyQuery } from '@apollo/client'
import ElectricityTab from 'views/Components/ElectricityTab'
import CustomTabs from 'components/CustomTabs/CustomTabs'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

function activeTabProps(energyType) {
  switch (energyType) {
    case 1:
      return { color: 'warning', title: 'Electricity' }
    case 2:
      return { color: 'rose', title: 'Warm Water' }
    case 3:
      return { color: 'info', title: 'Cold Water' }
    case 4:
      return { color: 'primary', title: 'Heating' }
    default:
      return { color: 'warning' }
  }
}

const CustomTabsWrapper = ({ session, locationPos }) => {
  const [fetchMeasurePoints, { data, loading }] = useLazyQuery(MEASURE_POINTS, { fetchPolicy: 'no-cache' })
  const [activeTitle, setActiveTitle] = useState('')
  const [directionActiveTab, setDirectionActiveTab] = useState(0)
  const { t } = useTranslation()

  useEffect(() => {
    setActiveTitle(activeTabProps(session.activeEnergyTab).title)
    setDirectionActiveTab(0)
    fetchData()
  }, [session.activeEnergyTab])

  const activeSensorIdHandler = id => {
    session.setActiveSensorId(id)
  }

  const mPoints = data?.measurePoints || []
  const pointsByType = mPoints.reduce((acc, item) => {
    acc[item.DirectionPos] = acc[item.DirectionPos] || []
    return { ...acc, [item.DirectionPos]: [...acc[item.DirectionPos], item] }
  }, {})

  const tabs = [
    { tabName: 'In', position: 1, direction: 1 },
    { tabName: 'Exec', position: 2, direction: 2 },
    { tabName: 'Out', position: 3, direction: 3 },
  ].reduce((acc, { tabName, position, direction }) => {
    if (pointsByType[direction]?.length) {
      acc.push({
        tabName,
        position,
        tabContent: (
          <ElectricityTab
            data={pointsByType[direction]}
            activeTabColor={activeTabProps(session.activeEnergyTab).color}
            activeTitle={t(activeTabProps().title)}
            activeSensorId={session.activeSensorId}
            setActiveSensorId={activeSensorIdHandler}
            energyTypePos={session.activeEnergyTab}
          />
        ),
      })
    }
    return acc
  }, [])

  const fetchData = () => {
    fetchMeasurePoints({
      variables: {
        LocationPos: locationPos,
        EnergyTypePos: session.activeEnergyTab,
      },
    })
  }

  return (
    <CustomTabs
      headerColor={activeTabProps().color}
      onChangeValue={setDirectionActiveTab}
      value={directionActiveTab}
      loading={loading}
      title={t(activeTitle)}
      tabs={tabs}
    />
  )
}

export default inject('session')(observer(CustomTabsWrapper))
