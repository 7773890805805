import { gql } from '@apollo/client';

const MEASURE_POINTS = gql`
  query($LocationPos: Int, $DirectionPos: Int, $EnergyTypePos: Int) {
    measurePoints(LocationPos: $LocationPos, DirectionPos: $DirectionPos, EnergyTypePos: $EnergyTypePos) {
      Id
      Name
      StatusPos
      DirectionPos
    }
  }
`;

export default MEASURE_POINTS;
