import React, { Fragment, useCallback, useState } from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useTranslation } from "react-i18next";
import {AddCircleRounded, Check, Close, Delete, Done, Edit, RemoveCircle } from "@material-ui/icons";
import CustomInput from "components/CustomInput/CustomInput";
import { makeStyles } from "@material-ui/styles";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { Button, Checkbox, Tooltip } from "@material-ui/core";
import CREATE_NEW_USER from "mutations/user/newUser";
import { useMutation } from "@apollo/client";
import { DELETE_USER, UPDATE_USER, REMOVE_USER } from "mutations/user";
import Modal from "components/Modal/Modal";
import { DELETE_ACCOUNT, UPDATE_ACCOUNT } from "mutations/account";
import Datetime from "react-datetime";
import dayjs from "dayjs";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

export default function NestedRow(props) {
  const { account, getQuery, accountColumns, userColumns } = props;
  const [open, setOpen] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(null);
  const { t } = useTranslation();
  const useStyles = makeStyles({...styles, ...formStyles});
  const classes = useStyles();
  const [mode, setMode] = useState("");
  const [editingObject, setEditingObject] = useState({});
  const [onAddUserData, { loading: addingUserLoader }] = useMutation(CREATE_NEW_USER, {
    onCompleted: data => showSuccessAlert(data),
    onError: (err) => {
      if (err.graphQLErrors) {
        showAlert(t("Error"), t(err.graphQLErrors[0].message), "", "error")
      }
    }
  });

  const [onDeleteUserData, { loading: deletingUserLoader }] = useMutation(DELETE_USER);
  const [onRemoveUser, { loading: removeUserLoader }] = useMutation(REMOVE_USER);
  const [onUpdateUserData, { loading: updatingUserLoader }] = useMutation(UPDATE_USER);

  const [onDeleteAccountData, { loading: deletingAccountLoader }] = useMutation(DELETE_ACCOUNT,);
  const [onUpdateAccountData, { loading: updatingAccountLoader }] = useMutation(UPDATE_ACCOUNT);


  const addRowClick = () => {
    setMode("addUser");
    setOpen(true);
  };
  const addUserRow = () => {
    onAddUserData({
      variables: {
        accountId : account[0].Id, 
        input: {
          FirstName: editingObject.FirstName,
          LastName: editingObject.LastName,
          Email: editingObject.Email,
          Password: editingObject.Password
        }
      },
      refetchQueries:
        [{
          query: getQuery.query
        }]
    });
    setMode('');
  };

  const onUpdateUser = () => {
    onUpdateUserData({
      variables: {
        input: {
          FirstName: editingObject.UserFirstName,
          LastName: editingObject.UserLastName,
          Email: editingObject.UserEmail,
          Password: editingObject.Password
        },
        id: editingObject.UserId,
      },
      refetchQueries:
        [{
          query: getQuery.query
        }]
    });
    setMode('');
  };

  const onDeleteUser = (data) => {
    onDeleteUserData({
      variables: {
        id: data.UserId,
      },
      refetchQueries:
        [{
          query: getQuery.query
        }]
    });
    setModalVisible(null);
  }
  const onUserRemoval = (data) => {
    onRemoveUser({
      variables: {
        accountId: data.Id,
        userId: data.UserId,
      },
      refetchQueries:
        [{
          query: getQuery.query
        }]
    });
    setModalVisible(null);
  }

  const onUpdateAccount = () => {
    onUpdateAccountData({
      variables: {
        input: {
          Company: editingObject.Company,
          FirstName: editingObject.FirstName,
          LastName: editingObject.LastName,
          ZIP: editingObject.ZIP,
          City: editingObject.City,
          Country: editingObject.Country,
          ValidFrom: editingObject.ValidFrom,
          ValidTo: editingObject.ValidTo,
          email: editingObject.email,
          sentInvoiceByEmail: editingObject.sentInvoiceByEmail
        },
        id: editingObject.Id,
      },
      refetchQueries:
        [{
          query: getQuery.query
        }]
    });
    setMode('');
  };

  const onDeleteAccount = (data) => {
    onDeleteAccountData({
      variables: {
        id: data.Id,
      },
      refetchQueries:
        [{
          query: getQuery.query
        }]
    });
    setModalVisible(null);
  }

  return (
    <React.Fragment>
      {modalVisible && (
        <Modal 
          isOpened={!!modalVisible}
          titleText={t("Confirmation")}
          bodyText={t(modalVisible.bodyText)}
          onModalClose={() => setModalVisible(null)}
        >
          <Button color="primary" onClick={() => modalVisible.handleClick(editingObject)}>{t('Proceed')}</Button>
          <Button onClick={() => setModalVisible(null)} color="primary">
            {t('Close')}
          </Button>
        </Modal>
      )}           
        { mode !== "editAccount" || editingObject.Id != account[0].Id ? 
        (
          <TableRow>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{account[0].Company}</TableCell>
          <TableCell>{account[0].FirstName}</TableCell>
          <TableCell>{account[0].LastName}</TableCell>
          <TableCell>{account[0].email}</TableCell>
          <TableCell>{account[0].ZIP}</TableCell>
          <TableCell>{account[0].City}</TableCell>
          <TableCell>{account[0].Country}</TableCell>
          <TableCell>{dayjs(account[0].ValidFrom).format("DD.MM.YYYY")}</TableCell>
          <TableCell>{dayjs(account[0].ValidTo).format("DD.MM.YYYY")}</TableCell>
          <TableCell>{account[0].sentInvoiceByEmail && <Check color="primary"/>}</TableCell>
          <TableCell>
          {
            <Tooltip id="tooltip-top"  title={t("Edit")} placement="top" classes={{ tooltip: classes.tooltip }} >
              <IconButton size="small" aria-label="Edit" color="secondary"
                onClick={() => {
                  setMode("editAccount");
                  setEditingObject(account[0])
                  }} >
                <Edit />
              </IconButton>
            </Tooltip>
          }
          {
            <Tooltip id="tooltip-top" title={t("Delete")} placement="top" classes={{ tooltip: classes.tooltip }} >
              <IconButton size="small" aria-label="Delete" className={classes.dangerButton}
                onClick={() => {
                  setEditingObject(account[0]);
                  setModalVisible({bodyText: "Are you sure you want to delete account?", handleClick: onDeleteAccount });
                }}>
                <Delete />
              </IconButton>
            </Tooltip>
          }
          </TableCell>
          <TableCell>
            <IconButton aria-label="add" color="primary" onClick={addRowClick}>
              <AddCircleRounded />
            </IconButton>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow>
          <TableCell />
        {
        accountColumns.map((column, index) => {
          if (column.editable === undefined)
            column.editable = true;
          return (
            <Fragment key={index}>
              {column.editable && (
                <TableCell>
                {column.date ? (
                  <Datetime
                    closeOnSelect
                    timeFormat={false}
                    defaultValue={dayjs(account[0][column.field]).format("DD.MM.YYYY")}
                    dateFormat={"DD.MM.YYYY"}
                    disableOnClickOutside={false}
                    onChange={(value) => {
                      setEditingObject({
                        ...editingObject,
                        [column.field]: dayjs(value).format("YYYY-MM-DD"),
                      });
                    }}
                    inputProps={{
                      placeholder: column.Header,
                      readOnly: false, 
                    }}
                  />
                ) : (
                  column.type === "checkbox" ?
                  <Checkbox
                    checked={editingObject[column.field]}
                    onClick={ ({ target: { checked } }) => {
                      setEditingObject({
                        ...editingObject,
                        [column.field]: checked,
                      });
                    }}
                    checkedIcon={<Check className={classes.checkedIcon} color="primary"/>}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.root
                    }}
                  /> :
                  <CustomInput
                    inputProps={{
                      placeholder: column.Header,
                      defaultValue: account[0][column.field] || "",
                      onChange: ({ target: { value } }) => {
                        setEditingObject({
                          ...editingObject,
                          [column.field]: value,
                        });
                      },
                    }}
                  />
                )}
              </TableCell>
              )}
              {column.Header === t("Actions") && (
                <TableCell>
                  <div className={classes.centered}>
                    <Tooltip id="tooltip-top" title={t("Save")} placement="top" classes={{ tooltip: classes.tooltip }}>
                      <IconButton aria-label="Save" color="secondary" onClick={onUpdateAccount}>
                        <Done />
                      </IconButton>
                    </Tooltip>

                    <Tooltip id="tooltip-top" title={t("Cancel")} placement="top" classes={{ tooltip: classes.tooltip }}>
                      <IconButton aria-label="Cancel" onClick={() => setMode('')}>
                        <Close />
                      </IconButton>
                    </Tooltip>
                  </div>
                </TableCell>
              )}
            </Fragment>
          );
        })}
      </TableRow>
      )
    }
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h5" gutterBottom component="div">
                {t("Users")}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {userColumns.map((column, index) => {
                      return <TableCell key={index} >{column.Header}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {account.map((user, index) =>
                    mode !== "editUser" || editingObject.UserId != user.UserId ? (
                      <TableRow key={index}>
                        <TableCell>{user.UserFirstName}</TableCell>
                        <TableCell>{user.UserLastName}</TableCell>
                        <TableCell>{user.UserEmail}</TableCell>
                        <TableCell>{user.Password}</TableCell>
                        <TableCell>
                          <Tooltip id="tooltip-top" title={t("Edit")} placement="top" classes={{ tooltip: classes.tooltip }}>
                            <IconButton size="small" aria-label="Edit" color="secondary"
                              onClick={() => {
                                setMode("editUser");
                                setEditingObject(user);
                              }}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          <Tooltip id="tooltip-top" title={t("Remove")} placement="top" classes={{ tooltip: classes.tooltip }}>
                            <IconButton size="small" aria-label="Delete" className={classes.dangerButton}
                              onClick={() => {
                                setModalVisible({
                                  bodyText: "Are you sure you want to remove the user?",
                                  handleClick: onUserRemoval
                                });
                                setEditingObject(user);
                              }}>
                              <RemoveCircle />
                            </IconButton>
                          </Tooltip>
                          <Tooltip id="tooltip-top" title={t("Delete")} placement="top" classes={{ tooltip: classes.tooltip }}>
                            <IconButton size="small" aria-label="Delete" className={classes.dangerButton}
                              onClick={() => {
                                setEditingObject(user);
                                setModalVisible({
                                  bodyText: "Are you sure you want to delete user? This action will delete the user from all accounts.",
                                  handleClick: onDeleteUser
                                  });
                              }}>
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow key={index}>
                        {userColumns.map((column, i) => {
                          if (column.editable === undefined)
                            column.editable = true;
                          return (
                            <Fragment key={i}>
                              {column.editable && (
                                <TableCell>
                                  <CustomInput
                                    inputProps={{
                                      placeholder: column.Header,
                                      defaultValue: user[column.field] || "",
                                      onChange: ({ target: { value } }) => {
                                        setEditingObject({
                                          ...editingObject,
                                          [column.field]: value,
                                        });
                                      },
                                    }}
                                  />
                                </TableCell>
                              )}
                              {column.Header === t("Actions") && (
                                <TableCell>
                                  <div className={classes.centered}>
                                    <Tooltip id="tooltip-top" title={t("Save")} placement="top" classes={{ tooltip: classes.tooltip }}>
                                      <IconButton aria-label="Save" color="secondary" onClick={onUpdateUser}>
                                        <Done />
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip id="tooltip-top" title={t("Cancel")} placement="top" classes={{ tooltip: classes.tooltip }}>
                                      <IconButton aria-label="Cancel" onClick={() => setMode('')}>
                                        <Close />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </TableCell>
                              )}
                            </Fragment>
                          );
                        })}
                      </TableRow>
                    )
                  )}
                  {mode === "addUser" && (
                    <TableRow>
                      {userColumns.map((column, index) => {
                        if (column.editable === undefined) {
                          column.editable = true;
                        }
                        if (column.editable) {
                          return (
                            <TableCell key={index}>
                              <CustomInput
                                inputProps={{
                                  placeholder: column.Header,
                                  onChange: ({ target: { value } }) => {
                                    setEditingObject({
                                      ...editingObject,
                                      [column.accessor]:
                                        column.type === "numeric"
                                          ? parseInt(value)
                                          : value,
                                    });
                                  },
                                }}
                              />
                            </TableCell>
                          );
                        } else if (column.Header === t("Actions")) {
                          return (
                            <TableCell key={index} className="rt-td">
                              <div className={classes.centered}>
                                <Tooltip id="tooltip-top" title={t("Save")} placement="top" classes={{ tooltip: classes.tooltip }}>
                                  <IconButton aria-label="Save" color="secondary" onClick={addUserRow}>
                                    <Done />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip id="tooltip-top" title={t("Cancel")} placement="top" classes={{ tooltip: classes.tooltip }}>
                                  <IconButton aria-label="Cancel" onClick={() => setMode('')}>
                                    <Close />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </TableCell>
                          );
                        } else {
                          return <TableCell key={index}></TableCell>;
                        }
                      })}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}