import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import { useHistory, useParams } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles.js";

const useStyles = makeStyles(styles);

export default function ErrorPage() {
  const { errorCode } = useParams();
  const classes = useStyles();
  const history = useHistory();

  const renderSubtitle = () => {
    switch (errorCode) {
      case '404': return 'Page not found';
      case '423': return 'Invalid subscription';
      default: 'Page not found';
    }
  }

  const renderDescription = () => {
    const queryParams = Object.fromEntries(new URLSearchParams(window.location.search).entries());
    return queryParams.message ? queryParams.message : 'Ooooups! Looks like you got lost.';
  }

  return (
    <div className={classes.contentCenter}>
      <GridContainer>
        <GridItem md={12}>
          <h1 className={classes.title}>{errorCode}</h1>
          <h2 className={classes.subTitle}>{renderSubtitle()}</h2>
          <h4 className={classes.description}>{renderDescription()}</h4>
          <Button
            color="info"
            className={classes.updateProfileButton}
            onClick={() => history.push('/')}>
            Go back!
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}
