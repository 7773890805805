import { gql } from '@apollo/client';

const REMOVE_PROFILE_IMAGE = gql`
  mutation removeProfileImage {
    removeProfileImage {
      response
      message
    }
  }
`;

export default REMOVE_PROFILE_IMAGE;
