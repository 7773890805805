import React, { useEffect, useState } from 'react'

// Components
import Card from 'components/Card/Card'
import { useLazyQuery, useQuery } from '@apollo/client'
import ChartistGraph from 'react-chartist'
import Badge from 'components/Badge/Badge'
import { Skeleton } from '@material-ui/lab'
import GridItem from 'components/Grid/GridItem'
import CardBody from 'components/Card/CardBody'
import Muted from 'components/Typography/Muted'
import CardHeader from 'components/Card/CardHeader'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer'
import CustomDropdown from 'components/CustomDropdown/CustomDropdown'
import { IconButton, makeStyles, Tooltip, CircularProgress } from '@material-ui/core'
import Chartist from 'chartist';
// Icons
import { ChevronLeft, ChevronRight, ArrowUpward, ArrowDownward, Refresh, Autorenew } from '@material-ui/icons'

// Styles
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js'

// Charts
import { dailySalesChart2, tabContentAnimation } from 'variables/charts.js'
import { MEASURE_VALUES, MEASURE_POINT_LAST_HEARTBEAT } from 'queries'

// Dayjs
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT, GRAPHQL_DATE_FORMAT } from 'utils/date'
import { labelsForPeriod } from 'utils/graph'
import { exportCSVData } from 'utils/exportDataToExcel'
import MEASURE_VALUES_TO_EXCEL from 'queries/measureValuesToExcel'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'
import { PERMISSIONS } from 'utils/session'
dayjs.Ls.en.weekStart = 1

const useStyles = makeStyles((theme) => ({
  ...styles,
  withoutPadding: {
    padding: '0 !important',
  },
  customCardSubtitle: {
    ...styles.cardSubtitle,
    fontSize: '18px',
    color: '#777',
    textTransform: 'none',
  },
  chartWrapper: {
    padding: '15px',
    borderRadius: '3px !important',
    margin: '0 15px',
    marginTop: '-20px',
    background: 'linear-gradient(200deg, #fcfcfc, #9f9f9f)',
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(172, 172, 172, 0.4)',
  },
  chartGrid: {
    stroke: 'rgba(69, 69, 69, 0.2) !important',
  },
  chartLabels: {
    color: 'black !important',
  },
  customCardTitle: {
    ...styles.cardTitle,
    fontSize: '17px',
  },
  text: {
    color: 'inherit',
  },
  bMonth: {
    strokeWidth: '3% !important',
  },
  bDay: {
    strokeWidth: '4% !important',
  },
  bWeek: {
    strokeWidth: '10% !important',
  },
  controlsWrapper: {
    padding: '10px 0',
    marginBottom: '8px',
  },
  bYear: {
    strokeWidth: '8% !important',
  },
}))

function TabContent({ name, id, energyType: { energyTypePos = 1, energyType }, activeTabColor, session }) {
  const date = dayjs()
  const { t } = useTranslation()
  const userPermissions = session.user.permissions;
  const [period, setPeriod] = useState(session.activePeriod)
  const [startDate, setStartDate] = useState(date.startOf(period).format(GRAPHQL_DATE_FORMAT))
  const [measurePointAlive, setMeasurePointAlive] = useState(false)
  const [exportChartData, { loading: exportChartDataLoading }] = useLazyQuery(MEASURE_VALUES_TO_EXCEL, {
    onCompleted: (data) => exportData(data.measureValuesToExcel.data),
    fetchPolicy: 'no-cache',
  })
  const measureValuesQueryResult = useQuery(MEASURE_VALUES, {
    variables: { MeasurePointPos: id, Period: period, StartDate: startDate, EnergyTypePos: energyTypePos },
  })
  const measurePointLastHeartbeatQueryResult = useQuery(MEASURE_POINT_LAST_HEARTBEAT, {
    variables: { MeasurePointPos: id },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => onAliveQueryCompleted(data),
  })
  const [chartData, setChartData] = useState({ labels: [], series: [] })
  const [chartOptions, setChartOptions] = useState({})
  const classes = useStyles()

  useEffect(() => {
    const data = measureValuesQueryResult?.data?.measureValues

    if (data) {
      return setTransformedChartData(data, period)
    }
  }, [measureValuesQueryResult, period])

  useEffect(() => {
    measurePointLastHeartbeatQueryResult.startPolling(5000)
    return () => {
      measurePointLastHeartbeatQueryResult.stopPolling()
    }
  }, [measurePointLastHeartbeatQueryResult.startPolling, measurePointLastHeartbeatQueryResult.stopPolling])

  const onAliveQueryCompleted = (data) => {
    let measurePointAliveStatus = measurePointAlive
    const statusData = data?.measurePointLastHeartbeat.Status

    if (statusData) {
      measurePointAliveStatus = statusData === 'active'
    } else {
      measurePointAliveStatus = false
    }

    if (measurePointAlive !== measurePointAliveStatus) {
      setMeasurePointAlive(measurePointAliveStatus)
    }
  }

  const setTransformedChartData = (data, period) => {
    const labels = labelsForPeriod(period, startDate)
    let company = labels.map((v) => 0)
    let solar = labels.map((v) => 0)
    let unit = 'kWh'
    const chartSeries = [{ name: data.Company.name || ' ', data: company, className: "ct-series-a", legend: "ct-series-0"} ]

    if (data.Company.data.length) {
      unit = data.Company.data[0].unit
      data.Company.data.forEach(({ MeasureXaxis, used }) => {
        company[MeasureXaxis] = used
      })
    }

    if (data.Solar.data.length) {
      data.Solar.data.forEach(({ MeasureXaxis, used }) => {
        solar[MeasureXaxis] = used
      })

      chartSeries.push({ name: data.Solar.name || ' ', data: solar, className: "ct-series-b", legend: "ct-series-1" })
    }

    setChartData({
      labels,
      series: chartSeries,
    })
    getChartSettings([...company, ...solar], unit)
  }

  const returnTotal = (data) => {
    return (
      data
        .reduce((acc, v) => acc + (v.used ? parseFloat(v.used) : 0), 0)
        .toFixed(3) + ` ${data[0].unit}`
    );
  };

  const calculateConsummatedElectricity = () => {
    const defaultValue = `${energyTypePos === 1 ? "0 kWh" : "0 m3"}`;

    if (measureValuesQueryResult.data?.measureValues) {
      const { Solar, Company } = measureValuesQueryResult.data.measureValues;
      return {
        Solar: {
          name: Solar.name,
          consumption: Solar.data.length ? returnTotal(Solar.data) : defaultValue
        },
        Company: {
          name: Company.name,
          consumption: Company.data.length ? returnTotal(Company.data) : defaultValue
        },
        Total: {
          name: t('Total'),
          consumption: [...Solar.data, ...Company.data].length ? returnTotal([...Solar.data, ...Company.data]) : defaultValue
        },
      };
    }
    return {
      Total: {
        name: t("Total"),
        consumption: defaultValue
      }
    };
  };

  const calculatePricing = () => {
    if (measureValuesQueryResult.data) {
      const measureValues = measureValuesQueryResult.data.measureValues
      const data = [...measureValues.Solar.data, ...measureValues.Company.data]
      return data.reduce((acc, v) => acc + (v.sale ? parseFloat(v.sale) : 0), 0).toFixed(2)
    }

    return Number(0).toFixed(3)
  }

  const getChartSettings = (chartSeries, unit) => {
    let options = { ...dailySalesChart2(unit).options }

    if (chartSeries) {
      const highestChartValue = Math.max(...chartSeries)
      options.high = (highestChartValue + highestChartValue / 2).toFixed(2)
    }

    setChartOptions(options)
  }

  const chartDateRange = (startDate) => {
    if (period === 'day') {
      return `( ${dayjs(startDate).startOf(period).format(DATE_FORMAT)} )`
    } else {
      return `( ${dayjs(startDate).startOf(period).format(DATE_FORMAT)} - ${dayjs(startDate).add(1, period).subtract(1, "day").format(DATE_FORMAT)} )`
    }
  }

  const setTimePeriod = (period) => {
    const newStartDate = date.startOf(period).format(GRAPHQL_DATE_FORMAT)
    setPeriod(period)
    setStartDate(newStartDate)
    session.setActivePeriod(period)
  }

  const previousDateRange = () => {
    const updatedStartDate = dayjs(startDate).subtract(1, period).format(GRAPHQL_DATE_FORMAT)
    setStartDate(updatedStartDate)
  }

  const nextDateRange = () => {
    const updatedStartDate = dayjs(startDate).add(1, period).format(GRAPHQL_DATE_FORMAT)
    setStartDate(updatedStartDate)
  }

  const resetDateRange = () => {
    const updatedStartDate = date.startOf(period).format(GRAPHQL_DATE_FORMAT)
    setStartDate(updatedStartDate)
  }

  const refreshData = () => {
    measureValuesQueryResult.refetch()
  }

  const exportDataHandler = () => {
    exportChartData({ variables: { MeasurePointPos: id, Period: period, StartDate: startDate } })
  }

  const exportData = (data) => {
    const { consumption: consummated } = calculateConsummatedElectricity().Total;
    const totalPricing = calculatePricing()
    exportCSVData(data, `${name} ${period === 'day' ? 'dai' : period}ly report. ${chartDateRange(startDate)}`, consummated, totalPricing)
  }

  const difference = Number(measureValuesQueryResult?.data?.measureValues.Comparison.difference).toFixed(1)
  const comparisonType = measureValuesQueryResult?.data?.measureValues.Comparison.type

  const chartBarClassNames = classNames({
    'ct-bar': true,
    [classes.bMonth]: period === 'month',
    [classes.bDay]: period === 'day',
    [classes.bWeek]: period === 'week',
    [classes.bYear]: period === 'year',
  })

  const { Solar, Company, Total } = calculateConsummatedElectricity();

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={4}>
          <GridContainer className={classes.controlsWrapper}>
            <GridItem xs={12} sm={6} md={6} lg={12} style={{ marginBottom: '10px' }}>
              <GridContainer>
                <GridItem className={classes.withoutPadding}>
                  <Tooltip id="tooltip-top" title={t('Previous')} placement="top" classes={{ tooltip: classes.tooltip }}>
                    <IconButton aria-label="Previous" className={classes.tableActionButton} onClick={previousDateRange}>
                      <ChevronLeft fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </GridItem>
                <GridItem className={classes.withoutPadding} style={{ margin: '0 10px' }}>
                  <CustomDropdown
                    caretActive
                    buttonText={t(period)}
                    dropdownHeader={t('Show data for')}
                    selected={period}
                    onClick={(value) => setTimePeriod(value)}
                    buttonProps={{ round: true, color: activeTabColor }}
                    dropdownList={[{ divider: true }, 'day', 'week', 'month', 'year']}
                  />
                </GridItem>
                <GridItem className={classes.withoutPadding}>
                  <Tooltip id="tooltip-top" title={t('Next')} placement="top" classes={{ tooltip: classes.tooltip }}>
                    <IconButton aria-label="Next" className={classes.tableActionButton} onClick={nextDateRange}>
                      <ChevronRight fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={12} sm={6} md={6} lg={12}>
              <GridContainer>
                <GridItem className={classes.withoutPadding}>
                  <Tooltip id="tooltip-top" title={t('Reset time frame')} placement="top" classes={{ tooltip: classes.tooltip }}>
                    <IconButton aria-label="Reset" className={classes.tableActionButton} onClick={resetDateRange}>
                      <Refresh fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </GridItem>
                <GridItem className={classes.withoutPadding} style={{ margin: '0 10px' }}>
                  {
                    !!session.enableExcelExport &&
                      <div>
                        <Button round color={activeTabColor} onClick={exportDataHandler}>
                          {exportChartDataLoading && <CircularProgress size={18} style={{ marginRight: '15px' }} />}
                          {t('Export excel')}
                        </Button>
                      </div>
                  }
                </GridItem>
                <GridItem className={classes.withoutPadding}>
                  <Tooltip id="tooltip-top" title={t('Refresh data')} placement="top" classes={{ tooltip: classes.tooltip }}>
                    <IconButton aria-label="Refresh data" className={classes.tableActionButton} onClick={refreshData}>
                      <Autorenew fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>

          <Card style={{ marginBottom: '1rem', marginTop: '0' }}>
            <CardBody>
              <Badge color={measurePointAlive ? 'success' : 'danger'} style={{ fontSize: '11px' }}>
                {measurePointAlive ? 'Active' : 'Inactive'}
              </Badge>
              <Muted>
                <h6 className={classes.customCardSubtitle}>{t(energyType) + ' ' + t('Consumption')}</h6>
              </Muted>
              {Solar?.name && (
                <h3 className={classes.customCardTitle}>{`${Solar.name}: ${Solar?.consumption}`}</h3>
              )}
              {Company?.name && (
                <h3 className={classes.customCardTitle}>{`${Company.name}: ${Company?.consumption}`}</h3>
              )}
            <hr/>
            <h3 className={classes.customCardTitle}>{`${Total.name}: ${Total.consumption}`}</h3>
            </CardBody>
          </Card>

          <Card style={{ marginTop: '.6rem' }}>
            <CardBody>
              <Muted>
                <h6 className={classes.customCardSubtitle}>{t('Price w/o VAT')}</h6>
              </Muted>
              <h3 className={classes.customCardTitle}>
                {calculatePricing()} <span>CHF</span>{' '}
              </h3>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={8}>
          <Card chart className={classes.cardHover}>
            {measureValuesQueryResult.loading ? (
              <div className={classes.chartSkeleton}>
                <Skeleton animation="pulse" height={240} />
              </div>
            ) : (
              <CardHeader className={classes.chartWrapper}>
                <ChartistGraph
                  key={chartData.series.length}
                  className={classNames('ct-chart-white-colors', classes.chart)}
                  data={chartData}
                  type="Bar"
                  options={{
                    ...chartOptions,
                    classNames: {
                      bar: chartBarClassNames,
                      grid: classNames('ct-grid', classes.chartGrid),
                      label: classNames('ct-label', classes.chartLabels),
                    },
                  }}
                  listener={tabContentAnimation}
                />
              </CardHeader>
            )}
            <CardBody>
              <h4 className={classes.customCardTitle}>
                {measureValuesQueryResult.loading ? (
                  <Skeleton animation="pulse" height={20} width={250} />
                ) : (
                  <>
                    {t('Data for')} <b>{chartDateRange(startDate)}</b>
                  </>
                )}
              </h4>
              <div className={classes.cardCategory}>
                {measureValuesQueryResult.loading ? (
                  <Skeleton animation="pulse" height={20} width={350} />
                ) : (
                  <>
                    <span
                      className={classNames({
                        [classes.successText]: comparisonType === 'increased',
                        [classes.dangerText]: comparisonType === 'decreased',
                        [classes.text]: comparisonType === 'same',
                      })}
                    >
                      {comparisonType === 'increased' && <ArrowUpward className={classes.upArrowCardCategory} />}
                      {comparisonType === 'decreased' && <ArrowDownward className={classes.upArrowCardCategory} />}
                      {`${!isNaN(difference) ? difference : 0}%`}
                    </span>{' '}
                    {difference !== undefined ? `${t('in comparison with previous')}${t(period)}.` : t('No data for previous date range.')}
                  </>
                )}
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  )
}

export default inject('session')(observer(TabContent))
