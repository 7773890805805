import { gql } from '@apollo/client';

const LOGIN = gql`
  query login($Email: String!, $Password: String!){
    login(Email: $Email, Password: $Password) {
      tokens {
        accountPos
        token
        error
      }
    }
  }
`;

export default LOGIN;