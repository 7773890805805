import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Card from 'components/Card/Card';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import ReactTable from "components/ReactTable/ReactTable.js";
import { makeStyles } from '@material-ui/styles';
import { RemoveRedEye, Folder, FilterList, CloudDownloadTwoTone } from '@material-ui/icons';
import { IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import AuthGuard from 'components/AuthGuard/AuthGuard';
import CustomInput from "components/CustomInput/CustomInput.js";
import { PAGINATED_INVOICES } from 'queries';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { useHistory } from 'react-router';
import { useDebounce } from '@react-hook/debounce';
import dayjs from 'dayjs';
import INVOICES_BY_IDS from 'queries/invoice/invoicesByIds';
import { DateRangeSelector } from './DateSelector';
import { loadLanguage } from 'utils/language';

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: '#dedede'
  },
  h2: {
    marginBottom: '10px'
  },
  cardIconTitle: {
    color: 'black'
  },
  dateSelector: {
    display: 'flex',
    flexDirection: 'row',
    paddingBlock: '12px',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));

export default function BillerInvoices() {
  const history = useHistory();
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [loadData, { loading, data }] = useLazyQuery(PAGINATED_INVOICES, {
    variables: {
      page: 0,
      size: 5
    },
    fetchPolicy: 'no-cache'
  });
  const [loadInvoices] = useLazyQuery(INVOICES_BY_IDS, {
    variables: {
      invoiceIds: [],
      lng: loadLanguage()
    },
    fetchPolicy: 'no-cache'
  });
  const [filteredInvoice, setFilteredInvoice] = useDebounce({}, 500);
  const { t } = useTranslation();
  const classes = useStyles();

  const getData = useCallback((props) => {
    loadData({
      variables: {
        ...props,
        searchCriteria: filteredInvoice
      },
    });
  }, [filteredInvoice]);

  const columns = [
    {
      Header: t('Date'),
      accessor: 'Date',
      disableFilters: true,
      editable: false,
    },
    {
      Header: t('Energy type'),
      accessor: 'EnergyType',
      disableFilters: true,
      editable: false,
    },
    {
      Header: t('Billing text'),
      accessor: 'BillingText',
      disableSortBy: true,
      Filter: () => {
        return (
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: filteredInvoice.BillingText,
              onChange: (e) => {
                const filteredObject = { ...filteredInvoice, BillingText: e.target.value };
                setFilteredInvoice(filteredObject);
              },
              startAdornment: (
                <InputAdornment position="start">
                  <FilterList />
                </InputAdornment>
              ),
            }}
          />
        );
      },
      editable: false,
    },
    {
      Header: t('Total (CHF)'),
      accessor: 'BillTotalPrice',
      type: 'float',
      disableFilters: true,
      editable: false,
    },
    {
      Header: t('Actions')
    }
  ];
  function b64toBlob(base64String) {
    const binaryData = atob(base64String);
    const byteArray = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }
    return new Blob([byteArray], { type: 'application/zip' });
  }

  const generateZipFile = () => {   
   loadInvoices({ variables: {invoiceIds: data.paginatedInvoices.biller.totalInvoiceIds || [] }}).then((res) => {
    const { zipFile } = res.data.getInvoiceFiles;
    const blob = b64toBlob(zipFile);
  
    const url = URL.createObjectURL(blob)
    window.open(url)
   });
  }

  const state = useMemo(() => columns, []);
  const billerTableData = data ? data.paginatedInvoices.biller : { data: [], totalCount: 0, page: 0 };

  return (
    <AuthGuard permission="all">
      <GridContainer direction="column">
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Folder />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("Biller invoices")}</h4>
            </CardHeader>
            <CardBody>
              <GridItem className={classes.actions}>
              <DateRangeSelector
                classes={classes}
                handleChange={(dateRange) => {
                  const { startDate, endDate } = dateRange;
                  const filteredObject = {
                    ...filteredInvoice,
                    ...(startDate && { StartInvoice: dayjs(startDate, "DD.MM.YYYY").format("YYYY-MM-DD") }),
                    ...(endDate && { EndInvoice: dayjs(endDate, "DD.MM.YYYY").format("YYYY-MM-DD") }),
                  };
                  setFilteredInvoice(filteredObject);
                }}
              />
                <IconButton
                  style={{ height: "min-content" }}
                  size="medium"
                  color="primary"
                  disabled={loading || !data?.paginatedInvoices?.biller?.data?.length}
                  onClick={generateZipFile}>
                    <CloudDownloadTwoTone />
                </IconButton>
              </GridItem>
              <ReactTable
                columns={state}
                data={billerTableData}
                loading={loading}
                fetchData={getData}
                filteredObject={filteredInvoice}
                extraActionButtons={[{
                  title: t('Preview'),
                  icon: RemoveRedEye,
                  callback: row => window.open(`/invoices/${row.Id}`)
                }]}
                getQuery={{ query: PAGINATED_INVOICES }} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AuthGuard>
  );
}
