import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GridItem from 'components/Grid/GridItem';
import dayjs from 'dayjs';
import Datetime from 'react-datetime';

export const DateRangeSelector = (props) => {
  const { classes, handleChange } = props;
  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
  const { t } = useTranslation();

  useEffect(() => {
    handleChange(dateRange);
  }, [dateRange]);

  return (
    <GridItem>
      <span>{t('Filter by date range')}</span>
      <GridItem className={classes.dateSelector}>
        <GridItem>
          <Datetime
            closeOnSelect
            timeFormat={false}
            dateFormat='DD.MM.YYYY'
            disableOnClickOutside={false}
            onChange={(value) => {
              if (value && dayjs(value, 'DD.MM.YYYY').isValid)
                setDateRange({
                  ...dateRange,
                  startDate: dayjs(value).format('DD.MM.YYYY'),
                });
            }}
            inputProps={{
              placeholder: 'Start date',
              readOnly: false
            }}
          />
        </GridItem>
        <GridItem>
          <Datetime
            closeOnSelect
            timeFormat={false}
            dateFormat='DD.MM.YYYY'
            disableOnClickOutside={false}
            onChange={(value) => {
              if (value && dayjs(value, 'DD.MM.YYYY').isValid)
                setDateRange({
                  ...dateRange,
                  endDate: dayjs(value).format('DD.MM.YYYY'),
                });
            }}
            inputProps={{
              placeholder: 'End date',
              readOnly: false
            }}
          />
        </GridItem>
      </GridItem>
    </GridItem>
  );
};
