import { gql } from "@apollo/client";

export const ADD_PERMISSION = gql`
  mutation($personGroupTypId: Int!, $permissionId: Int) {
    addPermission(personGroupTypId: $personGroupTypId, permissionId: $permissionId) {
      response
      message
    }
  }
`;

export const DELETE_PERMISSION = gql`
  mutation($personGroupTypId: Int!, $permissionId: Int) {
    deletePermission(personGroupTypId: $personGroupTypId, permissionId: $permissionId) {
      response
      message
    }
  }
`;

export const UPDATE_PERMISSIONS = gql`
  mutation($personGroupTypId: Int!, $permissions: [Int]) {
    updatePermissions(personGroupTypId: $personGroupTypId, permissions: $permissions) {
      response
      message
    }
  }
`;
