import { gql } from '@apollo/client';

const SMART_ME_REAL_TIME = gql`
  {
    smartMeDeviceRealTime {
      measurePointName
      value
      unit
    }
  }
`;

export default SMART_ME_REAL_TIME;