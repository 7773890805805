import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import dutch from 'dayjs/locale/de-ch';
import french from 'dayjs/locale/fr-ch';
import italian from 'dayjs/locale/it-ch';
import english from 'dayjs/locale/en-gb';
import { getLSValue } from "./localStorage";

const locales = {
  'de-ch': dutch,
  'fr-ch': french,
  'it-ch': italian,
  'en-gb': english,
};

const langToLocaleHash = {
  'de': 'de-ch',
  'fr': 'fr-ch',
  'it': 'it-ch',
  'en': 'en-gb',
}

const BASE_ICON_URL = "http://openweathermap.org/img/wn/";

const getDayOfWeek = day => {
  const currentLang = getLSValue('language');
  const locale = langToLocaleHash[currentLang] || 'en-gb';

  dayjs.locale({ ...locales[locale] });
  dayjs.extend(localeData);

  const dayIndex = dayjs(day).day() ;

  const weekDays = dayjs.weekdaysShort(true);
  return weekDays[dayIndex - 1];
}

const getIcon = code => {
  return `${BASE_ICON_URL}${code}@2x.png`;
}

export {
  getIcon,
  getDayOfWeek
};
