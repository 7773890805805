import { gql } from '@apollo/client';

const GENERATE_ACTIVATION_TOKEN = gql`
  query generateActivationToken($input: SignUpPayloadInput) {
    generateActivationToken(input: $input) {
      response
      message
    }
  }
`;

export default GENERATE_ACTIVATION_TOKEN;