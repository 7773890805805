import React from "react";
// Components
import BillingDetailCard from "../BillingDetailCard";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// Styles
import billingStyles from "assets/jss/material-dashboard-pro-react/views/billingStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@material-ui/lab";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  ...styles,
  ...billingStyles(theme),
}));

export default function BillingInfoContainer({
  month,
  year,
  details,
  title,
  loading,
  energyType,
  ...props
}) {
  if (!details) return <></>;

  const calculateTotalUsage = (items) => {
    return items.reduce((acc, detail) => {
      const { name, used, sale } = detail;
      const index = acc.findIndex(item => {
        return item.name === name
      });

      if (index < 0) {
        acc.push(detail);
      } else {
        acc[index] = {
          ...detail,
          used: acc[index].used + used,
          sale: acc[index].sale + sale
         };
      }
      return acc;
    }, []);
  };

  const sumDetails = details.reduce((acc, detail) => {
    const { Company, Solar  } = detail;

    return {
      Company: [...acc.Company, ...Company],
      Solar: [...acc.Solar, ...Solar],
    } 
  }, {Company: [], Solar: []});


  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <GridContainer style={{ fontSize: "17px" }}>
      <GridItem xs={12} xl={8}>
        <GridContainer spacing={2}>
          {loading ? (
            <GridItem className={classes.mB15} xs={12}>
              <Paper className={classes.billingPaper} elevation={2}>
                <GridContainer direction="column">
                  <GridItem className={classes.billingTitle}>
                    <Skeleton height={30} style={{ marginTop: "5px" }} />
                  </GridItem>

                  <GridItem
                    style={{ fontSize: "20px" }}
                    className={classes.billingCardsWrapper}
                  >
                    <Skeleton height={100} />
                  </GridItem>
                </GridContainer>
              </Paper>
            </GridItem>
          ) : (
            <GridItem className={classes.mB15} xs={12}>
              <Paper className={classes.billingPaper} elevation={2}>
                <GridContainer direction="column" style={{ flexWrap: 'nowrap' }}>
                  <GridItem className={classes.billingTitle}>
                    <h4 className={classNames(classes.text, classes.mB15)}>
                      <b>{t(title)}</b>
                    </h4>
                  </GridItem>

                  <GridItem
                    style={{ fontSize: "20px" }}
                    className={classes.billingCardsWrapper}
                  >
                  <BillingDetailCard
                    company={calculateTotalUsage(sumDetails.Company)}
                    solar={calculateTotalUsage(sumDetails.Solar)}
                    energyType={energyType}
                />
                  </GridItem>
                </GridContainer>
              </Paper>
            </GridItem>
          )}
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
}
