import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Skeleton } from '@material-ui/lab';

import styles from "assets/jss/material-dashboard-pro-react/components/customTabsStyle.js";

const useStyles = makeStyles({
  ...styles,
  horizontalMargin: {
    margin: '0 5px'
  },
  customTabRootButton: {
    ...styles.tabRootButton,
    background: '#bfbfbf'
  },
  customCardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'linear-gradient(210deg, #fcfcfc, #b1b1b1)',
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(172, 172, 172, 0.4)',
    margin: '0 15px',
    padding: '15px',
    marginTop: '-20px',
    borderRadius: '3px !important',
  },
  title: {
    fontWeight: 'bolder',
    fontSize: '20px',
    color: 'white'
  },
  customSkeleton: {
    marginBottom: '-20px',
    marginTop: '-20px',
  },
  cardBody: {
    padding: '20px',
  }
});



export default function CustomTabs(props) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, value) => {
    props.onChangeValue(value);
    setValue(value);
  };
  const classes = useStyles();
  const { plainTabs, tabs, title, loading } = props;
  const cardTitle = classNames({
    [classes.title]: true
  });

  useEffect(() => setValue(props.value), [props.value]);

  const check = () => {
    if (!tabs.length) {
      return "no sensors available"
    }
    else {
      return tabs.map((prop, key) => {
        if (key === value) {
          return <div key={key}>{prop.tabContent}</div>;
        }
        return null;
      })
    }
  }


  return (
    <Card plain={plainTabs}>
      <CardHeader plain={plainTabs} className={classes.customCardHeader}>
        {title !== undefined ? <div className={cardTitle}>{title}</div> : null}
        <Tabs
          value={value}
          onChange={props.changeValue ? props.changeValue : handleChange}
          classes={{
            root: classes.tabsRoot,
            indicator: classes.displayNone
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {!loading && tabs.map((prop, key) => {
            var icon = {};
            if (prop.tabIcon) {
              icon = {
                icon: <prop.tabIcon style={{ width: '36px', height: '36px', fill: 'white' }} />
              };
            }
            return (
              <Tab
                classes={{
                  root: classes.customTabRootButton,
                  selected: classes.tabSelected,
                  wrapper: classes.tabWrapper
                }}
                key={key}
                label={prop.tabName}
                {...icon}
              />
            );
          })}
        </Tabs>
      </CardHeader>
      <CardBody className={classes.cardBody}>
        {loading ? (<>
          <Skeleton animation="wave" height={75} className={classes.customSkeleton} />
          <Skeleton animation="wave" height={75} className={classes.customSkeleton} />
          <Skeleton animation="wave" height={75} className={classes.customSkeleton} />
        </>)
          : (
            check()
          )
        }
      </CardBody>
    </Card>
  );
}

CustomTabs.defaultProps = {
  value: 0
};

CustomTabs.propTypes = {
  // the default opened tab - index starts at 0
  value: PropTypes.number,
  // function for changing the value
  // note, if you pass this function,
  // the default function that changes the tabs will no longer work,
  // so you need to create the changing functionality as well
  changeValue: PropTypes.func,
  onChange: PropTypes.func,
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose"
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool
};
