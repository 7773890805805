import React, { useEffect, useState } from "react";
// core components
import Icon from "@material-ui/core/Icon";
import Card from "components/Card/Card.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import { LinearProgress } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
// Apollo
import { useLazyQuery, useMutation } from "@apollo/client";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from 'classnames';
import { inject, observer } from "mobx-react";
import validate from "validate.js";
import { Email } from "@material-ui/icons";
import GENERATE_RESET_TOKEN from "queries/resetPassword/generateResetToken";
import CHANGE_PASSWORD from "mutations/resetPassword/changePassword";

const useStyles = makeStyles(theme => ({
  ...loginPageStyle(theme),
  ...sweetAlertStyles,
  forgotPasswordFormContainer: {
    "@media (max-width: 1199px)": {
      width: "450px"
    },
    "@media (min-width: 1200px)": {
      width: "450px"
    }
  },
  cardFooter: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0
  }
}));

const ForgotPassword = ({ session, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [resetToken, setResetToken] = useState('');
  const [error, setError] = useState('');
  const [cardAnimation, setCardAnimation] = useState("cardHidden");
  const [generateResetToken, { loading: generateTokenLoading, data: generateResetTokenResult, error: generateResetTokenError }] = useLazyQuery(GENERATE_RESET_TOKEN);
  const [changePassword, { loading: changePasswordLoading, data: changePasswordResult, error: changePasswordError }] = useMutation(CHANGE_PASSWORD);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const emailSchema = {
    Email: {
      email: true,
      presence: { allowEmpty: false, message: t('is required') },
    }
  };

  const passwordSchema = {
    Password: {
      presence: { allowEmpty: false, message: t('is required') },
      length: { minimum: 6, message: t('minimum 6 characters') },
    }
  }

  useEffect(() => {
    if (generateResetTokenError) {
      generateResetTokenError.graphQLErrors.map(error => setError(error.message));
    }

    if (changePasswordError) {
      changePasswordError.graphQLErrors.map(error => setError(error.message));
    }
  }, [generateResetTokenError, changePasswordError]);

  useEffect(() => {
    if (changePasswordResult) {
      const { response, message } = changePasswordResult.changePassword;
      if (response) {
        props.openAlert('You can log in now!', message, '/auth/login-page');
      }
    }

    if (generateResetTokenResult) {
      const { response, message } = generateResetTokenResult.generateResetToken;
      if (response) {
        props.openAlert('Please, check your inbox!', message);
      }
    }
  }, [generateResetTokenResult, changePasswordResult]);

  useEffect(() => {
    let errors;

    if (resetToken) {
      errors = validate(formState.values, passwordSchema);
    } else {
      errors = validate(formState.values, emailSchema);
    }

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }));
  }, [formState.values, session]);

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 300);
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const token = params.get('resetToken');

    if (token) {
      setResetToken(token);
    }
  }, [history]);

  const onFormSubmit = event => {
    event.preventDefault();
    const { Email, Password } = formState.values;

    if (resetToken) {
      changePassword({ variables: { resetToken, Password } });
    } else {
      generateResetToken({ variables: { Email } });
    }
  }

  const inputHandler = event => {
    event.persist();

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.id]: event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.id]: true
      }
    }));
  }

  const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]));

  return (
    <div className={classNames(classes.container, classes.forgotPasswordFormContainer)}>
      <GridContainer justify="center">
        <GridItem xs={12}>
          <form onSubmit={onFormSubmit}>
            <Card login className={classes[cardAnimation]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle} style={{ color: 'white' }}>
                  {resetToken ? t('Enter your new password') : t('Enter your email')}
                </h4>
              </CardHeader>
              <CardBody>
                <div className={classes.error}>{error}</div>

                {
                  resetToken
                    ? (<CustomInput
                      labelText={t('Password')}
                      id="Password"
                      helperText={hasError('Password') ? formState.errors.Password[0] : null}
                      error={hasError('Password')}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        onChange: inputHandler,
                        value: formState.values.Password || '',
                        type: "password",
                        autoComplete: "off"
                      }}
                    />)
                    : (<CustomInput
                      labelText={t('Email')}
                      id="Email"
                      helperText={hasError('Email') ? formState.errors.Email[0] : null}
                      error={hasError('Email')}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        value: formState.values.Email || '',
                        onChange: inputHandler
                      }}
                    />)
                }

              </CardBody>
              <CardFooter
                className={classNames(classes.justifyContentCenter, classes.cardFooter)}
                style={{ cursor: (formState.isValid && !generateTokenLoading && !changePasswordLoading) ? 'pointer' : 'not-allowed' }}>
                <Button
                  block
                  simple
                  size="lg"
                  color="rose"
                  type="submit"
                  disabled={!formState.isValid || generateTokenLoading || changePasswordLoading}
                >
                  {resetToken ? t('Change password') : t('Submit')}
                </Button>

                <Button
                  block
                  simple
                  size="lg"
                  color="rose"
                  onClick={() => history.push('/auth/login-page')}
                  type="button"
                >
                  {t('Back')}
                </Button>
              </CardFooter>
              {generateTokenLoading || changePasswordLoading && <LinearProgress />}
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default inject('session')(observer(ForgotPassword));
