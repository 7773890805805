import { gql } from '@apollo/client';

const REMOVE_COMPANY_LOGO = gql`
  mutation removeCompanyLogo {
    removeCompanyLogo {
      response
      message
    }
  }
`;

export default REMOVE_COMPANY_LOGO;
