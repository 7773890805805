import React from 'react'
// Components
import Card from 'components/Card/Card'
import { Skeleton } from '@material-ui/lab'
import CardIcon from 'components/Card/CardIcon'
import GridItem from 'components/Grid/GridItem.js'
import CardHeader from 'components/Card/CardHeader'
import GridContainer from 'components/Grid/GridContainer.js'
// Icons
import { FlashOn, Opacity, Whatshot } from '@material-ui/icons'
// Styles
import { makeStyles } from '@material-ui/core'
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js'
// Apollo
import { useQuery } from '@apollo/client'
// Utils
import { useTranslation } from 'react-i18next'
import ENERGY_TYPES from 'queries/energyTypes'
import { inject, observer } from 'mobx-react'
import { Link, useLocation } from 'react-router-dom'

const useStyles = makeStyles(styles)

function EnergyTypeCards({ onChangeCard, activeTab, smartData, smartLoader}) {
  const { data: energyTypes } = useQuery(ENERGY_TYPES)
  const { t } = useTranslation()
  const classes = useStyles()
  const location = useLocation();
  
  const energyHash = {
    '1': { icon: <FlashOn />, color: 'warning', data: smartData?.smartMeDeviceRealTime },
    '2': { icon: <Opacity />, color: 'rose', data: [] },
    '3': { icon: <Opacity />, color: 'info', data: [] },
    '4': { icon: <Whatshot />, color: 'danger', data: [] },
    '5': { icon: <Whatshot />, color: 'danger', data: [] },
  }

  const loadSmartMeData = (data) => {
    if (!data) return ''
    if (smartLoader) {
      return (
        <>
          <Skeleton animation="wave" height={18} />
          <Skeleton animation="wave" height={18} />
        </>
      )
    }

    return data.map((item, index) => {
      return (
        <p className={classes.cardTitle} key={index}>
          <b>{item.measurePointName}:</b> &nbsp;
          <span className={classes.measurePointValue}>{Number(item.value).toFixed(3)} &nbsp;</span>
          <b>{item.unit}</b>
        </p>
      )
    })
  }

  const renderEnergyCards = () => {
    return energyTypes?.getEnergyTypes.map((item) => {
      const { icon, color, data } = energyHash[item.Id]

      const cardStyle =
        item.Id === activeTab
          ? { boxShadow: 'rgb(0 0 0 / 17%) 0px 8px 12px, rgb(0 0 0 / 11%) 13px 9px', cursor: 'pointer', padding: '10px 0' }
          : { cursor: 'pointer', padding: '10px 0' }

      const cardIconStyle =
        color === 'danger'
          ? {
              background: 'linear-gradient(220deg, #8150ef, #b235e5)',
              boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(136, 76, 237, 0.49)',
            }
          : {}
      const energyCard= () => {
        return (
          <Card onClick={() => onChangeCard(item.Id)} style={cardStyle}>
            <CardHeader color={color} stats icon>
              <CardIcon color={color} style={cardIconStyle}>
                {icon}
              </CardIcon>
              <p className={classes.cardCategory}>{t(item.Name)}</p>
              {loadSmartMeData(data)}
            </CardHeader>
          </Card>)
          }
        
      return (
        item.Status === 'active' && (
          <GridItem xs={12} sm={6} md={6} lg={3} key={item.Id}>
            { location.pathname.includes('billing') 
          ?
          <Link to={`/billing/${item.Id}`}>
         { energyCard()}</Link>
          :
          energyCard() 
          }
          </GridItem>
        )
      )
    })
  }

  return <GridContainer container>{renderEnergyCards()}</GridContainer>
}

export default EnergyTypeCards
