import RECENT_MONTHS_BILLING from "queries/billing/recentMonthsBilling";
import { useQuery } from "@apollo/client";

export const useMonthlyBilling = () => {
  const { data, loading } = useQuery(RECENT_MONTHS_BILLING, {
    fetchPolicy: 'no-cache'
  });

  const getBillingMonthlyByEnergyType = (energy) => {
    if (!data) return;
    return data.recentMonthsBilling[energy];
  };

  return {
    data,
    loading,
    getBillingMonthlyByEnergyType,
  };
};
