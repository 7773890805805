import { gql } from "@apollo/client";

const INVOICES_BY_IDS = gql`
  query getInvoiceFiles($invoiceIds: [Int]!, $lng: String) {
    getInvoiceFiles(invoiceIds: $invoiceIds, lng: $lng) {
      zipFile
    }
  }
`;

export default INVOICES_BY_IDS;
