import { gql } from '@apollo/client';

const UPLOAD_COMPANY_LOGO = gql`
  mutation uploadCompanyLogo($file: Upload!) {
    uploadCompanyLogo(file: $file) {
      response
      message
    }
  }
`;

export default UPLOAD_COMPANY_LOGO;
