import { makeAutoObservable } from 'mobx';

class Widgets {
  currentPower = {
    image: ''
  };

  constructor() {
    makeAutoObservable(this);
  }

  get widgets() {
    return {
      currentPower: {
        image: this.currentPower.image,
      }
    }
  }

  setCurrentPowerImage(image) {
    this.currentPower = {
      ...this.currentPower,
      image
    }
  }
}

export default Widgets;
