import { gql } from '@apollo/client'; 

const ALL_PERSON_GROUPS = gql`
  {
    allPersonGroups {
      Id
      Name
    }
  }
`;

export default ALL_PERSON_GROUPS ;
