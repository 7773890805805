import React, { useEffect } from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.js";

import defaultImage from "assets/img/image_placeholder.jpg";
// i18n
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { Skeleton } from "@material-ui/lab";

const ImageUpload = ({ widgets, ...props }) => {
  const { t } = useTranslation();
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(defaultImage);
  useEffect(() => {
    if (props.loading) return;
    if (props.image) {
      setFile(props.image);
      setImagePreviewUrl(props.image);
    } else if (props.defaultImage) {
      setFile(props.defaultImage);
      setImagePreviewUrl(props.defaultImage);
    } else {
      setFile(defaultImage);
      setImagePreviewUrl(defaultImage);
    }
  }, [props.image, props.loading, props.defaultImage]);

  let fileInput = React.createRef();
  const handleImageChange = e => {
    e.preventDefault();
    let file = e.target.files[0];
    props.onUploadFile(file);
  };
  // eslint-disable-next-line
  const handleSubmit = e => {
    e.preventDefault();
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(defaultImage);
    props.onRemoveImage();
    widgets.setCurrentPowerImage('');
    fileInput.current.value = null;
  };
  let { image, addButtonProps, changeButtonProps, removeButtonProps, imageProps, imageWrapperProps } = props;
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} accept=".jpeg, .png" />
      <div className={"thumbnail"} {...imageWrapperProps}>
        {
          props.loading
            ? <Skeleton width={250} height={185} style={{ transform: 'scale(1)' }} />
            : <img src={imagePreviewUrl} alt="..." {...imageProps} />
        }
      </div>
      <div>
        {!file ? (
          <Button {...addButtonProps} onClick={() => handleClick()}>
            {t("Select image")}
          </Button>
        ) : (
          <span>
            <Button {...changeButtonProps} onClick={() => handleClick()}>
              {t('Change')}
            </Button>
            <Button {...removeButtonProps} onClick={() => handleRemove()}>
              {t('Remove')}
            </Button>
          </span>
        )}
      </div>
    </div >
  );
}

ImageUpload.propTypes = {
  image: PropTypes.string,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  onUploadFile: PropTypes.func,
  onRemoveImage: PropTypes.func,
};

export default inject('widgets')(observer(ImageUpload));
