import { gql } from '@apollo/client';

const UPDATE_USER = gql`
  mutation updateUser($id: Int!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      response
      message
    }
  }
`;

export default UPDATE_USER;