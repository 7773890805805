import { gql } from '@apollo/client';

const CREATE_NEW_USER = gql`
  mutation createNewUser($accountId: Int, $input: UserInput! ) {
    createNewUser(accountId: $accountId, input: $input) {
      response
      message
    }
  }
`;

export default CREATE_NEW_USER;
