import React from 'react';
// Icons
import { ChevronRight, ChevronLeft } from '@material-ui/icons';
// Styles
import { makeStyles } from '@material-ui/core';
import './AnimatedArrows.scss';

const useStyles = makeStyles(() => ({
  icon: {
    color: 'black'
  }
}));

export default function AnimatedArrows({ direction = 'right', ...props }) {
  const classes = useStyles();

  const renderIcon = i => (
    direction === 'right'
      ? <ChevronRight key={i} viewBox="3 3 17 17" className={classes.icon} style={{ width: 20, height: 20 }} />
      : <ChevronLeft key={i} viewBox="3 3 17 17" className={classes.icon} style={{ width: 20, height: 20 }} />
  );

  return (
    <div id="animated-arrows" {...props}>
      <div className={direction}>
        {Array.from(Array(20).keys()).map((_, i) => renderIcon(i))}
      </div>

      <div className={direction}>
        {Array.from(Array(20).keys()).map((_, i) => renderIcon(i))}
      </div>
    </div>
  );
}
