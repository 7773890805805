import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import PropTypes from "prop-types";
import classNames from "classnames";

export const MP_INPUT_TYPE = 1;
export const MP_CONSUMPTION_TYPE = 2;
export const MP_OUTPUT_TYPE = 3;

const useStyles = makeStyles({
  ...styles,
  measurePoint: {
    padding: '5px',
    border: '2px solid #ddd',
    borderRadius: '5px',
    background: '#eee',
    margin: '5px',
    '&:hover': {
      background: '#ccc',
      cursor: 'pointer',
    }
  },
  title: {
    fontSize: '10px',
  },
  measureInputPoint: {
    position: 'relative',
    left: '-50px',
  },
  measureOutputPoint: {
    position: 'relative',
    right: '-50px',
  },
  measureConsumptionPoint: {

  },
});


export function MeasurePointItem(props) {
  const { point: { Name, DirectionPos, value } } = props;
  const classes = useStyles();

  const pointClasses = classNames({
    [classes.measurePoint]: true,
    [classes.measureInputPoint]: DirectionPos === MP_INPUT_TYPE,
    [classes.measureConsumptionPoint]: DirectionPos === MP_CONSUMPTION_TYPE,
    [classes.measureOutputPoint]: DirectionPos === MP_OUTPUT_TYPE,
  });

  return (
    <Box className={ pointClasses }>
      <Box>{Name}</Box>
      <Box><b className={classes.title}>Value:</b> {value} <b>KW</b> </Box>
    </Box>
  )
}

MeasurePointItem.propTypes = {
  point: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    SensorPos: PropTypes.number,
    DirectionPos: PropTypes.number,
    Name: PropTypes.string,
    EnergyTypPos: PropTypes.number,
    StatusPos: PropTypes.number,
    TarifPos: PropTypes.number,
    JsonFieldNameValue: PropTypes.string,
    JsonFieldNameUnit: PropTypes.string,
    ClientPos: PropTypes.number,
    IsCalculated: PropTypes.number,
    LastHearbeat: PropTypes.string,
    IsMainDashboard: PropTypes.number,
    value: PropTypes.number,
  }),
};
