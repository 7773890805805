import { gql } from '@apollo/client';

const UPDATE_ACCOUNT = gql`
  mutation($id: Int!, $input: AccountInput) {
    updateAccount( id: $id, input: $input ) {
      response
      message
    }
  }
`;

export default UPDATE_ACCOUNT;
