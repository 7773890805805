import React, { useCallback, useState } from "react";

// Core components
import Card from "components/Card/Card";
import GridItem from "components/Grid/GridItem";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import AuthGuard from "components/AuthGuard/AuthGuard";
import GridContainer from "components/Grid/GridContainer";
import ReactTable from "components/ReactTable/ReactTable.js";
// i18n
import { useTranslation } from "react-i18next";
// Icons
import { Assignment } from "@material-ui/icons";
// Styles
import { makeStyles } from "@material-ui/core";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import DashBoardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
// Account mutations and query
import { USERS } from "queries";
import {
  UPDATE_USER,
  DELETE_USER,
  GENERATE_USER_ACTIVATION_TOKEN,
} from "mutations/user";
import { useLazyQuery } from "@apollo/client";
import { PERMISSIONS } from "utils/session";
import { inject, observer } from "mobx-react";

const styles = {
  ...DashBoardStyles,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);

const Users = ({ session, showAlert }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const accountId = session.activeAccount.Id;
  const [loadData, { loading, data }] = useLazyQuery(USERS, {
    variables: {
      page: 0,
      size: 10,
      accountId,
    },
  });

  const getData = useCallback(({ page, size }) => {
    loadData({ variables: { page, size, accountId } });
  }, []);

  const columns = [
    {
      Header: t("First name"),
      accessor: "FirstName",
    },
    {
      Header: t("Last name"),
      accessor: "LastName",
    },
    {
      Header: t("Email"),
      accessor: "Email",
      editable: false,
    },
    {
      Header: t("Password"),
      accessor: "Password",
      editable: false
    },
    {
      Header: t("Actions"),
      editable: false
    },
  ];

  const getVisibleColumns = () => {
    const canChangeUserPassword =
      session.permissions.indexOf("ChangeUserPassword") !== -1;
    const result = columns.filter((column) => {
      if (!canChangeUserPassword && column.Header === t("Password")) return;
      return column;
    });

    return result;
  };

  const [state, setState] = useState(getVisibleColumns());
  const tableData = data ? data.users : { data: [], totalCount: 0, page: 0 };

  return (
    <AuthGuard permission={PERMISSIONS.users}>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("Users")}</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={state}
                data={tableData}
                loading={loading}
                fetchData={getData}
                getQuery={{ query: USERS, variables: { accountId } }}
                showSuccessAlert={(data) =>
                  showAlert(
                    t("Success"),
                    t(data.generateUserActivationToken.message)
                  )
                }
                showErrorAlert={(message) =>
                  showAlert(t("Error"), t(message), "", "error")
                }
                addRowText={t("Add user")}
                deleteRowText={t("Are you sure you want to delete user?")}
                onAddQuery={GENERATE_USER_ACTIVATION_TOKEN}
                onUpdateQuery={UPDATE_USER}
                onDeleteQuery={DELETE_USER}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AuthGuard>
  );
};

export default inject("session")(observer(Users));
