import React, { useEffect } from "react";
// Apollo
import { useQuery } from "@apollo/client";
import CO2_SAVINGS from "queries/widgets/co2Savings";
// Components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
// Material UI
import { Skeleton } from "@material-ui/lab";
// Styles
import { makeStyles } from "@material-ui/core";
import Card from "components/Card/Card.js";
// i18n
import { useTranslation } from "react-i18next";
import WidgetGuard from 'components/WidgetGuard';
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { ReactComponent as CO2SavingsIcon } from '../../../assets/img/co2-savings-2.svg';
import { inject, observer } from "mobx-react";

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginBottom: theme.spacing(2)
  },
  text: {
    color: 'black'
  },
    ...styles,  
    alignLeft: {
      textAlign: 'left'
    },
    heading5: {
      color: 'black'
    },
    widgetsWrapper: {
      // padding: '0 25px',
      overflow: 'hidden'
    },
    cardCategory: {
      ...styles.cardCategory,
      lineHeight: '17px'
    }
}));


  const CO2Savings = ({ session }) => {
  const { t } = useTranslation();
  const {data, loading, error } = useQuery(CO2_SAVINGS, { variables: { ClientPos: session.activeAccount.clientId } });
  const classes = useStyles();
  

if(!data) {
  return null
};
  return (
    <WidgetGuard widget="CO2SavingsWidget">
                <GridItem xs={12} sm={12} md={12} lg={5}>
                  <Card hover>
                    <CardHeader color="warning" stats icon>
                      {
                        CO2SavingsIcon && (
                          <CardIcon color="warning">
                            <CO2SavingsIcon />
                          </CardIcon>
                        )
                      }
                      <p className={classes.cardCategory}>{t('CO2 savings')}</p>
    <GridContainer direction="column" className={classes.wrapper}>

      <GridItem>
        <div className={classes.text}>
          {
            loading
              ? (<Skeleton width={75} height={20} />)
              : (<h5>{t('CO2')} - <b>{Math.round(data.co2Savings.CO2saving)} t</b></h5>)
          }
        </div>
      </GridItem>

      <GridItem>
        <div className={classes.text}>
          {
            loading
              ? (<Skeleton width={75} height={20} />)
              : (<h5>{t('Trees')} - <b>{Math.round(data.co2Savings.TreeSaving)}</b></h5>)
          }
        </div>
      </GridItem>

    </GridContainer>
                    </CardHeader>
                  </Card>
                </GridItem>
              </WidgetGuard> 
  );
}
export default inject('session')(observer(CO2Savings));