import React from 'react'
// Components
import Accordion from 'components/Accordion/Accordion'

function ElectricityTab({ data, activeTabColor, activeTitle, energyTypePos, activeSensorId, setActiveSensorId }) {
  const renderCollapsibleElements = () => {
    if (data) {
      const measurePoints = data

      if (measurePoints.length) {
        return measurePoints.map(({ Name, Id }) => ({
          title: Name,
          id: Id,
          activeTabColor,
          activeTitle,
          energyTypePos,
        }))
      }
    }

    return []
  }

  const setActiveTab = (active) => {
    if (active === -1) return
    setActiveSensorId(active)
  }

  return <Accordion active={activeSensorId} onChange={setActiveTab} collapses={renderCollapsibleElements()} />
}

export default ElectricityTab
