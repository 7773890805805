import React, { useEffect, useState } from 'react';
// Material UI core
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// Styles
import { makeStyles, Paper } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import billingStyles from "assets/jss/material-dashboard-pro-react/views/billingStyle.js";
// Utils
import { useTranslation } from "react-i18next";
import { Skeleton } from "@material-ui/lab";
import BillingInfoContainer from "./BillingInfoContainer";
import BillingActions from "./Actions";
import NavPills from "components/NavPills/NavPills.js";
import MonthlyBilling from "./MonthlyBilling";
// Hooks
import { useBilling } from "./hooks/useBilling";
import {useHistory, useParams } from 'react-router-dom';
import EnergyTypeCards from 'components/EnergyTypeCards/EnergyTypeCards';
import { inject, observer } from 'mobx-react';
import { getMonthNames } from 'utils/graph';
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  ...styles,
  ...billingStyles(theme),
}));

const BillingWrapper = ({ session }) => {
  const params = useParams();
  const [energyTypeId, setEnergyTypeId ]= useState(0);
  const {
    month,
    year,
    data,
    loading,
    selectedEnergyCard,
    setYear,
    setMonth,
    setSelectedEnergyCard,
  } = useBilling();
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    const id = parseInt(params.id);
    if (id && id >= 0 && id < 5 ) {
      setEnergyTypeId(id);
    } else {
      setEnergyTypeId(0);
    }
  
  }, 
  [params]) 

  const activeTabProps = () => {
    switch (energyTypeId) {
      case 1: return { color: 'warning', title: "Electricity"};
      case 2: return { color: 'rose', title: "Warm Water"};
      case 3: return { color: 'info', title: "Cold Water"};
      case 4: return { color: 'danger', title: "Heating"};
      default: return { color: 'warning'};
    }
  }

  const renderNavPillsContent = () => {
    if (!data) return [];
    const result = [];

    ["payer", "biller"].forEach((type) => {
      const foundData = data.billingDetails[type];
      if (!foundData.length) return;

      result.push({
        tabButton: t(type),
        tabContent: renderBillingDetails(foundData),
      });
    });

    return result;
  };

  const renderBillingDetails = (details) => {
    const calcTotalUsage = (data) => data.reduce((acc, { UsageTotal }) =>  acc + UsageTotal, 0)

    if (!details.length) return;
    const totalUsage = calcTotalUsage(details);

    const groupedDetails =  details.reduce((acc, d) => {
      const key = d.BillingTitle;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(d);
      return acc;
    }, {});
  
    return (
      <>
        <Paper className={classes.billingContainer} elevation={3}>
          <GridItem sm={12} md={8} lg={6} className={classes.mB15}>
            <Paper className={classes.paper} elevation={2}>
              <h4 style={{ marginBottom: "15px" }}>
                <b>
                  {t("Billing for")} {`${getMonthNames()[month - 1] || year}`}
                </b>
              </h4>
              <GridContainer direction="column">
                {loading ? (
                  <>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </>
                ) : (
                  <>
                    <GridItem className={classes.flexBetween} >
                      <h5><b>{t("Consumption")}</b></h5>
                    </GridItem>
                    {Object.keys(groupedDetails).map((key, index) => {
                    return (
                      <GridItem
                        className={classNames(classes.flexBetween, classes.mB5)}
                        style={{ marginTop: "8px" }}
                        key={index}
                      >
                        <div>{key}</div>
                        <div>
                          <b>{`${Number(calcTotalUsage(groupedDetails[key])).toFixed(2)} CHF`}</b>
                        </div>
                      </GridItem>
                    );
                  })}
                  <hr style={{width: "100%"}}/>
                  <GridItem className={classes.flexBetween} style={{ marginTop: "10px", fontWeight: 600 }} >
                    <div>{t("Total")}</div>
                    <div>
                      {Number(totalUsage).toFixed(2)} CHF
                    </div>
                  </GridItem>
                  </>
                )}
              </GridContainer>
            </Paper>
          </GridItem>
        </Paper>
        <Paper className={classes.billingContainer} elevation={3}>
          {Object.keys(groupedDetails).map((key, index) => (
            <BillingInfoContainer
              key={index}
              loading={loading}
              energyType={selectedEnergyCard}
              title={key}
              details={groupedDetails[key]}
              month={month}
              year={year}
            />
          ))}
        </Paper>
      </>
    );
  };
  const onChangeBillingCard= (index) => {
    session.setActiveBillingEnergyTab(index)
  }
  const renderTabs = () => {
    if (
      !data.billingDetails.payer.length &&
      !data.billingDetails.biller.length
    ) {
      return (
        <GridItem style={{ padding: "0 1.5rem", marginTop: "1rem" }}>
          <h4>{t("No data found for selected parameters.")}</h4>
        </GridItem>
      );
    } else {
      return (
        <>
          <div style={{ marginLeft: "20px", marginTop: "10px" }}>
            <h4>{t("Bill for")}:</h4>
          </div>
          <NavPills
            color={activeTabProps(selectedEnergyCard.Id).color}
            tabs={renderNavPillsContent()}
          />
        </>
      );
    }
  };

  const [activeTitle, setActiveTitle] = useState(activeTabProps().title );
  const onChangeEnergyType = (index, title) => {
    setActiveTitle(index);
  }
  
  return (
    <GridContainer direction="column">
      <EnergyTypeCards onChangeCard={onChangeBillingCard} activeTab={session.activeBillingEnergyTab}/>

      <MonthlyBilling
        energyType={selectedEnergyCard}
        onChangeEnergyType={(card) => setSelectedEnergyCard(card)}
      />

      <BillingActions
        setMonth={(val) => setMonth(val)}
        setYear={(val) => setYear(val)}
        loading={loading}
        month={month}
        year={year}
        color={activeTabProps(selectedEnergyCard.Id).color}
      />
    

      {loading || !data ? <Skeleton /> : renderTabs()}
    </GridContainer>
  );
};

export default inject('session')(observer(BillingWrapper))

