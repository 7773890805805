import dayjs from "dayjs";
import { getMonthNames } from "./graph";

export const housePicker = data => [{ divider: true }, ...data.map(item => item.Name)];


const subtractedDate = dayjs().subtract(10, 'year');
const getYears = () => {
  const result = [];

  for (let i = subtractedDate.year(); i <= dayjs().year(); i++) {
    result.push(i);
  }

  return result.reverse();
}

export const yearPicker = [{ divider: true }, ...getYears()];