import { gql } from '@apollo/client';

const UPDATE_PDF_SETTING = gql`
  mutation($id: Int, $input: PdfSettingInput!) {
    updatePdfSetting(id: $id, input: $input) {
      Id
      textColor
      logo
      clientPos
    }
  }
`;

export default UPDATE_PDF_SETTING;
