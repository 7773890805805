import { gql } from '@apollo/client';

const SMART_ME_REAL_TIME_CURRENT_POWER = gql`
  query smartMeRealTimeCurrentPower($ClientPos: Int) {
    smartMeRealTimeCurrentPower(ClientPos: $ClientPos) {
      ActivePower
      ActivePowerUnit
      Direction
      MeasurePoint
    }
  }
`;

export default SMART_ME_REAL_TIME_CURRENT_POWER;