import React from "react";
import PropTypes from "prop-types";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import { Business, ContactMail, RecordVoiceOver } from "@material-ui/icons";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import ALL_PERSON_GROUPS from "queries/allPersonGroups";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { CircularProgress, FormHelperText, FormLabel, Typography } from "@material-ui/core";
import Datetime from "react-datetime";

import client from "apollo/client";
import dayjs from "dayjs";

var isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
dayjs.extend(isSameOrAfter);

const style = {
  ...customSelectStyle,
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.dateFormat = "DD/MM/YYYY";
    this.state = {
      FirstName: "",
      FirstNameState: "",
      LastName: "",
      LastNameState: "",
      Company: "",
      CompanyState: "",
      Street: "",
      StreetState: "",
      ZIP: "",
      ZIPState: "",
      City: "",
      CityState: "",
      ValidFrom: '',
      ValidFromState: 'success',
      ValidFromError: '',
      ValidTo: '',
      ValidToState: 'success',
      ValidToError: '',
      Country: "",
      CountryState: "",
      PersonGroupPos: 1,
      PersonGroups: [],
      loading: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    client.query({ query: ALL_PERSON_GROUPS })
      .then(res => {
        if (res.data) {
          const data = res.data.allPersonGroups;
          this.setState({
            PersonGroups: data,
            ValidFrom: dayjs(),
            ValidTo: dayjs().add(1, 'week'),
            PersonGroupPos: data[0].Id,
            loading: false
          });
        }
      })
      .catch(err => console.error(err));
  }

  sendState() {
    return {
      FirstName: this.state.FirstName,
      LastName: this.state.LastName,
      Company: this.state.Company,
      Street: this.state.Street,
      ZIP: this.state.ZIP,
      City: this.state.City,
      Country: this.state.Country,
      ValidFrom: dayjs(this.state.ValidFrom).format("YYYY-MM-DD"),
      ValidTo: dayjs(this.state.ValidTo).format("YYYY-MM-DD"),
      PersonGroupPos: this.state.PersonGroupPos
    };
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  isValidated() {
    const { FirstNameState, LastNameState, ValidFromState, ValidToState, CompanyState } = this.state;
    if (
      FirstNameState === "success" &&
      LastNameState === "success" &&
      ValidFromState === "success" &&
      ValidToState === "success" &&
      CompanyState === "success"
    ) {
      return true;
    } else {
      const res = {
        FirstNameState: FirstNameState !== 'success' ? 'error' : 'success',
        LastNameState: LastNameState !== 'success' ? 'error' : 'success',
        CompanyState: CompanyState !== 'success' ? 'error' : 'success',
        ValidFrom: ValidFromState !== 'success' ? 'error' : 'success',
        ValidTo: ValidToState !== 'success' ? 'error' : 'success',
      };

      this.sendState({ ...this.state, res });
    }
    return false;
  }

  setPickerValue(val, field) {
    const isDateValid = dayjs(val, this.dateFormat).isValid();

    if (!isDateValid) {
      this.setState({ ...this.state, [`${field}State`]: "error", [`${field}Error`]: "Invalid date format." });
      return;
    } else {
      if (field === "ValidFrom") {
        if (dayjs(val).isAfter(dayjs(this.state.ValidTo))) {
          this.setState({ ...this.state, [field]: val, [`${field}State`]: "error", ValidFromError: "Valid from should be before Valid to." });
          return;
        }

        if (dayjs(this.state.ValidTo).diff(val, 'd') < 7) {
           return this.setState({ ...this.state, [field]: val, ValidTo: dayjs(val).add(1, 'week') });
        }
      }

      this.setState({ ...this.state, [field]: val, [`${field}State`]: "success", [`${field}Error`]: "" });
    }
  }

  datePickerValidationHandler(incomingDate, direction) {
    if (direction === 'after') {
      const currentDay = dayjs().startOf('d');
      return dayjs(incomingDate).isSameOrAfter(currentDay);
    } else {
      const validFromDate = this.state.ValidFrom || dayjs();
      const nextWeek = dayjs(validFromDate).add(1, 'week').startOf('d');
      return dayjs(incomingDate).isSameOrAfter(nextWeek);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer alignItems="flex-end">
        <GridItem xs={12} sm={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="valid-from">Valid from</FormLabel>
            <Datetime
              closeOnSelect
              timeFormat={false}
              value={this.state.ValidFrom}
              dateFormat={this.dateFormat}
              disableOnClickOutside={false}
              isValidDate={(currentDate) => this.datePickerValidationHandler(currentDate, 'after')}
              onChange={val => this.setPickerValue(val, 'ValidFrom')}
              inputProps={{ placeholder: "Valid from", id: 'valid-from', readOnly: true }}
            />
            <Typography variant="caption" style={{ marginTop: '5px' }} color="error">
              {this.state.ValidFromState !== "success" ? this.state.ValidFromError : ""}
            </Typography>
          </FormControl>

          <CustomInput
            success={this.state.FirstNameState === "success"}
            error={this.state.FirstNameState === "error"}
            labelText={<span>First Name <small>(required)</small></span>}
            id="FirstName"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              onChange: event => this.change(event, "FirstName", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            success={this.state.LastNameState === "success"}
            error={this.state.LastNameState === "error"}
            labelText={<span>Last Name <small>(required)</small></span>}
            id="LastName"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              onChange: event => this.change(event, "LastName", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <RecordVoiceOver className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            success={this.state.CompanyState === "success"}
            error={this.state.CompanyState === "error"}
            labelText={<span>Company <small>(required)</small></span>}
            id="Company"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              onChange: event => this.change(event, "Company", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Business className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />

          {
            this.state.loading
              ? (
                <CircularProgress />
              )
              : (
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Choose person group
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.PersonGroupPos}
                    onChange={this.handleSimple}
                    inputProps={{
                      name: "PersonGroupPos",
                      id: "simple-select"
                    }}
                  >
                    {this.state.PersonGroups.map(personGroup => (
                      <MenuItem
                        key={personGroup.Id}
                        classes={{ root: classes.selectMenuItem }}
                        value={personGroup.Id}>
                        {personGroup.Name}                
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )
          }
        </GridItem>

        <GridItem xs={12} sm={6}>
          <FormLabel htmlFor="valid-to">Valid to</FormLabel>
          <FormControl fullWidth>
            <Datetime
              closeOnSelect
              timeFormat={false}
              value={dayjs(this.state.ValidTo).format(this.dateFormat)}
              dateFormat={this.dateFormat}
              disableOnClickOutside={false}
              isValidDate={(currentDate) => this.datePickerValidationHandler(currentDate)}
              onChange={val => this.setPickerValue(val, 'ValidTo')}
              inputProps={{ placeholder: "Valid to", id: "valid-to", readOnly: true }}
            />
            <Typography variant="caption" style={{ marginTop: '5px' }} color="error">
              {this.state.ValidToState !== "success" ? this.state.ValidToError : ""}
            </Typography>
          </FormControl>

          <CustomInput
            success={this.state.StreetState === "success"}
            error={this.state.StreetState === "error"}
            labelText={<span>Street <small>(optional)</small></span>}
            id="Street"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              onChange: event => this.change(event, "Street", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <ContactMail className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            success={this.state.ZIPState === "success"}
            error={this.state.ZIPState === "error"}
            labelText={<span>ZIP <small>(optional)</small></span>}
            id="ZIP"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              onChange: event => this.change(event, "ZIP", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <ContactMail className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            success={this.state.CityState === "success"}
            error={this.state.CityState === "error"}
            labelText={<span>City <small>(optional)</small></span>}
            id="City"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              onChange: event => this.change(event, "City", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <ContactMail className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            success={this.state.CountryState === "success"}
            error={this.state.CountryState === "error"}
            labelText={<span>Country <small>(optional)</small></span>}
            id="Country"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              onChange: event => this.change(event, "Country", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <ContactMail className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Step1);
