import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import { Widgets } from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardAvatar from "components/Card/CardAvatar.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// Icons
import { Edit, Close, Check } from "@material-ui/icons";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/faces/marc.jpg";
import AuthGuard from "components/AuthGuard/AuthGuard";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import ImageUpload from "components/CustomUpload/ImageUpload";
import AboutProfileCard from "components/AboutProfileCard";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPDATE_USER } from "mutations/user";
import { REFRESH_TOKEN } from "queries";
import { getLSValue, setLSValue } from "utils/localStorage";
import { CircularProgress } from "@material-ui/core";
import UPLOAD_PROFILE_IMAGE from "mutations/upload";
import { Skeleton } from "@material-ui/lab";
import UPLOAD_WIDGET_IMAGE from "mutations/upload/widgetImage";
import { fetchingImage } from "utils/image";
import REMOVE_PROFILE_IMAGE from "mutations/upload/removeProfileImage";
import REMOVE_WIDGET_IMAGE from "mutations/upload/removeWidgetImage";
import UPLOAD_COMPANY_LOGO from "mutations/account/uploadCompanyLogo";
import REMOVE_COMPANY_LOGO from "mutations/account/removeCompanyLogo";

const useStyles = makeStyles({
  ...styles,
  subtitle: {
    color: "#999",
    fontWeight: "bolder",
    fontSize: ".935rem",
  },
  containerWrapper: {
    margin: "20px 0",
  },
  subtitleText: {
    color: "#000",
  },
  editButton: {
    position: "absolute",
    top: "10px",
    right: "20px",
  },
  inputsWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& label": {
      textAlign: "left",
    },
  },
  button: {
    padding: "5px 15px",
    zIndex: "10",
  },
  spinner: {
    "& > svg": {
      margin: "0px",
      width: "20px",
      height: "20px",
    },
  },
});

function UserProfile({ session, widgets }) {
  const { firstName, lastName, email } = session.user;
  const { t } = useTranslation();
  const classes = useStyles();
  const isAdmin = session.permissions.includes("SuperAdmin");
  const isEditable = session.permissions.includes("EditProfileData");
  const pwdChangeAllowed = session.permissions.includes("ChangeUserPassword");

  const [houseImage, setHouseImage] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyLogoLoading, setCompanyLogoLoading] = useState(false);
  const [profileImageLoading, setProfileImageLoading] = useState(false);
  const [widgetImageLoading, setWidgetImageLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [formValues, setFormValues] = useState({
    FirstName: firstName,
    LastName: lastName,
  });
  const [error, setError] = useState("");
  const [refreshToken, { loading: refreshTokenLoading }] = useLazyQuery(
    REFRESH_TOKEN,
    {
      onCompleted: (data) => userUpdatedHandler(data),
      onError: (err) => console.error(err),
      fetchPolicy: "no-cache",
    }
  );
  const [updateUser, { updateUserLoading }] = useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      if (data.updateUser.response) refreshToken();
    },
    fetchPolicy: "no-cache",
  });
  const [removeProfileImg, { loading: removeProfileImgLoading }] = useMutation(
    REMOVE_PROFILE_IMAGE,
    {
      onCompleted: (data) => {
        if (data.removeProfileImage.response) refreshToken();
      },
      fetchPolicy: "no-cache",
    }
  );
  const [removeWidgetImg, { loading: removeWidgetImgLoading }] = useMutation(
    REMOVE_WIDGET_IMAGE,
    {
      onCompleted: (data) => {
        if (data.removeWidgetImage.response) refreshToken();
      },
      fetchPolicy: "no-cache",
    }
  );
  const [
    uploadProfileImage,
    { updateProfileImageLoading, error: uploadError },
  ] = useMutation(UPLOAD_PROFILE_IMAGE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.uploadProfileImage.response) refreshToken();
    },
    onError: (error) => {
      console.error(error);
    },
  });
  const [uploadWidgetImage, { uploadWidgetImageLoading }] = useMutation(
    UPLOAD_WIDGET_IMAGE,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data.uploadWidgetImage.response) refreshToken();
      },
    }
  );

  useEffect(() => {
    if (session.activeAccount.ImageURL) {
      setProfileImageLoading(true);
      fetchingImage(session.activeAccount.ImageURL)
        .then((res) => setProfileImage(res))
        .catch((e) => console.error(e))
        .finally(() => setProfileImageLoading(false));
    }
  }, [session.activeAccount.ImageURL]);

  useEffect(() => {
    if (session.companyLogo) {
      setCompanyLogoLoading(true);
      fetchingImage(session.companyLogo)
        .then((res) => setCompanyLogo(res))
        .catch((e) => console.error(e))
        .finally(() => setCompanyLogoLoading(false));
    }
  }, [session.companyLogo]);

  useEffect(() => {
    if (uploadError) {
      uploadError.graphQLErrors.map((error) => setError(uploadError.message));
    }
  }, [uploadError]);

  useEffect(() => {
    if (session.activeAccount.WidgetImageUrl) {
      setWidgetImageLoading(true);
      fetchingImage(session.activeAccount.WidgetImageUrl)
        .then((res) => setHouseImage(res))
        .catch((e) => console.error(e))
        .finally(() => setWidgetImageLoading(false));
    }
  }, [session.activeAccount.WidgetImageUrl]);

  const [removeCompanyLogo, { removeCompanyLogoLoading }] = useMutation(
    REMOVE_COMPANY_LOGO,
    {
      onCompleted: (data) => {
        if (data.removeCompanyLogo.response) refreshToken();
      },
      onError: (err) => console.error(err),
    }
  );

  const [uploadCompanyLogo, { uploadCompanyLogoLoading }] = useMutation(
    UPLOAD_COMPANY_LOGO,
    {
      onCompleted: (data) => {
        if (data.uploadCompanyLogo.response) refreshToken();
      },
      onError: (err) => console.error(err),
    }
  );
  const onUploadProfileImageHandler = (file) => {
    uploadProfileImage({ variables: { file } });
  };

  const onUploadWidgetImageHandler = (file) => {
    uploadWidgetImage({ variables: { file } });
  };

  const inputHandler = (event) => {
    event.persist();

    setFormValues((prevFormState) => ({
      ...prevFormState,
      [event.target.id]: event.target.value,
    }));
  };

  const onSubmit = () => {
    const id = session.user.id;
    updateUser({ variables: { id, input: formValues } });
  };

  const userUpdatedHandler = (data) => {
    setEditMode(false);

    if (data.refreshToken.tokens) {
      updateLocalStorageTokens(data.refreshToken);
    }
  };

  const updateLocalStorageTokens = ({ tokens }) => {

    const newToken = tokens.find( t => t.accountPos === session.user.activeAccount.Id ).token;
    setLSValue("token", newToken);
    const oldTokens = getLSValue("tokens");
    session.saveToken(newToken);

    if (oldTokens) {
      setLSValue("tokens", JSON.stringify(tokens));
    }
  };

  const renderProfileImage = () => {
    if (profileImageLoading)
      return (
        <Skeleton width={200} height={200} style={{ transform: "scale(1)" }} />
      );
    return <img src={profileImage ? profileImage : avatar} alt="..." />;
  };

  const removeProfileImage = () => {
    removeProfileImg();
    setProfileImage(null);
  };

  const removeWidgetImage = () => {
    removeWidgetImg();
    setHouseImage(null);
  };

  const removeCompanyImage = () => {
    setCompanyLogo(null);
    removeCompanyLogo();
  };

  const uploadCompanyImage = (file) => {
    uploadCompanyLogo({ variables: { file } });
  };

  return (
    <AuthGuard permission="all">
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            {isEditable && <div className={classes.editButton}>
              <Button
                justIcon
                color="rose"
                className={classes.button}
                onClick={() => setEditMode((prevState) => !prevState)}
              >
                <Edit />
              </Button>
            </div>}
            {editMode ? (
              <GridContainer
                style={{
                  margin: "1rem auto 0",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ImageUpload
                  loading={profileImageLoading}
                  image={profileImage}
                  defaultImage={avatar}
                  removeButtonProps={{ disabled: !profileImage }}
                  onRemoveImage={removeProfileImage}
                  onUploadFile={onUploadProfileImageHandler}
                />
              </GridContainer>
            ) : (
              <CardAvatar profile style={{ background: "white" }}>
                <div style={{ width: "100%", height: "100%" }}>
                  {renderProfileImage()}
                </div>
              </CardAvatar>
            )}
            <CardBody profile>
              {editMode ? (
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={12} className={classes.inputsWrapper}>
                    <CustomInput
                      labelText={t("First Name")}
                      id="FirstName"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.formControl,
                      }}
                      labelProps={{ style: { position: "unset", top: 0 } }}
                      inputProps={{
                        value: formValues.FirstName || "",
                        onChange: inputHandler,
                        style: { marginRight: "5px" },
                      }}
                    />

                    <CustomInput
                      labelText={t("Last Name")}
                      id="LastName"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.formControl,
                      }}
                      labelProps={{ style: { position: "unset", top: 0 } }}
                      inputProps={{
                        value: formValues.LastName || "",
                        onChange: inputHandler,
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12}>
                    <Button
                      justIcon
                      color="success"
                      className={classes.button}
                      onClick={onSubmit}
                      disabled={
                        updateUserLoading ||
                        refreshTokenLoading ||
                        updateProfileImageLoading ||
                        uploadWidgetImageLoading ||
                        removeProfileImgLoading ||
                        removeWidgetImgLoading
                      }
                    >
                      <div>
                        {updateUserLoading || refreshTokenLoading ? (
                          <CircularProgress
                            className={classes.spinner}
                            size={20}
                          />
                        ) : (
                          <Check className={classes.icon} />
                        )}
                      </div>
                    </Button>

                    <Button
                      justIcon
                      color="danger"
                      className={classes.button}
                      onClick={() => setEditMode(false)}
                      disabled={
                        updateUserLoading ||
                        refreshTokenLoading ||
                        updateProfileImageLoading ||
                        uploadWidgetImageLoading ||
                        removeProfileImgLoading ||
                        removeWidgetImgLoading
                      }
                    >
                      <Close />
                    </Button>
                  </GridItem>
                </GridContainer>
              ) : (
                <h4
                  className={classes.cardTitle}
                >{`${firstName} ${lastName}`}</h4>
              )}
              <p className={classes.description}>{email}</p>
              {editMode && <div className={classes.error}>{error}</div>}
            </CardBody>
          </Card>
        </GridItem>

        <AboutProfileCard readonly={!isEditable} passwordChangeAllowed={pwdChangeAllowed} />
      </GridContainer>

      {isAdmin && (
        <React.Fragment>
          <GridContainer>
            <GridItem xs />
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <Widgets />
                  </CardIcon>
                  <h3 className={classes.cardIconTitle} style={{ lineHeight: "20px" }}>
                    {t("Widget settings")}
                  </h3>
                </CardHeader>
                <CardBody>
                  <GridContainer direction="column">
                    <GridItem xs style={{ marginBottom: "10px" }}>
                      <h4>{t("Current power widget image")}</h4>
                    </GridItem>
                    <GridItem xs>
                      <ImageUpload
                        image={houseImage}
                        loading={widgetImageLoading}
                        removeButtonProps={{ disabled: !houseImage }}
                        onRemoveImage={removeWidgetImage}
                        onUploadFile={onUploadWidgetImageHandler}
                      />
                    </GridItem>
                  </GridContainer>
                  <Clearfix />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs />
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <Widgets />
                  </CardIcon>
                  <h3 className={classes.cardIconTitle} style={{ lineHeight: "20px" }}>
                    {t("Choose company logo")}
                  </h3>
                </CardHeader>
                <CardBody>
                  <GridContainer direction="column">
                    <GridItem xs style={{ marginBottom: "10px" }}>
                      <h4>{t("Current company logo")}</h4>
                    </GridItem>
                    <GridItem xs>
                      <ImageUpload
                        image={companyLogo}
                        loading={companyLogoLoading}
                        removeButtonProps={{ disabled: !companyLogo }}
                        onRemoveImage={removeCompanyImage}
                        onUploadFile={uploadCompanyImage}
                      />
                    </GridItem>
                  </GridContainer>
                  <Clearfix />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </React.Fragment>
      )}
    </AuthGuard>
  );
}

export default inject("session", "widgets")(observer(UserProfile));
