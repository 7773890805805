import { gql } from '@apollo/client';

const USER_ACCOUNT_LOCATIONS = gql`
query allUserAccountsAndLocations {
  allUserAccountsAndLocations{
    data {
      LocationId
      LocationName
      PersonGroupPos
      Id
      Company
      FirstName
      LastName
      ValidFrom
      ValidTo
      ZIP
      City
      Country
      email
      UserId
      UserFirstName
      UserLastName
      UserEmail
      Password
      sentInvoiceByEmail
      }
    page
    totalCount
  }
}
`;


export default USER_ACCOUNT_LOCATIONS;

