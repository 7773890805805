import React, { useEffect, useState } from 'react';
// Components
import AuthGuard from 'components/AuthGuard/AuthGuard.js';
import SnackbarContent from 'components/Snackbar/SnackbarContent';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
// Icons
import { ReactComponent as Gas } from '../../assets/img/gas.svg';
import { ReactComponent as CO2SavingsIcon } from '../../assets/img/co2-savings-2.svg';
import { ReactComponent as Umbrella } from '../../assets/img/umbrella.svg';
import { FlashOn, Opacity, Whatshot, AttachMoney } from '@material-ui/icons';
// Styles
import { makeStyles } from '@material-ui/core';
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
// Utils
import { LocationItem } from 'components/LocationItem/LocationItem';
import { useHistory } from 'react-router';
import { PERMISSIONS } from 'utils/session';
import { CurrentPower, CO2Savings, Earnings, WeatherForecast } from './Widgets';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import WidgetGuard from 'components/WidgetGuard';

const useStyles = makeStyles({
  ...styles,
  alignLeft: {
    textAlign: 'left'
  },
  heading5: {
    color: 'black'
  },
  widgetsWrapper: {
    // padding: '0 25px',
    overflow: 'hidden'
  },
  cardCategory: {
    ...styles.cardCategory,
    lineHeight: '17px'
  }
});

export default function Dashboard() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeLocation, setActiveLocation] = useState(0);
  const history = useHistory();

  const locations = [
    {
      Id: 1,
      Name: 'House 1',
      measurePoints: [
        {
          Id: 1111,
          DirectionPos: 1,
          Name: 'Sensor 1',
          EnergyTypPos: 1,
          value: 123,
        },
        {
          Id: 11111,
          DirectionPos: 1,
          Name: 'Sensor 11',
          EnergyTypPos: 1,
          value: 33,
        },
        {
          Id: 1112,
          DirectionPos: 2,
          Name: 'Sensor 2',
          EnergyTypPos: 1,
          value: 22,
        },
        {
          Id: 1113,
          DirectionPos: 3,
          Name: 'Sensor 3',
          EnergyTypPos: 1,
          value: 13,
        },
      ],
      children: [
        {
          Id: 11,
          Name: 'Appartment 1',
          children: [
            {
              Id: 111,
              Name: 'Room 1',
            },
            {
              Id: 112,
              Name: 'Room 2',
            }
          ]
        },
        {
          Id: 12,
          Name: 'Appartment 2'
        },
        {
          Id: 13,
          Name: 'Corridor 1'
        },
      ]
    },
    {
      Id: 2,
      Name: 'House 2',
      children: [
        {
          Id: 21,
          Name: 'Appartment 1'
        },
        {
          Id: 22,
          Name: 'Appartment 2'
        },
        {
          Id: 23,
          Name: 'Corridor 1'
        },
      ]
    }
  ];

  function onChangeActiveLocation(Id) {
    if (Id !== activeLocation) {
      setActiveLocation(Id)
    }
  }

  function mapRecursively(location) {
    let children = null;
    if (location.children && location.children.length) {
      children = mapLocations(location.children);
    }

    // TODO: change it to actual location id
    return (<LocationItem
      onClickLocation={(Id) => history.push('/location/H14a_Atelier')}
      onChangeActiveLocation={(Id) => onChangeActiveLocation(Id)}
      active={location.Id === activeLocation} location={location}
      key={location.Id}>{children}</LocationItem>);
  }

  function mapLocations(array) {
    return array.map(location => mapRecursively(location));
  }

  const locationsMap = mapLocations(locations);

  return (
    <AuthGuard permission={PERMISSIONS.dashboard}>
      <SnackbarContent
        message='Electricity system is not working properly!'
        icon={FlashOn}
        color="warning"
        visible={false}
        close
      />

      <SnackbarContent
        message='Water system is not working properly!'
        icon={Opacity}
        color="info"
        visible={false}
        close
      />

      <SnackbarContent
        message='Heating system is not working properly!'
        icon={Whatshot}
        color="danger"
        visible={false}
        close
      />

      <SnackbarContent
        message='Gas system is not working properly!'
        icon={Gas}
        color="rose"
        visible={false}
        close
      />

      {/* {locationsMap} */}

      <div className={classes.widgetsWrapper}>
        <GridContainer container>
          <WidgetGuard widget="CurrentConsumptionWidget">
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card hover>
                <CardHeader color="warning" stats icon>
                  <CardIcon color="warning">
                    <FlashOn />
                  </CardIcon>
                  <p className={classNames(classes.cardCategory, classes.alignLeft)}>{t('Current Power')}</p>
                  <CurrentPower />
                </CardHeader>
              </Card>
            </GridItem>
          </WidgetGuard>

          {
              <WidgetGuard widget="WeatherWidget">
                <GridItem xs={12} sm={12} md={12} lg={true}>
                  <Card hover>
                    <CardHeader color="warning" stats icon>
                      {
                        Umbrella && (
                          <CardIcon color="warning">
                            <Umbrella />
                          </CardIcon>
                        )
                      }
                      <p className={classes.cardCategory}>{t('3 day weather forecast')}</p>
                      <WeatherForecast />
                    </CardHeader>
                  </Card>
                </GridItem>
              </WidgetGuard>
          }
{
              <CO2Savings />
}
        </GridContainer >
      </div>

    </AuthGuard >
  );
};
