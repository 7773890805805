import { gql } from '@apollo/client'; 

const MEASURE_POINT_LAST_HEARTBEAT_MONTHLY = gql`
  query measurePointLastHeartbeat($MeasurePointPos: Int!){
    measurePointLastHeartbeat(MeasurePointPos: $MeasurePointPos) {
      Status
    }
  }
`;

export default MEASURE_POINT_LAST_HEARTBEAT_MONTHLY;
