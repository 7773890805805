import React, { useEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'

// @material-ui/icons
import ExpandMore from '@material-ui/icons/ExpandMore'

import styles from 'assets/jss/material-dashboard-pro-react/components/accordionStyle.js'
import TabContent from 'views/Components/TabContent'

const useStyles = makeStyles(styles)

export default function Accordion({ collapses, onChange, active }) {
  const sensorRef = React.useRef()
  const classes = useStyles()

  useEffect(() => {
    sensorRef.current?.scrollIntoView({ block: 'end' })
  }, [])

  const handleChange = (panel) => (event, expanded) => {
    let expandedStatus = expanded ? panel : -1
    if (!onChange) return
    onChange(expandedStatus)
  }

  const TabContentWithRef = forwardRef((props, ref) => <TabContent ref={ref} {...props} />)
  return (
    <div className={classes.root}>
      {collapses.map((prop, key) => {
        return (
          <ExpansionPanel
            expanded={active === key}
            onChange={handleChange(key)}
            key={key}
            classes={{
              root: classes.expansionPanel,
              expanded: classes.expansionPanelExpanded,
            }}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMore />}
              classes={{
                root: classes.expansionPanelSummary,
                expanded: classes.expansionPanelSummaryExpaned,
                content: classes.expansionPanelSummaryContent,
                expandIcon: classes.expansionPanelSummaryExpandIcon,
              }}
            >
              <h4 className={classes.title}>{prop.title}</h4>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              {active === key && (
                <TabContentWithRef
                  name={prop.title}
                  activeTabColor={prop.activeTabColor}
                  id={prop.id}
                  energyType={{ energyType: prop.activeTitle, energyTypePos: prop.energyTypePos }}
                />
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      })}
      {!collapses.length && <h4>Sorry, no sensors available!</h4>}
    </div>
  )
}

Accordion.defaultProps = {
  active: -1,
}

Accordion.propTypes = {
  // index of the default active collapse
  active: PropTypes.number,
  collapses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node,
    })
  ).isRequired,
}
