import React, { useCallback, useState } from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useTranslation } from "react-i18next";
import {AddCircleRounded, Check, Close, Done } from "@material-ui/icons";
import CustomInput from "components/CustomInput/CustomInput";
import { makeStyles } from "@material-ui/styles";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import {  Checkbox, Tooltip } from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import { GENERATE_ACTIVATION_TOKEN } from "mutations/account";
import NestedRow from "./NestedRow";
import Datetime from "react-datetime";
import dayjs from "dayjs";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

export default function Row(props) {
  const { row, getQuery } = props;
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const useStyles = makeStyles({...styles, ...formStyles});
  const classes = useStyles();

  const locationId = row[0].LocationId;
  const locationName = row[0].LocationName;
  const [accountEditingObject, setAccountEditingObject] = useState({});
  const [userEditingObject, setUserEditingObject] = useState({});
  const sortedUsers = row.reduce((account, user) => {
    const { Id } = user;
    account[Id] = account[Id] ?? [];
    account[Id].push(user);
    return account;
  }, {});
  const nestedAccount = Object.values(sortedUsers);
  const [generateActivationToken, { loading, data, error: generationError }] = useLazyQuery(GENERATE_ACTIVATION_TOKEN);


  const [mode, setMode] = useState("");
  const [userMode, setUserMode] = useState(false);
  const accountColumns = [
    {
      Header: t("Company"),
      accessor: "Company",
      field: "Company"
    },
    {
      Header: t("First name"),
      accessor: "FirstName",
      field: "FirstName"
    },
    {
      Header: t("Last name"),
      accessor: "LastName",
      field: "LastName"
    },
    {
      Header: t("Email"),
      accessor: "email",
      field: "email"
    },
    {
      Header: t("ZIP"),
      accessor: "ZIP",
      field: "ZIP"
    },
    {
      Header: t("City"),
      accessor: "City",
      field: "City"
    },
    {
      Header: t("Country"),
      accessor: "Country",
      field: "Country"
    },
    {
      Header: t("Valid From"),
      accessor: "ValidFrom",
      field: "ValidFrom",
      date: true
    },
    {
      Header: t("Valid To"),
      accessor: "ValidTo",
      field: "ValidTo",
      date:true
    },
    {
      Header: t("Invoice By Email"),
      accessor: "sentInvoiceByEmail",
      field: "sentInvoiceByEmail",
      type: "checkbox",
    },
    {
      Header: t("Actions"),
      editable: false,
    },
    {
      Header: t("Add User"),
      field: "AddUser",
      editable: false
    }
  ];

  const userColumns = [
    {
      Header: t("First name"),
      accessor: "FirstName",
      field: "UserFirstName"
    },
    {
      Header: t("Last name"),
      accessor: "LastName",
      field: "UserLastName"
    },
    {
      Header: t("Email"),
      accessor: "Email",
      field: "UserEmail"
    },
    {
      Header: t("Password"),
      accessor: "Password",
      field: "Password"
    },
    {
      Header: t("Actions"),
      editable: false,
    },
  ];

  const addRowClick = () => {
    setAccountEditingObject({PersonGroupPos: nestedAccount[0][0].PersonGroupPos })
    setMode("addAccount");
    addUserRow();
    setOpen(true);
  };

  const addAccountRow = () => {
    const values = {
      account: accountEditingObject,
      user: userEditingObject
    }
    generateActivationToken({
      variables: {
        input: values
      },
      refetchQueries:
        [{
          query: getQuery.query
        }]
    });
    setMode('');
  };

  const addUserRow = () => {
    setUserMode(true);
  }

  const datePickerValidation = (incomingDate) => {
    const validFromDate = row[row.length - 1].ValidTo || dayjs();
    return dayjs(incomingDate).isAfter(validFromDate);
  }
  return (
    <React.Fragment>
      <TableRow style={{backgroundColor:"#F5F5F5"}}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {locationId}
        </TableCell>
        <TableCell align="left">{locationName}</TableCell>
        <TableCell>
          <IconButton onClick={addRowClick} aria-label="add" >
            <AddCircleRounded/>
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} padding={1}>
              <Typography variant="h5" gutterBottom component="div">
                {t("Accounts")}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell/>
                    {accountColumns.map((column, index) => {
                      return <TableCell key={index}>{column.Header}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                {mode === "addAccount" && (
                    <>
                      <TableRow>
                        <TableCell>
                        <Typography variant="h6" gutterBottom component="div">{t('Account')}</Typography>
                      </TableCell>
                      {accountColumns.map((column, index) => {
                        if (column.editable === undefined) {
                          column.editable = true;
                        }
                        if (column.editable) {
                          return (
                            <TableCell key={index}>
                            {column.date ? (
                              <Datetime
                                isValidDate={(currentDate) => datePickerValidation(currentDate)}
                                closeOnSelect
                                timeFormat={false}
                                dateFormat={"DD.MM.YYYY"}
                                disableOnClickOutside={false}
                                onChange={(value) => {
                                  setAccountEditingObject({
                                    ...accountEditingObject,
                                    [column.accessor]: dayjs(value).format("YYYY-MM-DD"),

                                  });
                                }}
                                inputProps={{
                                  placeholder:  column.Header,
                                  readOnly: false,
                                }}
                              />
                            ) : (
                              column.type === "checkbox" ?
                              <Checkbox
                              checked={!!accountEditingObject[column.accessor]}
                              onClick={ ({ target: { checked } }) => {
                                setAccountEditingObject({
                                  ...accountEditingObject,
                                  [column.field]: checked,
                                });
                              }}
                              checkedIcon={<Check className={classes.checkedIcon} color="primary"/>}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.root
                              }}
                            /> :
                              <CustomInput
                                inputProps={{
                                  placeholder: column.Header,
                                  onChange: ({ target: { value } }) => {
                                    setAccountEditingObject({
                                      ...accountEditingObject,
                                      [column.accessor]:
                                        column.type === "numeric"
                                          ? parseInt(value)
                                          : value,
                                    });
                                  },
                                }}
                              />
                            )}
                          </TableCell>
                          );
                        }else if(column.Header === t("Actions") && !userMode) {
                          return(                         
                          <TableCell key={index} className="rt-td" >
                          <div className={classes.centered}>
                          </div>
                        </TableCell>)
                        }else {
                          return <TableCell key={index}></TableCell>;
                        }
                      })}
                      </TableRow>
                      <TableRow>
                        <TableCell/>
                      {userMode && (
                      <>
                        <TableCell>
                          <Typography variant="h6" gutterBottom component="div">{t('User')}</Typography>
                        </TableCell>
                      {userColumns.map((column, index) => {
                        if (column.editable === undefined) {
                          column.editable = true;
                        }
                        if (column.editable && column.Header != 'Password') {
                          return (
                            <TableCell key={index}>
                              <CustomInput
                                inputProps={{
                                  placeholder: column.Header,
                                  onChange: ({ target: { value } }) => {
                                    setUserEditingObject({
                                      ...userEditingObject,
                                      [column.accessor]:
                                        column.type === "numeric"
                                          ? parseInt(value)
                                          : value,
                                    });
                                  },
                                }}
                              />
                            </TableCell>
                          );
                        }  
                      })}
                        <TableCell className="rt-td" >
                          <div className={classes.centered}>
                            <Tooltip id="tooltip-top" title={t("Save")} placement="top" classes={{ tooltip: classes.tooltip }}>
                              <IconButton aria-label="Save" color="secondary" onClick={addAccountRow}>
                                <Done />
                              </IconButton>
                            </Tooltip>

                            <Tooltip id="tooltip-top" title={t("Cancel")} placement="top" classes={{ tooltip: classes.tooltip }}>
                              <IconButton aria-label="Cancel" onClick={() => {setMode(''); setUserMode(false)}}>
                                <Close />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </>)}
                      </TableRow>
                    </>
                    
                  )}
                  {nestedAccount.map((account, index) => (
                      <NestedRow key={index} accountColumns={accountColumns} userColumns={userColumns} account={account} getQuery={getQuery} />
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}