export const fetchingImage = (url) => {
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) =>
    fetch(`${process.env.REACT_APP_SERVER_PUBLIC}/${url}`, {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.readAsDataURL(blob);
      })
      .catch(err => console.log(err))
  );
};
