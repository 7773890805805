import { gql } from '@apollo/client';

const WEATHER_FORECAST = gql`
  query weatherForecast($ZipCode: Int, $CountryCode: String, $Units: String, $Language: String) {
    weatherForecast(ZipCode: $ZipCode, CountryCode: $CountryCode, Units: $Units, Language: $Language) {
      list {
        firstDay {
          ...forecastData
        }
        
        secondDay {
          ...forecastData
        }
        
        thirdDay {
          ...forecastData
        }
      }
      
      city {
        id
        name
        coord {
          lat
          lon
        }
        country
        timezone
        sunset
        sunrise
      }
    }
  }
  
  fragment forecastData on WeatherItem {
    data {
      dt
      dt_txt
      main {
        temp
        feels_like
        temp_min
        temp_max
      }
      weather {
        id
        main
        description
        icon
      }
      clouds {
        all
      }
      pop
      rain {
        threeHours
      }
      snow {
        threeHours
      }
      sys {
        pod
      }
    }
    temp_max
    temp_min
    
  }
`;

export default WEATHER_FORECAST;
