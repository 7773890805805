import React from "react";

import { Close } from "@material-ui/icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";

import { makeStyles } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";

const useStyles = makeStyles(styles);

export default function Modal({
  onModalClose,
  bodyText,
  titleText,
  content,
  isOpened,
  children,
}) {
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      open={isOpened}
      keepMounted
      onClose={onModalClose}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={onModalClose}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h3 className={classes.modalTitle}>{titleText}</h3>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <p>{bodyText}</p>
        {content}
      </DialogContent>
      <DialogActions className={classes.modalFooter}>{children}</DialogActions>
    </Dialog>
  );
}
