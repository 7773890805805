import { gql } from '@apollo/client';

const ADD_ACCOUNT = gql`
  mutation($Email: String, $Password: String) {
    createAccount(email: $Email, password: $Password) {
      response
      message
    }
  }
`;

export default ADD_ACCOUNT;