import { gql } from '@apollo/client';

const REMOVE_ACCOUNT_IMAGE = gql`
  mutation {
    removeAccountImage {
      response
      message
    }
  }
`;

export default REMOVE_ACCOUNT_IMAGE;
