import React from 'react';
import BillingWrapper from 'modules/BillingWrapper';
import AuthGuard from 'components/AuthGuard/AuthGuard';

const Billing = () => {
  return (
    <AuthGuard permission="all">
      <BillingWrapper />
    </AuthGuard>
  );
}

export default Billing;
