import {
  successColor,
  dangerColor,
  cardTitle,
  grayColor,
  tooltip
} from "assets/jss/material-dashboard-pro-react.js";

import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.js";

const dashboardStyle = {
  ...hoverCardStyle,
  tooltip: {
    ...tooltip,
    minWidth: '50px'
  },
  justifyRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonsWrapper: {
    display: 'flex',
    paddingLeft: '0 !important',
    marginTop: '15px',
    marginBottom: '10px'
  },
  cardTitle: {
    ...cardTitle,
    fontSize: '15px',
    marginBottom: "5px",
  },
  measurePointValue: {
    fontSize: '17px'
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center"
  },
  cardCategory: {
    color: grayColor[1],
    fontSize: "20px",
    fontWeight: 'bolder',
    paddingTop: "10px",
    marginBottom: "10px",
    marginTop: "0",
    margin: "0"
  },
  cardProductDesciprion: {
    textAlign: "center",
    color: grayColor[0]
  },
  stats: {
    color: grayColor[0],
    fontSize: "12px",
    lineHeight: "24px",
    display: "inline-flex",
    marginLeft: '5px',
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "5px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px"
    }
  },
  productStats: {
    paddingTop: "7px",
    paddingBottom: "7px",
    margin: "0"
  },
  successText: {
    color: successColor[0]
  },
  dangerText: {
    color: dangerColor[0]
  },
  upArrowCardCategory: {
    width: 14,
    height: 14
  },
  underChartIcons: {
    width: "17px",
    height: "17px"
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px"
    }
  },
  chartSkeleton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '-20px 1rem 0',
    '& > span' : {
      transform: 'scale(1)',
      width: '100%'
    }
  }
};

export default dashboardStyle;
