import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import BILLING_DETAILS from "queries/billing/billing";
import { useParams } from "react-router-dom";

export const useBilling = () => {
  const currentMonth = dayjs().month() +1;
  const currentYear = dayjs().year();
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);

  const params = useParams();
  const [selectedEnergyCard, setSelectedEnergyCard] = useState({
    id: 1,
    color: "warning",
  });
  const [fetchBillingDetails, { data, loading }] = useLazyQuery(
    BILLING_DETAILS,
    {
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (!month && !year && !selectedEnergyCard) return;
    fetchBillingDetails({
      variables: {
        ...(month <= 12 && { month }),
        year,
        energyTypePos: parseInt(params.id),
      },
    });
    setSelectedEnergyCard({
      id: parseInt(params.id),
      color: 'warning'
        })
  }, [ month, year, params]);

  return {
    data,
    month,
    year,
    loading,
    selectedEnergyCard,
    setMonth,
    setYear,
    setSelectedEnergyCard,
  };
};
