import { gql } from '@apollo/client';

const PAGINATED_INVOICES = gql`
  query PaginatedInvoices($page: Int, $size: Int, $searchCriteria: InvoiceInput) {
    paginatedInvoices(page: $page, size: $size, searchCriteria: $searchCriteria) {
      payer {
        ...data
      }
      biller {
        ...data
      }
    }
  }

  fragment data on PaginatedPDFData {
    data {
      Id
      Currency
      Reference
      EnergyType
      Date
      BillingCycle
      IBAN
      BillingText
      Total
      BillTotalPrice
      BillTotalVat
      BillTotalPriceVat
      Creditor {
        name
        address
        zip
        city
        country
        account
      }
      Debtor {
        name
        address
        zip
        city
        country
      }
      InvoiceLines {
        invoiceId
        billingText
        tarifName
        usage
        sale
        singlePrice
        costType
        vatPercent
        vatValue
      }
    }
    page
    totalCount
    totalInvoiceIds
  }
`;

export default PAGINATED_INVOICES;
