import { gql } from '@apollo/client';

const CHANGE_ACCOUNT = gql`
  query ($oldTokens: [TokenDataInput]!) {
    changeAccount(tokens: $oldTokens) {
      tokens {
        accountPos
        token
      }
    }
  }
`;

export default CHANGE_ACCOUNT;