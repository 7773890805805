import LoginPage from "views/Pages/LoginPage";
import ErrorPage from "views/Errors/ErrorPage";
import UserProfile from "views/Pages/UserProfile";
import Dashboard from "views/Dashboard/Dashboard.js";
import Accounts from "components/Accounts/Accounts";
import AddAccount from "components/Accounts/Add/AddAccount";
import LockScreenPage from "views/Pages/LockScreenPage";
import ActivateAccount from "views/Pages/ActivateAccount";
import ActivateUser from "views/Pages/ActivateUser";
import ForgotPassword from "views/Pages/ForgotPassword";
import Billing from "views/Pages/Billing";
import Administration from "views/Pages/Administration";
import UsersPage from "views/Pages/Users";
import Invoices from "views/Invoices";
import DetailedInvoice from "views/Invoices/DetailedInvoice";

// @material-ui/icons
import {
  Dashboard as DashboardIcon,
  Image,
  GroupSharp,
  Person,
  Receipt,
  Payment,
  Tune,
  SupervisedUserCircle,
} from "@material-ui/icons";
import LocationDashboard from "components/LocationDashboard/LocationDashboard";
import { PERMISSIONS } from "utils/session";
import SuperAdminPage from "views/Pages/SuperAdmin";

export const routes = [
  // Guest
  {
    path: "/activate-account",
    name: "Account activation",
    hideInSidebar: true,
    component: ActivateAccount,
    layout: "/guest",
  },
  {
    path: "/activate-user",
    name: "User activation",
    hideInSidebar: true,
    component: ActivateUser,
    layout: "/guest",
  },
  // Common layout
  {
    path: "/administration",
    name: "Administration",
    icon: Tune,
    component: Administration,
    private: true,
    layout: "/",
  },
  {
    path: "/accounts/add",
    name: "Add account",
    icon: GroupSharp,
    permission: PERMISSIONS.accounts,
    hideInSidebar: true,
    component: AddAccount,
    layout: "/",
  },
  {
    path: "/users",
    name: "Users",
    icon: Person,
    permission: PERMISSIONS.users,
    component: UsersPage,
    layout: "/",
  },
  {
    path: "/super-admin",
    component: SuperAdminPage,
    dynamic: true,
    icon: SupervisedUserCircle,
    name: "Super Admin",
    permission: PERMISSIONS.superAdmin,
    layout: "/",
  },
  {
    path: "/accounts",
    name: "Accounts",
    permission: PERMISSIONS.accounts,
    icon: GroupSharp,
    component: Accounts,
    layout: "/",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    permission: PERMISSIONS.dashboard,
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/",
  },
  {
    path: "/billing/:id",
    name: "Billing",
    dynamic: true,
    icon: Receipt,
    component: Billing,
    layout: "/",
  },
  {
    path: "/invoices",
    name: "Invoices",
    icon: Payment,
    component: Invoices,
    layout: "/",
  },
  {
    path: "/invoices/:id",
    name: "Detailed invoice",
    dynamic: true,
    hideInSidebar: true,
    component: DetailedInvoice,
    layout: "/",
  },
  {
    path: "/location/:id",
    hideInSidebar: true,
    component: LocationDashboard,
    dynamic: true,
    name: "Location",
    layout: "/",
  },
  {
    path: "/profile",
    hideInSidebar: true,
    component: UserProfile,
    dynamic: true,
    name: "Profile",
    layout: "/",
  },
  // Errors
  {
    path: "/:errorCode",
    name: "Something went wrong",
    hideInSidebar: true,
    component: ErrorPage,
    layout: "/error",
  },
  // Auth layout
  {
    collapse: true,
    name: "Pages",
    icon: Image,
    hideInSidebar: true,
    state: "pageCollapse",
    views: [
      {
        path: "/login-page",
        name: "Sensorit",
        component: LoginPage,
        layout: "/auth",
      },
      {
        path: "/lock-screen-page",
        name: "Lock Screen Page",
        component: LockScreenPage,
        layout: "/auth",
      },
      {
        path: "/forgot-password",
        name: "Forgot password",
        component: ForgotPassword,
        layout: "/auth",
      },
    ],
  },
];
