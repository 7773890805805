import { gql } from '@apollo/client';

const CO2_SAVINGS = gql`
  query co2Savings($ClientPos: Int!){
    co2Savings(ClientPos: $ClientPos) {
      WidgetID
      WidgetTyp
      Consumptionkwh
      CO2saving
      TreeSaving
    }
  }
`;

export default CO2_SAVINGS;
