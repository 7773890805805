import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import { inject, observer } from 'mobx-react';

function AuthGuard({ session, permission, children }) {
  const history = useHistory();
  const [loaded, setLoading] = useState(false)

  useEffect(() => {
    const userPermissions = session.user.permissions;
    setLoading(true);
    if (!session.user.token || !session.user) {
      history.push('/auth/login-page');
      return;
    }

    if (permission === 'all') {
      return;
    }

    if (!userPermissions.includes(permission)) {
      history.push('/error/401');
    }
  }, [history, session.user.token, session.user]);

  return <>{loaded && children}</>;
}

export default inject('session')(observer(AuthGuard));
