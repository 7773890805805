import { gql } from '@apollo/client';

const REMOVE_WIDGET_IMAGE = gql`
  mutation removeWidgetImage {
    removeWidgetImage {
      response
      message
    }
  }
`;

export default REMOVE_WIDGET_IMAGE;
