import { gql } from '@apollo/client';

const PDF_SETTINGS = gql`
  query Query($clientId: Int!) {
    getPdfSetting(clientId: $clientId) {
      Id
      logo
      textColor
      clientPos
      InvoiceText
      enableQRBill
      enableTotalVAT
    }
  }
`;

export default PDF_SETTINGS;
