import { gql } from '@apollo/client';

const UPDATE_PDF_IMAGE = gql`
  mutation uploadPdfImage($file: Upload!) {
    uploadPdfImage(file: $file) {
      response
      message
    }
  }
`;

export default UPDATE_PDF_IMAGE;
