import { gql } from '@apollo/client';

const GENERATE_USER_ACTIVATION_TOKEN = gql`
  mutation generateUserActivationToken($accountId: Int!, $input: UserInput){
    generateUserActivationToken(accountId: $accountId, input: $input) {
      response
      message
    }
  }
`;

export default GENERATE_USER_ACTIVATION_TOKEN;
