import React, { useEffect, useState } from 'react'
// Components
import GridItem from 'components/Grid/GridItem.js'
import AuthGuard from 'components/AuthGuard/AuthGuard'
import GridContainer from 'components/Grid/GridContainer.js'
// Utils
import EnergyTypeCards from 'components/EnergyTypeCards/EnergyTypeCards'
// Apollo
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import CustomTabsWrapper from 'components/CustomTabsWrapper'
import useQueryParams from 'hooks/useQueryParams'
import { SMART_ME_REAL_TIME } from 'queries'
import { useQuery } from '@apollo/client'

function LocationDashboard({ session, onGetLocationIdParam }) {
  const params = useParams()
  const { getKey, setKeys } = useQueryParams()
  const [locationPos, setLocationPos] = useState(0)
  const { loading: smartMeLoader, data } = useQuery(SMART_ME_REAL_TIME, { pollInterval: 5000, fetchPolicy: 'no-cache' })

  useEffect(() => {
    session.setActiveEnergyTab(parseInt(getKey('energyType')) || 1)
    session.setActiveSensorId(parseInt(getKey('sensorId')) || 0)
    session.setActivePeriod(parseInt(getKey('period')) || 'week')
  }, [])

  useEffect(() => {
    setKeys([
      { key: 'energyType', value: session.activeEnergyTab },
      { key: 'sensorId', value: session.activeSensorId },
      { key: 'period', value: session.activePeriod },
    ])
  }, [session.activeEnergyTab, session.activeSensorId, session.activePeriod])

  useEffect(() => {
    if (params?.id) {
      setLocationPos(parseInt(params.id))
      onGetLocationIdParam(params.id)
    }
  }, [params])

  const onChangeEnergyType = (index) => {
    session.setActiveEnergyTab(index)
  }
  return (
    <AuthGuard permission="all">
      <EnergyTypeCards onChangeCard={onChangeEnergyType} activeTab={session.activeEnergyTab} smartLoader={smartMeLoader} smartData={data}/>

      <GridContainer style={{ overflowX: 'hidden' }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CustomTabsWrapper locationPos={locationPos} />
        </GridItem>
      </GridContainer>
    </AuthGuard>
  )
}

export default inject('session')(observer(LocationDashboard))
