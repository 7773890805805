import React from 'react';

import AuthGuard from 'components/AuthGuard/AuthGuard';
import GridContainer from 'components/Grid/GridContainer';
import PayerInvoices from 'components/PayerTable';
import BillerInvoices from 'components/BillerTable';

export default function Invoices() {
  return (
    <AuthGuard permission="all">
      <GridContainer direction="column">
        <PayerInvoices />
        <BillerInvoices />
      </GridContainer>
    </AuthGuard>
  );
}
