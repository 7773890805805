import { gql } from '@apollo/client';

const REMOVE_USER = gql`
  mutation deleteUserFromAccount($accountId: Int!, $userId: Int!) {
    deleteUserFromAccount(accountId: $accountId, userId: $userId) {
      response
      message
    }
  }
`;

export default REMOVE_USER;
