import { gql } from '@apollo/client';

const USERS = gql`
  query users($page: Int, $size: Int, $accountId: Int) {
    users(page: $page, size: $size, accountId: $accountId) {
      data {
        Id
        Email
        FirstName
        LastName
        Password
      }
      page
      totalCount
    }
  }
`;

export default USERS;
