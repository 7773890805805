import React, { useEffect, useState } from "react";
// core components
import Icon from "@material-ui/core/Icon";
import Card from "components/Card/Card.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import { LinearProgress } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
// Apollo
import { useMutation } from "@apollo/client";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { useHistory } from "react-router-dom";
import { ADD_ACCOUNT } from "mutations/account";
import { useTranslation } from "react-i18next";
import classNames from 'classnames';
import { inject, observer } from "mobx-react";
import validate from "validate.js";

const useStyles = makeStyles(theme => ({
  ...loginPageStyle(theme),
  ...sweetAlertStyles,
  cardFooter: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0
  }
}));

const ActivateAccount = ({ session, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [alert, setAlert] = useState(null);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [cardAnimation, setCardAnimation] = useState("cardHidden");
  const [addAccount, { loading, data, error: createAccountErrors }] = useMutation(ADD_ACCOUNT);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const schema = {
    Password: {
      presence: { allowEmpty: false, message: t('is required') },
      length: { minimum: 6, message: t('minimum 6 characters') },
    }
  };

  useEffect(() => {
    if (createAccountErrors) {
      createAccountErrors.graphQLErrors.map(error => setError(error.message ));
    }
  }, [createAccountErrors]);

  useEffect(() => {
    if (data) {
      if (data.createAccount.response) {
        props.openAlert('Account activated.', 'You can log in now!', '/auth/login-page');
      }
    }
  }, [data]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }));
  }, [formState.values, session]);

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 300);
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const email = params.get('email');
    if (email) {
      setEmail(email);
    }
  }, []);

  const onFormSubmit = event => {
    event.preventDefault();
    const { Password } = formState.values;
    addAccount({ variables: { Email: email, Password } });
  }

  const inputHandler = event => {
    event.persist();

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.id]: event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.id]: true
      }
    }));
  }

  const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]));

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={onFormSubmit}>
            <Card login className={classes[cardAnimation]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle} style={{ color: 'white' }}>{t('Activate your account')}</h4>
              </CardHeader>
              <CardBody>
                <div className={classes.error}>{error}</div>
                <CustomInput
                  labelText={t('Password')}
                  id="Password"
                  helperText={hasError('Password') ? formState.errors.Password[0] : null}
                  error={hasError('Password')}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    onChange: inputHandler,
                    value: formState.values.Password || '',
                    type: "password",
                    autoComplete: "off"
                  }}
                />
              </CardBody>
              <CardFooter
                className={classNames(classes.justifyContentCenter, classes.cardFooter)}
                style={{ cursor: formState.isValid ? 'pointer' : 'not-allowed' }}>
                <Button
                  block
                  simple
                  size="lg"
                  color="rose"
                  type="submit"
                  disabled={!formState.isValid || loading}
                >
                  {t('Activate')}
                </Button>
              </CardFooter>
              {loading && <LinearProgress />}
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default inject('session')(observer(ActivateAccount));
