import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { routes } from "routes.js";

// Styles
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import SIDEBAR_IMG from "assets/img/sidebar-2.jpg";
import { useTranslation } from "react-i18next";

var ps;

const useStyles = makeStyles(styles);

const Dashboard = (props) => {
  const [selectedLocationId, setSelectedLocationId] = React.useState(null);
  const { session, ...rest } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  const mainPanel = React.createRef();
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      // document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].dynamic) {
        let nonDynamicPart = routes[i].path.split(":")[0];
        nonDynamicPart = nonDynamicPart.slice(0, -1);
        if (window.location.href.indexOf(nonDynamicPart) !== -1) {
          return t(routes[i].name);
        }
      } else {
        if (window.location.href.indexOf(routes[i].path) !== -1) {
          return t(routes[i].name);
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) return getRoutes(prop.views);
      if (!prop.layout === "/") return null;

      return (
        <Route
          exact={prop.exact || true}
          path={prop.path}
          component={(cProps) => (
            <prop.component
              onGetLocationIdParam={(id) => setSelectedLocationId(id)}
              {...cProps}
            />
          )}
          key={key}
        />
      );
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const redirectTo = () => {
    return <Redirect from="/" to="/profile" exact />;
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        image={SIDEBAR_IMG}
        handleDrawerToggle={handleDrawerToggle}
        selectedLocationId={selectedLocationId}
        open={mobileOpen}
        color="green"
        bgColor="black"
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {getRoutes(routes)}
                {redirectTo()}
              </Switch>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <Switch>
              {getRoutes(routes)}
              {redirectTo()}
            </Switch>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
