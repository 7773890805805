import React, { useState, useEffect } from 'react';

// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import Icon from "@material-ui/core/Icon";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { LinearProgress } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput.js";

import { useHistory } from 'react-router-dom';

// Styles
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  ...styles(theme),
  cardFooter: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0
  }
}));

export default function Login({ formState, hasError, inputHandler, loading, error, ...props }) {
  const [cardAnimation, setCardAnimation] = useState("cardHidden");
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 300);
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  return (
    <Card login className={classes[cardAnimation]}>
      <CardHeader
        className={`${classes.cardHeader} ${classes.textCenter}`}
        color="rose"
      >
        <h4 className={classes.cardTitle}>{t('Sign in')}</h4>
      </CardHeader>
      <CardBody>
        <CustomInput
          labelText={t('Email')}
          id="Email"
          helperText={hasError('Email') ? formState.errors.Email[0] : null}
          error={hasError('Email')}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Email className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
            value: formState.values.Email || '',
            onChange: inputHandler
          }}
        />
        <CustomInput
          labelText={t('Password')}
          id="Password"
          helperText={hasError('Password') ? formState.errors.Password[0] : null}
          error={hasError('Password')}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon className={classes.inputAdornmentIcon}>
                  lock_outline
                </Icon>
              </InputAdornment>
            ),
            onChange: inputHandler,
            value: formState.values.Password || '',
            type: "password",
            autoComplete: "off"
          }}
        />
        <div className={classes.error}>{error}</div>
      </CardBody>
      <CardFooter className={classNames(classes.justifyContentCenter, classes.cardFooter)} style={{ cursor: formState.isValid ? 'pointer' : 'not-allowed' }}>
        <Button
          block
          simple
          size="lg"
          color="primary"
          type="button"
          onClick={() => history.push('/auth/forgot-password')}
        >
          {t('Forgot password?')}
        </Button>

        <Button
          block
          simple
          size="lg"
          color="primary"
          type="submit"
          disabled={!formState.isValid || loading}
        >
          {t('Sign in')}
        </Button>
      </CardFooter>
      {loading && <LinearProgress />}
    </Card>
  );
}
